import React from "react";
import { Input } from "reactstrap";

import {
  LIST_DEALERS,
  LIST_RATES,
  LIST_AUTONOMOUS_COMMUNITIES,
  LIST_MARKETERS,
  LIST_MARKETERS_GAS,
  LIST_LOCALITIES,
  LIST_PROVINCES,
  LIST_USER,
  LIST_USERS,
  LIST_LOCALITIESNAME,
LIST_CNAE,
LIST_ESTADOS_PROPUESTAS,
LIST_ESTADOS_CONTRATOS
} from "./SipsOption.graphql";
import { Query } from "react-apollo";

const queries = {
  LIST_DEALERS: {
    query: LIST_DEALERS,
    name: "listDealers",
    key: "Id",
    value: "Empresa",
  },
  LIST_RATES: {
    query: LIST_RATES,
    name: "listRates",
    key: "Id",
    value: "Descripcion",
  },
  LIST_AUTONOMOUS_COMMUNITIES: {
    query: LIST_AUTONOMOUS_COMMUNITIES,
    name: "listAutonomousCommunities",
    key: "autonomia_id",
    value: "nombre",
  },
  LIST_MARKETERS: {
    query: LIST_MARKETERS,
    name: "listMarketers",
    key: "Id",
    value: "Empresa",
  },
  LIST_MARKETERS_GAS: {
    query: LIST_MARKETERS_GAS,
    name: "listMarketersGas",
    key: "Id",
    value: "Empresa",
  },
  LIST_LOCALITIES: {
    query: LIST_LOCALITIES,
    name: "listLocalities",
    key: "Cmunicipio",
    value: "Nombre",
  },
  LIST_PROVINCES: {
    query: LIST_PROVINCES,
    name: "listProvinces",
    key: "provinciaId",
    value: "nombre",
  },
  LIST_LOCALITIESNAME: {
    query: LIST_LOCALITIES,
    name: "listLocalities",
    key: "Nombre",
    value: "Nombre",
  },
  LIST_CNAE: {
    query: LIST_CNAE,
    name: "listCNAE",
    key: "actividadCNAE",
    value: "cnaeId",
  },
  LIST_ESTADOS_PROPUESTAS: {
    query: LIST_ESTADOS_PROPUESTAS,
    name: "estadosPropuestas",
    key: "Id",
    value: "Descripcion",
  },
  LIST_ESTADOS_CONTRATOS: {
    query: LIST_ESTADOS_CONTRATOS,
    name: "estadosContratos",
    key: "Id",
    value: "Descripcion",
  },
  /*
  LIST_USERS: {
    query: LIST_USERS,
    name: "listUsers",
    key: "id",
    value: "first_name",

  },
  LIST_USER: {
    query: LIST_USER,
    name: "listUser",
    key: "id",
    value: "first_name",
  },*/
};

interface SipsOptionInterface
  extends React.InputHTMLAttributes<HTMLInputElement> {
  query: string;
  invalid?: boolean;
}

class SipsOption extends React.Component<SipsOptionInterface> {
  render() {
    const query = queries[this.props.query];
    return (
      <Input
        {...this.props}
        type="select"
        placeholder={
          this.props.placeholder ? this.props.placeholder : "Selecciona..."
        }
        invalid={this.props.invalid ? this.props.invalid : false}
      >
        <Query query={query.query}>
          {
          ((result:any) => {

           const { loading, error, data } = result;
            if (loading) return <option>Loading…</option>;
            if (error) return <option>Error :(</option>;
                    console.log('VALOR DE SIP OPTIONS')
                    console.log(data)

            let c = 1;
            const ret = data[query.name].map((item) => {
              c++;
              return (
                <option key={c} value={item[query.key]}>
                  {item[query.value]}
                </option>
              );
            });
            ret.unshift(
              <option value="0" key={0}>
                {this.props.placeholder
                  ? this.props.placeholder
                  : "Seleccionar..."}
              </option>
            );
            return ret;
          })}
        </Query>



      </Input>
    );
  }
}

export default SipsOption;
