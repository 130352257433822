import React from "react";

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col} from "reactstrap";
import { withApollo } from "react-apollo";
import { Link } from 'react-router-dom';
import axios from 'axios';
import moment from "moment";
import 'moment/locale/es';
import Reflux from "reflux";
import { UserStore } from "../../store/UserStore";

import { GET_COMERCIAL_DETAIL } from "../../queries/comercial/detail";
import { GET_USER_DATA} from "../../queries/users/users.queries";
import numberWithPoints from "../../utils/numberWithPoints";
import {ThunderboltFilled,FireFilled} from '@ant-design/icons';
import listaSubmin from "../../assets/img/icons/custom/lista_submin.svg";
import listaSubminGas from "../../assets/img/icons/custom/lista_submin_gas.svg";
import listaEmpresas from "../../assets/img/icons/custom/lista_empresas.svg";
import listadoPS from "../../assets/img/icons/custom/listadoPS.svg";
import propuestaMulti from "../../assets/img/icons/custom/propuestaMulti.svg";
import gestionPropuestas from "../../assets/img/icons/custom/gestion_propuestas.svg";
import gestionContratos from "../../assets/img/icons/custom/gestion_contratos.svg";
import { CSVLink, CSVDownload } from "react-csv";
import DataTable from 'react-data-table-component';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';

import CircularProgress from '@material-ui/core/CircularProgress';

const sortIcon = <ArrowDownward />;

const columnsDatosClientes = [

    { selector: row => row.sesiones, name: 'SESIONES', sortable: true, width: '120px'},
    { selector: row => row.ultimaFechaAcceso, name: 'ULTIMO ACCESO', sortable: true, width: '140px', center: true },
    { selector: row => row.ip_address, name: 'IP', sortable: true, width: '140px', center: true },
    { selector: row => row.id, name: 'ID USUARIO', sortable: true, width: '120px', center: true},
    { selector: row => row.company, name: 'EMPRESA', sortable: true, width: '140px', center: true},
    { selector: row => row.first_name, name: 'NOMBRE', sortable: true, width: '140px', center: true, format: (row => capitalize(row.first_name)) },
    { selector: row => row.last_name, name: 'APELLIDO', sortable: true, width: '140px', center: true, format: (row => capitalize(row.last_name)) },
    { selector: row => row.email, name: 'CORREO', sortable: true, width: '140px', center: true },
    { selector: row => row.user_group_id, name: 'GRUPO', sortable: true, width: '140px', center: true },
    { selector: row => row.query_limit, name: 'LIMITE', sortable: true, width: '140px', center: true},

];


const columnsDatosClientesResumen = [

    { selector: row => row.usuarios, name: 'Nº USUARIOS', sortable: true, width: '120px'},
    { selector: row => row.company, name: 'EMPRESA', sortable: true, width: '300px', center: true, format: (row => capitalize(row.company)) },
    { selector: row => row.companyId, name: 'ID. EMPRESA', sortable: true, width: '120px', center: true},

];

const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.toLowerCase().charAt(0).toUpperCase() + s.slice(1)
}

const customStyles = {
    headRow: {
        style: {
            border: 'none',

        },
    },
    headCells: {
        style: {
            color: '#202124',
            fontSize: '12px',
            fontWeight: 'bold',
            wordBreak: 'break-word',
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px'

        },
    },
    cells: {
        style: {
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px',
            wordBreak: 'break-word',
            fontFamily: '"Open Sans", sans-serif;'
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 250, 250)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
            fontSize: '12px',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};


const Circular = () => (
    // we need to add some padding to circular progress to keep it from activating our scrollbar
    <div style={{ padding: '24px' }}>
        <CircularProgress size={75} />
    </div>
);

class Header extends Reflux.Component<any, any> {
  state = {
UserStore: {},
companyIdUser: '',
userName:'',
companyNameUser: '',
userGroupId: 1000,
userId: '',
idCard: "",
first_name1: "",
last_name1: "",
showIndexButton: false,
showFreeMarketButton: false,
    isLoading: false,
    dataResumenConexiones: [{
        sesiones:0,
        ultimaFechaAcceso:"",
        ip_address:"",
        id:"",
        company:"",
        first_name:"",
        last_name:"",
        email:"",
        password:"",
        created_at:"",
        modified_at:"",
        user_group_id:"",
        query_limit:0,
        profile_photo:"",
        profile_photo_dir:"",
        app_logo:"",
        app_logo_dir:"",
        company_info:"",
        locked_up:"",
        attempts_login:"",
        reset_token:"",
        reset_token_expire:"",
        companyId:"",
        newCompanyId:""
    }],
    dataResumenConexionesResumen:[{
        usuarios: 0,
        company: "",
        companyId:0
    }],
isLoading2: true,
    data: {
      supplier_id: 0,
      num_sites: 0,
      kwhanual: 0,
      supplier_name: "",
      supplier_code_ree: "",
      persona_fisica_total_sites: 0,
      persona_fisica_sumkwhanual: 0,
      persona_juridica_total_sites: 0,
      persona_juridica_sumkwhanual: 0,
    },
  };


  constructor(props) {
      super(props);
      this.store = UserStore;
  }


  componentDidMount() {


      this.getUserData();
      this.fetchItems();
     // this.QueryResumenContratos();
     // this.QueryResumenContratosFirmados();

  };

  async getUserData() {

    const resp = await this.props.client.query({
      query: GET_USER_DATA,
    });

    const user = resp.data.userDataAdministratorBackend;

    this.setState({

      first_name1: user.first_name,
      last_name1: user.last_name,
    });
    this.QueryResumenConexiones(user.email,user.companyId,user.id);
    this.QueryResumenConexionesResumen(user.email,user.companyId,user.id);
  }



  componentWillReceiveProps() {


      const usuario: any = JSON.parse(JSON.stringify(this.state.UserStore['data']))

      const companyNameGlobal = usuario.company;
      const companyIdUser = usuario.companyId;

      const userGroupId = usuario.user_group_id
      const userId = usuario.id
      const userName = usuario.first_name +" "+usuario.last_name
      const idCard = usuario.email

      let showIndexButton = usuario.indexada;
      let showFreeMarketButton = usuario.mercadolibre;
      let companyNameUser = usuario.company;

      if (userGroupId === 1000) {
          this.setState({
              activeUser: true
          })
      };

      this.setState({
          companyNameGlobal,
          companyIdUser,
          userGroupId,
          userId,
          showIndexButton,
          userName,
          showFreeMarketButton,
          companyNameUser,
          idCard

      });
  }

  UNSAFE_componentWillMount() {


      var userFromStorageTemp = localStorage.getItem("user");
      var parsedTemp = JSON.parse(userFromStorageTemp || '{}');
      var userIdTemp = parsedTemp.id;
      var companyIdUserTemp = parsedTemp.companyId;
      var userGroupId = parsedTemp.user_group_id;
      var company = parsedTemp.company;
      var idCard = parsedTemp.email;

      this.setState({

          companyIdUser: companyIdUserTemp,
          userId: userIdTemp,
          userGroupId: userGroupId,
          companyNameUser: company,
          idCard: idCard

      });

  };

///////////////////////////////////////////////////////////////////////////////////////////

fetchItems = async () => {
  let isLoading = true;
  this.setState({ isLoading });

  const sips = await this.props.client.query({
    query: GET_COMERCIAL_DETAIL,
  });
  const data = sips.data.getComercialDetail;

  isLoading = false;
  this.setState({ data, isLoading });
};

  //////////////////////////////////////////////////////////////////////////////////////

  QueryResumenConexiones = async (idcard1,companyIdUser1,userId1) => {

let isLoading = true;
const isSearching = true;
const companyId = companyIdUser1.toString()
const userId = '-1'


var userIdAux = userId1.toString();
var userQuery = userId1.toString();
var idCard = idcard1;
//const IdOferta = this.state.contrato;
//
      //const IdOferta = this.state.contrato;
      //
      const headers = {
          'Authorization': 'Bearer my-token',
          'My-Custom-Header': 'foobar',
          'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
      };
      let direccionFile = "https://datapi.psgestion.es/usuario/listadoactivos?id=" + companyId + "&user=" + userIdAux+ "&idcard=" + idCard;

      await axios.get(direccionFile, { headers }).
          then(result => {

              let data = result.data


              this.setState({
                  dataResumenConexiones: data,
                  isLoading2: false
              });
          }).catch((error) => {
              console.log('Failure ', error)
          });

  };



////////////////////////////////////////////////////////////////////////////////////////////


QueryResumenConexionesResumen = async (idcard1,companyIdUser1,userId1) => {

    let isLoading = true;
    const isSearching = true;
    const companyId = companyIdUser1.toString()
    const userId = '-1'


    var userIdAux = userId1.toString();
    var userQuery = userId1.toString();
    var idCard = idcard1;

const headers = {
        'Authorization': 'Bearer my-token',
        'My-Custom-Header': 'foobar',
        'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
    };
    let direccionFile = "https://datapi.psgestion.es/usuario/listadoactivosempresa?id=" + companyId + "&user=" + userIdAux + "&idcard=" + idCard;

    await axios.get(direccionFile, { headers }).
        then(result => {

            let data = result.data

            this.setState({
                dataResumenConexionesResumen: data
            });
        }).catch((error) => {
            console.log('Failure ', error)
        });

};

///////////////////////////////////////////////////////////////////////////////////////////

  render() {

const dataResumenConexiones = this.state.dataResumenConexiones
console.log('dataResumenConexionesResumen: ', this.state.dataResumenConexionesResumen)

    return (
      <>
        <div className="header bg-gradient-info pb-8 pt-5 pt-lg-5">

            <Container fluid>
<div className="header-body pb-4 pt-1 ">
            <Row>

           <h2 className="font-weight-bold mb-0">BIENVENIDO! {this.state.first_name1} </h2 >
            </Row>
</div>
            </Container>

        <Container fluid className="mb-4">

            <div className="header-body">
              <Row>

                <Col  md="3" xl="3">
                  <Link  to={{ pathname: "nuevo-cliente"}} id = "crear-cliente">
                  <Card className="card-stats mb-4 mb-xl-0 h-100 btn p-1">
                    <CardBody className="p-0">
                      <Row>
                        <ThunderboltFilled style={{ fontSize:'14px',color:'#ffffff'}} className="col-auto ml-auto mr-3 align-self-end badge rounded-circle bg-yellow p-1"/>
                      </Row>
                      <Row>
                        <Col className="col col-8 m-auto text-center">
                          <h1><img src={gestionPropuestas}/></h1>
                        </Col>
                      </Row>
                      <Row className="m-auto">
                        <div className="col">
                          <CardTitle
                            className="text-center mb-0"
                          >
                            Alta usuario
                          </CardTitle>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                  </Link>
                </Col>

                <Col  md="3" xl="3">
                  <Link  to={{ pathname: "modificar-cliente"}} id = "modificar-cliente">
                  <Card className="card-stats mb-4 mb-xl-0 h-100 btn p-1">
                    <CardBody className="p-0">
                      <Row>
                        <ThunderboltFilled style={{ fontSize:'14px',color:'#ffffff'}} className="col-auto ml-auto mr-3 align-self-end badge rounded-circle bg-yellow p-1"/>
                      </Row>
                      <Row>
                        <Col className="col col-8 m-auto text-center">
                          <h1><img src={gestionContratos}/></h1>
                        </Col>
                      </Row>
                      <Row className="m-auto">
                        <div className="col">
                          <CardTitle
                            className="text-center mb-0"
                          >
                            Modificar/Baja Usuario
                          </CardTitle>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                  </Link>
                </Col>

                <Col  md="3" xl="3">
                  <Link  to={{ pathname: "sesiones-cliente"}} id = "sesiones-cliente">
                  <Card className="card-stats mb-4 mb-xl-0 h-100 btn p-1">
                    <CardBody className="p-0">
                      <Row>
                        <ThunderboltFilled style={{ fontSize:'14px',color:'#ffffff'}} className="col-auto ml-auto mr-3 align-self-end badge rounded-circle bg-yellow p-1"/>
                      </Row>
                      <Row>
                        <Col className="col col-8 m-auto text-center">
                          <h1><img src={gestionContratos}/></h1>
                        </Col>
                      </Row>
                      <Row className="m-auto">
                        <div className="col">
                          <CardTitle
                            className="text-center mb-0"
                          >
                            Cerrar sesiones
                          </CardTitle>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                  </Link>
                </Col>

              </Row>


            </div>
          </Container>

          <Container fluid>
<div className="header-body pb-2 pt-1 ">
          <Row>
                      <h2 className="font-weight-bold mb-0">DATOS RESUMEN USUARIOS</h2 >
          </Row>
</div>
          </Container>
          <Container fluid>
              <Row className="margin-reset w-100">

                  <Card className="shadow">
                      <DataTable
                          title="LISTADO DE USUARIOS/ACTIVIDAD"
                          columns={columnsDatosClientes}
                          data={dataResumenConexiones}

                          //selectableRowsComponent={radio} // Pass the function only
                          sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you           fixedHeader = {true}
                          noDataComponent={"Sin datos para mostrar"}
                          pagination
                          paginationPerPage={20}
                          paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 40]}
                          highlightOnHover
                          striped
                          pointerOnHover
                          selectableRowsHighlight
                          customStyles={customStyles}
                          contextMessage={{ singular: 'producto', plural: 'productos', message: 'seleccionado' }}
                          subHeader
                          subHeaderComponent={

                              <div style={{ display: 'flex', alignItems: 'center' }}>

                                  <CSVLink
                                      data={this.state.dataResumenConexiones}
                                      filename={"ClientesRegistradosConexiones.csv"}
                                      className="btn btn-default btn-sm btn-circle"
                                      color="default"
                                      target="_blank"
                                      separator={";"}
                                  >

                                      <span className="btn-inner--icon">
                                          <i className={"bi bi-file-earmark-spreadsheet"} style={{ fontSize: 25 }}></i>
                                      </span>
                                      <span className="btn-inner--text">Fichero CSV</span>

                                  </CSVLink>
                              </div>
                          }
                          progressComponent={<Circular />}
                      />
                  </Card>


                  <Card className="shadow">
                      <DataTable
                          title="LISTADO DE USUARIOS POR EMPRESA"
                          columns={columnsDatosClientesResumen}
                          data={this.state.dataResumenConexionesResumen}

                          //selectableRowsComponent={radio} // Pass the function only
                          sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you           fixedHeader = {true}
                          noDataComponent={"Sin datos para mostrar"}
                          pagination
                          paginationPerPage={20}
                          paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 40]}
                          highlightOnHover
                          striped
                          pointerOnHover
                          selectableRowsHighlight
                          customStyles={customStyles}
                          contextMessage={{ singular: 'producto', plural: 'productos', message: 'seleccionado' }}
                          subHeader
                          subHeaderComponent={

                              <div style={{ display: 'flex', alignItems: 'center' }}>

                                  <CSVLink
                                      data={this.state.dataResumenConexionesResumen}
                                      filename={"ClientesRegistradosEmpresasResumens.csv"}
                                      className="btn btn-default btn-sm btn-circle"
                                      color="default"
                                      target="_blank"
                                      separator={";"}
                                  >

                                      <span className="btn-inner--icon">
                                          <i className={"bi bi-file-earmark-spreadsheet"} style={{ fontSize: 25 }}></i>
                                      </span>
                                      <span className="btn-inner--text">Fichero CSV</span>

                                  </CSVLink>
                              </div>
                          }
                          progressComponent={<Circular />}
                      />
                  </Card>
</Row>


          </Container>





        </div>
      </>
    );
  }
}

export default withApollo(Header);
