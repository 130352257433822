import React from "react";
import Reflux from "reflux";
import axios from 'axios';
import { removeToken } from "../../utils";
import { validateSpanishId, spainIdType } from 'spain-id';
import {
    Card, Container, Row, CardTitle, CardBody, Label,
    FormGroup,
    Input, FormText,
    Form, Col, Button,
    UncontrolledTooltip, CardHeader, Collapse, FormFeedback
} from "reactstrap";
// core components
import Header from "../../components/Headers/Header";
import { withApollo } from "react-apollo";
import moment from "moment";
import 'moment/locale/es'
import client from "../../api/client";

import ReactBSAlert from "react-bootstrap-sweetalert";
import { UserStore } from "../../store/UserStore";
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import { LIST_DICMUNICIPIOS, LIST_DICMUNICIPIOSPOSTALSHORT } from "../../queries/options/dic-option.graphql";


var DatePicker = require("reactstrap-date-picker");

const spanishDayLabels = ['Dom', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sab'];
const spanishMonthLabels = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];


const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.toLowerCase().charAt(0).toUpperCase() + s.slice(1)
}

const sortIcon = <ArrowDownward />;


class crearEmpresas extends Reflux.Component<any, any> {
    public formRef: any = null;
    public maxFileSize = 3;
    public validFileExtensions = ["jpg", "jpeg", "png"];
    lastFilters = {};
    state = {
        UserStore: {},
        isLoading: false,
        currentPage: 0,
        pageSize: 0,
        pageCount: 0,
        count: 0,
        contrato: "",
        tipocliente: "",
        empresaDetailTotal: [],
        empresaSeleccionado: false,
        idUser: '',
        municipios: [{ key: "", value: 0, autonomia: "", provincia: "" }],
        empresaDetail: {
            id: 0,
            idEmpresa: "",
            nif: "",
            tipo: "",
            tipoServicio: "",
            numOrden: "SIN ORDEN",
            empresa: "",
            direccion: "",
            codigoPostal: "",
            municipio: "",
            provincia: "",
            telefonoAtt: "",
            telefonoUrgencias: "",
            ambito: "",
            fechaAlta: "",
            fechaBaja: "",
            web: "",
            estado: "a",
            correoContacto: "",
            nombreContacto: "",
            telefonoContacto: "",
            fechAltaDatos: "",
            usuario: 0,
            logoApp: "https://datapi.psgestion.es/files/images/dynargy_logo_verde_small.png",
            logoPropuesta: "https://datapi.psgestion.es/files/images/dynargy_logo_verde_small.png",
            autonomia: "",
            licencias: 1
        },
        formEmpresa: {
            id: 0,
            idEmpresa: "",
            nif: "",
            tipo: "",
            tipoServicio: "",
            numOrden: "SIN ORDEN",
            empresa: "",
            direccion: "",
            codigoPostal: "",
            municipio: "",
            provincia: "",
            telefonoAtt: "",
            telefonoUrgencias: "",
            ambito: "",
            fechaAlta: "",
            fechaBaja: "",
            web: "",
            estado: "a",
            correoContacto: "",
            nombreContacto: "",
            telefonoContacto: "",
            fechAltaDatos: "",
            usuario: "",
            logoApp: "https://datapi.psgestion.es/files/images/dynargy_logo_verde_small.png",
            logoPropuesta: "https://datapi.psgestion.es/files/images/dynargy_logo_verde_small.png",
            licencias: 1,
            autonomia: ""
        },

        companyIdUser: '',
        companyNameGlobal: '',
        userGroupId: 1000,
        userId: '',
        esAgencia: false,
        esComercializadora: false,
        esAdFincas: false,
        saveDataUsuario: false,
        updateDataUsuario: false,
        saveUsuarioButtonDisabled: true,
        updateUsuarioButtonDisabled: true,
        saveXMLButtonDisabled: true,
        buscarButtonDisabled: true,
        NIFClienteError: false,
        inputDisabled: false,
        show: false,
        saveButtonDisabled: true,
        showFilters: true,
        toggledClearRows: false,
        cupsError: false,
        cifError: false,
        locale: 'es',
        inputNombreProductoDisabled: false,
        inputCifDisabled: false,
        inputIdDisabled: false,
        createdAt: '',
        updatedAt: '',
        periodoValidezHasta: '',
        periodoValidezDesde: '',
        CodigoPostalTitularError: false,
        apellido1TitularError: false,
        emailError: false,
        nombreTitularError: false,
        NIFError: false,
        DireccionFiscalError: false,
        TelefonoFijoError: false,
        TelefonMovilError: false,
        cnaeAux: "",
        ibanError: false,
        alert: null,
        createResult: [],
        createResult2: [],
        dicMunicipios: [],
        dicMunicipiosList: [],
        errores: {
            codigoPostal: false,
            nif: false
        },
        textErrores: {
            codigoPostal: "INTRODUZCA MUNICIPIO/CODIGO POSTAL",
            nif: "INTRODUZCA NIF/CIF DEL CLIENTE"
        }

    };




    constructor(props) {
        super(props);
        this.store = UserStore;
        this.formRef = React.createRef();
        this.confirmAlertExit = this.confirmAlertExit.bind(this);
        this.logout = this.logout.bind(this);

    }

    componentDidMount() {

        const usuario: any = JSON.parse(JSON.stringify(this.state.UserStore['data']))

        const companyNameGlobal = usuario.company;
        const companyIdUser = usuario.companyId;

        const userGroupId = usuario.user_group_id
        const userId = usuario.id
        const esComercializadora = usuario.comercializadora
        const esAgencia = usuario.agencia
        const esAdFincas = usuario.adFincas



        if (userGroupId === 1000) {
            this.setState({
                activeUser: true
            })
        };

        this.setState({
            companyNameGlobal,
            companyIdUser,
            userGroupId,
            userId,
            esComercializadora,
            esAgencia,
            esAdFincas

        });


        this.getMunicipiosLista();
    }

    //////////////////////////////////////////////////////////////////////////////////////7


    async getMunicipiosLista() {

        await client.query({
            query: LIST_DICMUNICIPIOS,
            variables: {}
        })
            .then((result) => {
                let data = result.data.listDicMunicipios;
                this.setState({ dicMunicipios: data })
            });
    };

    fetchItemsMunicipio = async (codigoMunicipio) => {
        const codigoPostal = codigoMunicipio
        await client.query({
            query: LIST_DICMUNICIPIOSPOSTALSHORT,
            variables: { codigoPostal: codigoPostal }
        })
            .then((result) => {
                let data = result.data.listDicMunicipiosPostalShort;
                this.setState({ dicMunicipiosList: data })
            });
    };

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////

    logout = async () => {

        removeToken();
        localStorage.removeItem("lastFilters");
        localStorage.removeItem("user");
        localStorage.removeItem("lastFiltersEmpre");
        localStorage.removeItem("lastFiltersMulti");
        localStorage.removeItem('lastFiltersMultiGas');
        localStorage.removeItem('lastFiltersGas');
        this.props.client.cache.reset();
        this.props.client.resetStore();
        this.props.history.push(`/auth/login`);

    };


    //////////////////////////////////////////////////////////////////////////////////////7


    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    warningAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Error en la base de datos"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Existe un error en la Base de datos.
                </ReactBSAlert>
            )
        });
    };


    warningAlertRepetido = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Empresa Repetida"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    LA empresa ya se encuentra registrada.
                </ReactBSAlert>
            )
        });
    };



    warningAlertNIF = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="No existen registros que cumplan los requisitos"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Revise los requisitos introducidos
                </ReactBSAlert>
            )
        });
    };

    warningAlertFiltros = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Existen campos obligatorios sin información"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Revise los datos introducidos
                </ReactBSAlert>
            )
        });
    };

    confirmAlertSave = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="¿Desea crear la empresa"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.onUpdate()}
                    showCancel
                    confirmBtnBsStyle="secondary"
                    confirmBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    cancelBtnText="Guardar"
                    btnSize=""
                >
                </ReactBSAlert>
            )
        });
    };

    confirmAlertExit = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Vuelva a iniciar sesión"
                    onConfirm={() => this.logout()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Salir"
                    btnSize=""
                    focusConfirmBtn
                >
                    Se ha cerrado la sesión por seguridad. Por favor, vuelva a iniciar sesión
                </ReactBSAlert>
            )
        });
    };


    successAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Correcto"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Empresa guardada correctamente
                </ReactBSAlert>
            )
        });
    };

    successAlertLogo = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Correcto"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Logo actualizado correctamente
                </ReactBSAlert>
            )
        });
    };

    warningFicheroGrande = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Fichero seleccionado supera el lìmite de tamaño"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    El fichero seleccionado supera el límite de tamaño
                </ReactBSAlert>
            )
        });
    };


    warningFicheroPermitido = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Fichero seleccionado no permitido"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Solo se permiten las siguientes extensiones: jpg, jpeg, png
                </ReactBSAlert>
            )
        });
    };

    errorDB = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Existe un problema con el servidor"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Existe un problema con el servidor. Si persite este error, por favor, contacte con el área de soporte
                </ReactBSAlert>
            )
        });
    };



    ///////////////////////////////////////////////////////////////////////////////////////////


    onChangeFieldUsuario = (event) => {

        event.persist()
        let errorsData = { ...this.state.errores }
        let textErroresData = { ...this.state.textErrores }
        var formData = { ...this.state.formEmpresa };
        var target = event.target;
        var value =
            event.target.type === "checkbox"
                ? event.target.checked
                : event.target.value;
        const name = event.target.name;

        console.log('valor de formData: ', formData)
        console.log('valor de target: ', target)
        console.log('valor de value: ', value)

        var municipio: any = "";
        var autonomia = "";
        var provincia = "";

        const nameFilters = ['idEmpresa', 'nif', 'tipo', 'tipoServicio', 'numOrden', 'empresa', 'direccion', 'codigoPostal', 'municipio', 'provincia', 'telefonoAtt', 'telefonoUrgencias', 'ambito', 'fechaAlta', 'fechaBaja', 'web', 'estado', 'correoContacto', 'nombreContacto', 'telefonoContacto', 'fechAltaDatos', 'usuario']
        const optionsFilters = ['user_group_id', 'esActiva', 'esAdmin', 'esComercial', 'esConsultor', 'esFuncionario', 'informes', 'detalles'];

        if (nameFilters.indexOf(event.target.name) != -1) {
            this.setState({ saveDataUsuario: true });
            this.setState({
                saveUsuarioButtonDisabled: false,
                updateUsuarioButtonDisabled: false
            })
        }
        if (event.target.name == 'empresa' && event.target.value.length > 0) {

            this.setState({ saveDataUsuario: true });
            this.setState({
                saveUsuarioButtonDisabled: false,
                updateUsuarioButtonDisabled: false
            })
        }
        if (event.target.name == 'apellidos' && event.target.value.length > 0) {

            this.setState({ saveDataUsuario: true });
            this.setState({
                saveUsuarioButtonDisabled: false,
                updateUsuarioButtonDisabled: false
            })
        }

        if (event.target.name === 'correoContacto') {

            const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
            //const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
            const result = pattern.test(event.target.value);

            if (result === true) {
                this.setState({
                    emailError: false,
                })
            } else {
                this.setState({
                    emailError: true
                })
            }
        };

        if (optionsFilters.indexOf(event.target.name) != -1 && event.target.value == 'Selecciona...') {
            value = null
        }

        if (value === "true" || value == "false") {
            value = JSON.parse(value);
        };


        console.log('valor de event.target.name: ', event.target.name, ' , value: ', event.target.value)

        if (event.target.name === 'codigoPostal') {
            if (event.target.value) {
                errorsData['codigoPostal'] = true
                textErroresData['codigoPostal'] = ""
            } else {
                errorsData['codigoPostal'] = false
                textErroresData['codigoPostal'] = "SELECCIONE CODIGO POSTAL"
            }
        };


        if (name == "nif" && (!value || value.length == 0 || value == "")) {
            errorsData['nif'] = false
            textErroresData['nif'] = "INTRODUZCA NIF/CIF DEL CLIENTE"
        } else if (name == "nif" && (value || value.length > 0 || value !== "")) {
            const result = validateSpanishId(event.target.value)
            if (result === true) {
                const resultTipo = spainIdType(event.target.value)
                //formData['tipoTitular'] = resultTipo.toUpperCase();
                errorsData['nif'] = true
                textErroresData['nif'] = ""
                this.setState({
                    isDisabledCIF: false
                })
            } else {
                errorsData['nif'] = false
                textErroresData['nif'] = "NIF/CIF INCORRECTO"
                this.setState({
                    isDisabledCIF: true
                })
            }
        };

        if (name == 'municipio') {

            const valueArray = event.target.value.split("*");
            value = valueArray[1]
            let codigoMunicipio = valueArray[0]
            const municipio: any = this.state.dicMunicipios
            const resultado = municipio.find(municipio => municipio.Cmunicipio === codigoMunicipio);
            //const filtered = municipio.filter(entry => Object.values(entry).some(val => typeof val === "string" && val.includes('Barromán')));
            const valorEntidad = resultado.Cmunicipio
            formData['provincia'] = resultado.DescProvincia
            formData['autonomia'] = resultado.DescAutonomia
            this.fetchItemsMunicipio(valorEntidad);
            formData['codigoPostal'] = 'Selecciona CP'

        };

        formData[name] = value;
        //if (value === "" || value === null) {
        //    formData[name] = 0;
        //
        //}
        this.setState({
            saveDataCliente: true
        })
        this.setState({
            formEmpresa: formData,
            errores: errorsData,
            textErrores: textErroresData
        });
    };

    //////////////////////////////////////////////////////////////////////////////////////////////////

    showFiltersToggle = () => {
        const showFilters = !this.state.showFilters;

        this.setState({ showFilters });
    };

    ///////////////////////////////////////////////////////////////////////////////////////

    getFilterIcon = () => {
        return this.state.showFilters ? "ni-bold-up" : "ni-bold-down";
    };

    //////////////////////////////////////////////////////////////////////////////////////////////////

    clearForm = () => {
        const filters = {};
        this.setState({
            inputCifDisabled: false,
            inputNombreProductoDisabled: false,
            inputIdDisabled: false,
            inputDisabled: false,
            buscarButtonDisabled: true,
            empresaSeleccionado: false,
            empresaDetailTotal: [],
            formEmpresa: {
                id: "",
                idEmpresa: "",
                nif: "",
                tipo: "",
                tipoServicio: "",
                numOrden: "SIN ORDEN",
                empresa: "",
                direccion: "",
                codigoPostal: "",
                municipio: "",
                provincia: "",
                telefonoAtt: "",
                telefonoUrgencias: "",
                ambito: "",
                fechaAlta: "",
                fechaBaja: "",
                web: "",
                estado: "a",
                correoContacto: "",
                nombreContacto: "",
                telefonoContacto: "",
                fechAltaDatos: "",
                usuario: "",
                logoApp: "https://datapi.psgestion.es/files/images/dynargy_logo_verde_small.png",
                logoPropuesta: "https://datapi.psgestion.es/files/images/dynargy_logo_verde_small.png",
                licencias: 1,
                autonomia: ""
            },
            toggledClearRows: !this.state.toggledClearRows,
            saveUsuarioButtonDisabled: true,
            updateUsuarioButtonDisabled: true,
            createdAt: '',
            updatedAt: '',
            saveDataUsuario: false,
            errores: {
                codigoPostal: false,
                nif: false
            },
            textErrores: {
                codigoPostal: "INTRODUZCA MUNICIPIO/CODIGO POSTAL",
                nif: "INTRODUZCA NIF/CIF DEL CLIENTE"
            }
        });

        this.formRef.current.reset();

    };

    ///////////////////////////////////////////////////////////////////////////////////

    updateHandler(props) {

        let existenErrores = Object.keys(this.state.errores).some(k => !this.state.errores[k]);

        if (existenErrores == true) {
            this.warningAlertFiltros()
        } else {

            this.confirmAlertSave()
        }
    };

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    onUpdate = async () => {

        //const fechaActivacion = this.state.fechaActivacion;
        const uuidUser = localStorage.getItem("uuidUser")
        const token = localStorage.getItem("token")
        const userData: any = localStorage.getItem("user")

        const datosUsuario = JSON.parse(JSON.stringify(this.state.UserStore['data']))

        const user: any = localStorage.getItem("user");

        var datosUser: any = []
        if (user) {
            datosUser = JSON.parse(user);
            //this.setState({ user: parsed });
        }


        //const codigoINE = this.state.municipioBuscar ? this.state.municipioBuscar : ""
        var iduser = datosUser.userId ? datosUser.userId : ""
        var idEmpresa = datosUser.idEmpresa ? datosUser.idEmpresa : ""




        if (this.state.saveDataUsuario == true) {


            var createdAt = this.state.formEmpresa.fechAltaDatos;

            if (!createdAt) {
                createdAt = moment().format("YYYY-MM-DDTHH:mm:ss");
            }

            var empresaDataUpdate = {}

            empresaDataUpdate = {
                'id': "",
                'idEmpresa': "",
                'nif': this.state.formEmpresa.nif,
                'tipo': this.state.formEmpresa.tipo,
                'tipoServicio': this.state.formEmpresa.tipoServicio,
                'numOrden': this.state.formEmpresa.numOrden,
                'empresa': this.state.formEmpresa.empresa?.toUpperCase() || '',
                'direccion': this.state.formEmpresa.direccion?.toUpperCase() || '',
                'codigoPostal': this.state.formEmpresa.codigoPostal,
                'municipio': this.state.formEmpresa.municipio?.toUpperCase() || '',
                'provincia': this.state.formEmpresa.provincia?.toUpperCase() || '',
                'telefonoAtt': this.state.formEmpresa.telefonoAtt,
                'telefonoUrgencias': this.state.formEmpresa.telefonoUrgencias,
                'ambito': this.state.formEmpresa.ambito?.toUpperCase() || '',
                'fechaAlta': this.state.formEmpresa.fechaAlta,
                'fechaBaja': this.state.formEmpresa.fechaBaja,
                'web': this.state.formEmpresa.web,
                'estado': this.state.formEmpresa.estado,
                'correoContacto': this.state.formEmpresa.correoContacto,
                'nombreContacto': this.state.formEmpresa.nombreContacto?.toUpperCase() || '',
                'telefonoContacto': this.state.formEmpresa.telefonoContacto,
                'fechAltaDatos': this.state.formEmpresa.fechAltaDatos,
                'usuario': iduser,
                'logoApp': this.state.formEmpresa.logoApp,
                'logoPropuesta': this.state.formEmpresa.logoPropuesta,
                'licencias': this.state.formEmpresa.licencias,
                'autonomia': this.state.formEmpresa.autonomia,
                'idAlianza':""
            };


            const headers = {
                'Authorization': 'Bearer my-token',
                'My-Custom-Header': 'foobar',
                'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
            };

            let direccionFile = "https://datapi.psgestion.es/empresas/empresacreate?id=" + idEmpresa + "&user=" + iduser;


            axios.post(direccionFile, empresaDataUpdate, { headers, responseType: 'json' })
                .then((result) => {

                    console.log('Actualización correcta', result)
                    console.log('Datpos del registro: ', result.data)
                    this.successAlert();
                    this.setState({
                        inputDisabled: false,
                        empresaSeleccionado: false,
                        empresaDetailTotal: [],
                        saveDataUsuario: false,
                        saveUsuarioButtonDisabled: true,
                        updateUsuarioButtonDisabled: true,
                        createdAt: '',
                        updatedAt: '',
                        toggledClearRows: !this.state.toggledClearRows,
                        errores: {
                            codigoPostal: false,
                            nif: false
                        },
                        textErrores: {
                            codigoPostal: "INTRODUZCA MUNICIPIO/CODIGO POSTAL",
                            nif: "INTRODUZCA NIF/CIF DEL CLIENTE"
                        },
                        formEmpresa: {
                            id: "",
                            idEmpresa: "",
                            nif: "",
                            tipo: "",
                            tipoServicio: "",
                            numOrden: "SIN ORDEN",
                            empresa: "",
                            direccion: "",
                            codigoPostal: "",
                            municipio: "",
                            provincia: "",
                            telefonoAtt: "",
                            telefonoUrgencias: "",
                            ambito: "",
                            fechaAlta: "",
                            fechaBaja: "",
                            web: "",
                            estado: "a",
                            correoContacto: "",
                            nombreContacto: "",
                            telefonoContacto: "",
                            fechAltaDatos: "",
                            usuario: "",
                            logoApp: "https://datapi.psgestion.es/files/images/dynargy_logo_verde_small.png",
                            logoPropuesta: "https://datapi.psgestion.es/files/images/dynargy_logo_verde_small.png",
                            licencias: 1,
                            autonomia: ""
                        }
                    });
                }).catch((error) => {
                    this.setState({
                        isLoading: false,
                    });
                });
        }
    };


    ///////////////////////////////////////////////////////////////////////////////////////////////////////////

    handleChangeDate = (value, formattedValue, id) => {

        var formData = { ...this.state.formEmpresa };


        const name = id;
        var valueDay = formattedValue

        if (name == 'createdAt') {
            this.setState({ createdAt: value });

        }

        if (name == 'updatedAt') {
            this.setState({ updatedAt: value });
        }

        formData[name] = value;
        //if (value === "" || value === null) {
        //    formData[name] = 0;
        //}
        this.setState({ formData });
    };



    /////////////////////////////////////////////////////////////////////////////////

    handleClearRows = () => {
        this.setState({ toggledClearRows: !this.state.toggledClearRows })
    }

    ////////////////////////////////////// GESTION DE IMAGENES /////////////////////////

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    validateFile(file) {
        const extension = file.name.split(".").pop() || "";
        // Get length of file in bytes
        const fileSizeInBytes = file.size;
        // Convert the bytes to Kilobytes (1 KB = 1024 Bytes)
        const fileSizeInKB = fileSizeInBytes / 1024;
        // Convert the KB to MegaBytes (1 MB = 1024 KBytes)
        const fileSizeInMB = fileSizeInKB / 1024;
        if (!this.validFileExtensions.includes(extension)) {
            const extensionsAllowed = this.validFileExtensions.join(", ");
            this.warningFicheroPermitido()
            return false;
        }
        if (fileSizeInMB > this.maxFileSize) {
            this.warningFicheroGrande()
            return false;
        }

        return true;

        // onChangeFile(file);
    }


    // On App Logo Change

    onAppLogoChange = (e) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];
        let file2 = e.target.files

        if (this.validateFile(file)) {

            var formData = new FormData();

            formData.append('file', file)

            const userID: any = localStorage.getItem("uuidUser") ? localStorage.getItem("uuidUser") : ""
            const userCompany: any = localStorage.getItem("companyId") ? localStorage.getItem("companyId") : ""


            //var user: any = [];

            const datosUsuario = JSON.parse(JSON.stringify(this.state.UserStore['data']))

            const user: any = localStorage.getItem("user");

            var datosUser: any = []


            const headers = {
                'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W',
                "Content-Type": "multipart/form-data"
            };
            let direccionFile = "https://datapi.psgestion.es/files/upload/logo/empresa?id=" + this.state.companyIdUser + "&user=" + this.state.userId;


            axios.post(direccionFile, formData, { headers }
            ).then(result => {
                let direccionImagen = result.data.resultado
                var formData = { ...this.state.formEmpresa };

                formData['logoApp'] = direccionImagen

                this.setState({
                    isLoading: false,
                    formEmpresa: formData
                })

                this.successAlertLogo()
                // UserStoreActions.update(result.data);

            }).catch((error) => {
                console.log('Failure: ', error)
                this.errorDB()
                this.setState({
                    isLoading: false,
                });

            });
            reader.readAsDataURL(file);
        }
    };


    onAppLogoChangePropuesta = (e) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];
        let file2 = e.target.files

        if (this.validateFile(file)) {

            var formData = new FormData();

            formData.append('file', file)

            const userID: any = localStorage.getItem("uuidUser") ? localStorage.getItem("uuidUser") : ""
            const userCompany: any = localStorage.getItem("companyId") ? localStorage.getItem("companyId") : ""

            const datosUsuario = JSON.parse(JSON.stringify(this.state.UserStore['data']))

            const user: any = localStorage.getItem("user");

            var datosUser: any = []

            const headers = {
                'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W',
                "Content-Type": "multipart/form-data"
            };
            let direccionFile = "https://datapi.psgestion.es/files/upload/logo/propuesta?id=" + this.state.companyIdUser + "&user=" + this.state.userId;

            axios.post(direccionFile, formData, { headers }
            ).then(result => {
                let direccionImagen = result.data.resultado
                var formData = { ...this.state.formEmpresa };

                formData['logoPropuesta'] = direccionImagen

                this.setState({
                    isLoading: false,
                    formEmpresa: formData
                })

                this.successAlertLogo()
                // UserStoreActions.update(result.data);

            }).catch((error) => {
                console.log('Failure: ', error)
                this.errorDB()
                this.setState({
                    isLoading: false,
                });

            });
            reader.readAsDataURL(file);
        }
    }
    eventFire = (el, etype) => {
        if (el.fireEvent) {
            el.fireEvent("on" + etype);
        } else {
            var evObj = document.createEvent("Events");
            evObj.initEvent(etype, true, false);
            el.dispatchEvent(evObj);
        }
    };
    onAppLogoButtonClick = () => {
        const input = document.getElementById("label-upload-button");
        this.eventFire(input, "click");
    };

    onAppLogoPropuestaClick = () => {
        const input = document.getElementById("label-upload-propuesta-button");
        this.eventFire(input, "click");
    };

    //////////////////////////////////////////////////////////////////////////////////////////

    render() {

        const formEmpresa: any = this.state.formEmpresa;
        var previewImage = this.state.formEmpresa.logoApp ? this.state.formEmpresa.logoApp : ""
        var previewImagePropuesta = this.state.formEmpresa.logoPropuesta ? this.state.formEmpresa.logoPropuesta : ""

        console.log('VALOR DE THIS.STATE EN RENDER: ', this.state)


        //const listItems = createResult.map((d) => <li key="{d.idUser}">{d.idUser}</li>);

        if (this.state.userGroupId == 1000) {
            return (
                <>
                    <Header title={'Alta Empresa'} />
                    <Container className=" mt--7" fluid>

                        <Card className="mb-4">
                            <CardHeader>
                                <Button
                                    onClick={this.showFiltersToggle}
                                    size="sm"
                                    className="float-right"
                                >
                                    <i className={"ni " + this.getFilterIcon()} />
                                </Button>
                                <h3 className="mb-0">Filtrar</h3>
                            </CardHeader>
                            <Collapse isOpen={this.state.showFilters}>
                                <CardBody>
                                    <Form innerRef={this.formRef}>
                                        <Row>

                                            <Col className="text-right">
                                                <Button onClick={this.clearForm}>
                                                    <i className={"ni ni-fat-remove"} /> Limpiar
                                                </Button>

                                            </Col>
                                        </Row>

                                    </Form>
                                </CardBody>
                            </Collapse>
                        </Card>

                        <Row>
                            <div className="col">
                                <Card className="shadow">
                                    <CardBody>
                                        <Row>
                                            <Col>
                                                <button
                                                    className="btn btn-sm btn-primary"
                                                    onClick={this.props.history.goBack}
                                                >
                                                    Atrás
                                                </button>
                                            </Col>

                                            <Col className="col-auto d-flex">
                                                <Card className="p-3 bg-light">
                                                    <FormGroup>

                                                        <Button disabled={this.state.saveUsuarioButtonDisabled} id="saveRepreProptooltip" color="danger" size="m" type="button" className="btn-icon btn-sm btn-circle"
                                                            onClick={() => { this.updateHandler(this.state.saveDataUsuario) }}
                                                        >
                                                            <span className="btn-inner--icon">
                                                                <i className="bi bi-person-plus-fill" style={{ fontSize: 25 }}></i>
                                                            </span>
                                                            <span className="btn-inner--text">Añadir</span>
                                                            <UncontrolledTooltip delay={0} placement="top" target="saveRepreProptooltip">
                                                                AÑADIR EMPRESA AL REGISTRO
                                                            </UncontrolledTooltip>
                                                        </Button>


                                                    </FormGroup>
                                                </Card>
                                            </Col>

                                        </Row>
                                        <div>
                                            <CardTitle>DATOS GENERALES EMPRESA</CardTitle>
                                        </div>

                                        <Row>
                                            <Col>
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="id">
                                                        ID
                                                    </Label>
                                                    <Input
                                                        className="text-primary"
                                                        id="id"
                                                        placeholder=""
                                                        name="nombre"
                                                        maxLength={100}
                                                        onChange={this.onChangeFieldUsuario}
                                                        value={formEmpresa.id}
                                                        type="text"
                                                        disabled={true}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="empresa">
                                                        Razón Social
                                                    </Label>
                                                    <Input
                                                        className="text-primary"
                                                        id="empresa"
                                                        placeholder=""
                                                        name="empresa"
                                                        maxLength={100}
                                                        onChange={this.onChangeFieldUsuario}
                                                        value={formEmpresa.empresa}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="numOrden">
                                                        N Orden
                                                    </Label>
                                                    <Input
                                                        className="text-primary"
                                                        id="numOrden"
                                                        placeholder=""
                                                        name="numOrden"
                                                        maxLength={10}
                                                        onChange={this.onChangeFieldUsuario}
                                                        value={formEmpresa.numOrden}
                                                        type="text"
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col>
                                                <FormGroup>
                                                    <Label className="form-control-label" htmlFor="nif">
                                                        CIF
                                                    </Label>
                                                    <Input
                                                        id="nif"
                                                        placeholder=""
                                                        name="nif"
                                                        maxLength={16}
                                                        onChange={this.onChangeFieldUsuario}
                                                        value={formEmpresa.nif}
                                                        type="text"
                                                        invalid={this.state.errores.nif == false}
                                                    />
                                                    <FormFeedback>{this.state.textErrores.nif}</FormFeedback>
                                                </FormGroup>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="direccion">
                                                        Dirección
                                                    </Label>
                                                    <Input
                                                        id="direccion"
                                                        placeholder=""
                                                        name="direccion"
                                                        maxLength={150}
                                                        onChange={this.onChangeFieldUsuario}
                                                        value={formEmpresa.direccion}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="3">
                                                <Label className="form-control-label" htmlFor="codigoPostal">
                                                    C.P.
                                                </Label>
                                                <Input type="select" value={formEmpresa.codigoPostal} name="codigoPostal" id="codigoPostal" invalid={this.state.errores.codigoPostal == false} onChange={this.onChangeFieldUsuario}>
                                                    <option value={formEmpresa.codigoPostal} disabled>{formEmpresa.codigoPostal}</option>
                                                    {this.state.dicMunicipiosList.map((item: any, key) => (
                                                        <option key={key} value={item.codigoPostal}>{item.codigoPostal} - {item.entidadsingularnombre}</option>
                                                    ))
                                                    }
                                                </Input>
                                                <FormFeedback>{this.state.textErrores.codigoPostal}</FormFeedback>
                                            </Col>


                                            <Col md="3">
                                                <Label className="form-control-label" htmlFor="nombreMunicipio">
                                                    Municipio
                                                </Label>
                                                <Input type="select" value={formEmpresa.municipio} name="municipio" id="municipio" onChange={this.onChangeFieldUsuario}>
                                                    <option value={formEmpresa.municipio} disabled>{formEmpresa.municipio}</option>
                                                    {this.state.dicMunicipios.map((item: any, key) => (
                                                        <option key={key} value={item.Cmunicipio + "*" + item.Nombre}>{item.Nombre}</option>
                                                    ))
                                                    }
                                                </Input>
                                            </Col>

                                            <Col>
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="provincia">
                                                        Provincia
                                                    </Label>
                                                    <Input
                                                        id="provincia"
                                                        placeholder=""
                                                        name="provincia"
                                                        maxLength={150}
                                                        onChange={this.onChangeFieldUsuario}
                                                        value={formEmpresa.provincia}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col lg = "auto">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="autonomia">
                                                        Autonomia
                                                    </Label>
                                                    <Input
                                                        id="autonomia"
                                                        placeholder=""
                                                        name="autonomia"
                                                        maxLength={150}
                                                        onChange={this.onChangeFieldUsuario}
                                                        value={formEmpresa.autonomia}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>

                                        </Row>

                                        <Row>
                                            <Col md="2">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="nombreContacto">
                                                        Nombre Contacto
                                                    </Label>
                                                    <Input
                                                        className="text-primary"
                                                        id="nombreContacto"
                                                        placeholder=""
                                                        name="nombreContacto"
                                                        maxLength={80}
                                                        onChange={this.onChangeFieldUsuario}
                                                        value={formEmpresa.nombreContacto}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>


                                            <Col md="2">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="telefonoContacto">
                                                        Teléfono Contacto
                                                    </Label>
                                                    <Input
                                                        className="text-primary"
                                                        id="telefonoContacto"
                                                        placeholder=""
                                                        name="telefonoContacto"
                                                        maxLength={20}
                                                        onChange={this.onChangeFieldUsuario}
                                                        value={formEmpresa.telefonoContacto}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col md="3">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="correoContacto">
                                                        Correo Contacto
                                                    </Label>
                                                    <Input
                                                        id="correoContacto"
                                                        placeholder=""
                                                        name="correoContacto"
                                                        maxLength={80}
                                                        onChange={this.onChangeFieldUsuario}
                                                        value={formEmpresa.correoContacto}
                                                        type="text"
                                                        disabled={this.state.inputDisabled}

                                                    />
                                                </FormGroup>
                                            </Col>

                                        </Row>

                                    </CardBody>
                                </Card>

                                <Card className="shadow">
                                    <CardBody>
                                        <div>
                                            <CardTitle>LOGOS</CardTitle>
                                        </div>

                                        <Row>

                                            <Col md="3" lg="3">
                                                <FormGroup>
                                                    <Label className="form-control-label" for="tipo">Logo Empresa</Label>
                                                    {previewImage && (
                                                        <div>
                                                            <img className="preview" src={previewImage} alt="" />
                                                        </div>
                                                    )}
                                                    <FormGroup>
                                                        <label htmlFor="upload-button" id="label-upload-button">
                                                            <span className="btn btn-sm btn-primary mt-3">
                                                                Subir logo
                                                            </span>
                                                        </label>
                                                        <input
                                                            type="file"
                                                            id="upload-button"
                                                            style={{ display: "none" }}
                                                            onChange={this.onAppLogoChange}
                                                            accept="image/*"
                                                        />
                                                    </FormGroup>

                                                </FormGroup>
                                            </Col>



                                            <Col md="3" lg="3">
                                                <FormGroup>
                                                    <Label className="form-control-label" for="tipo">Logo Propuesta</Label>
                                                    {previewImagePropuesta && (
                                                        <div>
                                                            <img className="preview" src={previewImagePropuesta} alt="" />
                                                        </div>
                                                    )}
                                                    <FormGroup>
                                                        <label htmlFor="upload-button-propuesta" id="label-upload-propuesta-button">
                                                            <span className="btn btn-sm btn-primary mt-3">
                                                                Subir logo
                                                            </span>
                                                        </label>
                                                        <input
                                                            type="file"
                                                            id="upload-button-propuesta"
                                                            style={{ display: "none" }}
                                                            onChange={this.onAppLogoChangePropuesta}
                                                            accept="image/*"
                                                        />
                                                    </FormGroup>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>

                                <Card className="shadow">
                                    <CardBody>
                                        <div>
                                            <CardTitle>OTROS DATOS</CardTitle>
                                        </div>

                                        <Row>
                                            <Col md="2" lg="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" for="tipo">Tipo Empresa</Label>
                                                    <Input type="select" name="tipo" id="tipo"
                                                        disabled={this.state.inputDisabled}
                                                        onChange={this.onChangeFieldUsuario}
                                                        value={formEmpresa.tipo}>
                                                        <option selected>Selecciona...</option>
                                                        <option value="COM"> Comercializadora</option>
                                                        <option value="DIS"> Distribuidora</option>
                                                        <option value="AGN"> Agencia</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>

                                            <Col md="2" lg="2">
                                                <FormGroup>
                                                    <Label className="form-control-label" for="tipoverde">Tipo Servicio</Label>
                                                    <Input type="select" name="tipoServicio" id="tipoServicio"
                                                        disabled={this.state.inputDisabled}
                                                        onChange={this.onChangeFieldUsuario}
                                                        value={formEmpresa.tipoServicio}>
                                                        <option selected>Selecciona...</option>
                                                        <option value="ELEC">Electricidad</option>
                                                        <option value="GAS">Gas</option>
                                                        <option value="ELEGAS">Ambos</option>
                                                    </Input>
                                                </FormGroup>
                                            </Col>

                                            <Col lg="auto">
                                                <FormGroup>

                                                    <Label className="form-control-label" htmlFor="licencias">
                                                        Licencias
                                                    </Label>
                                                    <Input
                                                        id="licencias"
                                                        placeholder=""
                                                        name="licencias"
                                                        maxLength={3}
                                                        onChange={this.onChangeFieldUsuario}
                                                        value={formEmpresa.licencias}
                                                        type="number"
                                                        disabled={this.state.inputDisabled}

                                                    />
                                                </FormGroup>
                                            </Col>




                                        </Row>

                                    </CardBody>
                                </Card>
                                {this.state.alert}

                            </div>
                        </Row>
                    </Container>

                </>
            );
        } else {
            return (
                <>
                    <Header title={"No tienes permisos para acceder a este apartado. Consulte con nuestro equipo comercial"} />
                </>
            );
        }
    }
}

export default withApollo(crearEmpresas);
