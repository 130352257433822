import Environment from '../../constants/environment';
import EmpresaClienteFreeModal from "./empresaClienteModal";
import React, { forwardRef } from "react";
import Reflux from "reflux";
import axios from 'axios';
import client from "../../api/client";
import { validateSpanishId, spainIdType} from 'spain-id';
import {
    Card, Container, Row, CardTitle, CardBody, Label,
    FormGroup,
    Input, FormText,
    Form, Col, Button,
    UncontrolledTooltip, CardHeader, Collapse
} from "reactstrap";
import {
    Link,
    useRouteMatch
} from 'react-router-dom';
import { CSVLink, CSVDownload } from "react-csv";

// core components
import Header from "../../components/Headers/Header";

import { withApollo } from "react-apollo";
import moment from "moment";
import 'moment/locale/es'

import ReactBSAlert from "react-bootstrap-sweetalert";

import GetUserData from "../../components/User/GetUserData";
import { UserStore } from "../../store/UserStore";

import DataTable from 'react-data-table-component';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';

import CircularProgress from '@material-ui/core/CircularProgress';
import { saveAs } from 'file-saver';
import SipsOption from "../../components/Sips/SipsOption";
import SipsGasOption from "../../components/SipsGas/SipsGasOption";
import { LIST_DICMUNICIPIOS } from "../../queries/options/dic-option.graphql";

const ibantools = require('ibantools');

var DatePicker = require("reactstrap-date-picker");

const FileDownload = require('js-file-download');
const formato = require('xml-formatter');
const spanishDayLabels = ['Dom', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sab'];
const spanishMonthLabels = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];


const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.toLowerCase().charAt(0).toUpperCase() + s.slice(1)
}

const sortIcon = <ArrowDownward />;

const columnsDatosClientes = [

    { selector: row => row.id, name: 'ID USUARIO', sortable: true, width: '120px', center: true},
    { selector: row => row.company, name: 'EMPRESA', sortable: true, width: '170px', center: true},
    { selector: row => row.first_name, name: 'NOMBRE', sortable: true, width: '140px', center: true, format: (row => capitalize(row.first_name)) },
    { selector: row => row.last_name, name: 'APELLIDO', sortable: true, width: '140px', center: true, format: (row => capitalize(row.last_name)) },
    { selector: row => row.email, name: 'CORREO', sortable: true, width: '140px', center: true },
    { selector: row => row.user_group_id, name: 'GRUPO', sortable: true, width: '140px', center: true },
    { selector: row => row.query_limit, name: 'LIMITE', sortable: true, width: '140px', center: true},
    { selector: row => row.indexada, name: 'INDEX', sortable: true, width: '100px', center: true },
    { selector: row => row.mercadolibre, name: 'LIBRE', sortable: true, width: '100px', center: true},
    { selector: row => row.comisiones, name: 'COMISION', sortable: true, width: '100px', center: true },
    { selector: row => row.comercial, name: 'COMERCIAL', sortable: true, width: '100px', center: true},
    { selector: row => row.comercializadora, name: 'COMERCIALIZADORA', sortable: true, width: '100px', center: true },
    { selector: row => row.agencia, name: 'AGENCIA', sortable: true, width: '100px', center: true},
    { selector: row => row.adFincas, name: 'adFINCAS', sortable: true, width: '100px', center: true},

];



const customStyles = {
    headRow: {
        style: {
            border: 'none',

        },
    },
    headCells: {
        style: {
            color: '#202124',
            fontSize: '12px',
            fontWeight: 'bold',
            wordBreak: 'break-word',
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px'

        },
    },
    cells: {
        style: {
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px',
            wordBreak: 'break-word',
            fontFamily: '"Open Sans", sans-serif;'
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 250, 250)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
            fontSize: '12px',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};


const Circular = () => (
    // we need to add some padding to circular progress to keep it from activating our scrollbar
    <div style={{ padding: '24px' }}>
        <CircularProgress size={75} />
    </div>
);





class crearCliente extends Reflux.Component<any, any> {
    public maxFileSize = 3;
    public fileInput: any = null;
    public lecturasRef: any = [];
    public inputEl: any = null;
    public formRef: any = null;
    public validFileExtensions = ["jpg", "jpeg", "png"];
    lastFilters = {};
    state = {
        filters: {
            apellido1ClienteSearch: "",
            cifSearch: "",
            tipoEstadoSearch: "",
            clienteDesde: ""
        },
        UserStore: {},
        nombreFichero: "",
        isLoading: false,
        copiedText: null,
        currentPage: 0,
        pageSize: 0,
        pageCount: 0,
        count: 0,
        contrato: "",
        tipocliente: "",
        clienteDetailTotal: [],
        clienteSeleccionado: false,
        idCliente: '',
        clienteDetail: {
            id: 0,
            first_name: "",
            last_name: "",
            email: "",
            password: "",
            created_at: "",
            modified_at: "",
            user_group_id: 0,
            query_limit: 0,
            profile_photo: "",
            profile_photo_dir: "",
            app_logo: "",
            app_logo_dir: "",
            company: "",
            company_info: "",
            locked_up: "",
            attempts_login: "",
            reset_token: "",
            reset_token_expire: "",
            companyId: "",
            newCompanyId: "",
            indexada: false,
            mercadolibre: false,
            gas: false,
            electricidad: false,
            empresa: false,
            informes: false,
            mercadolibregas: false,
            contratos : false,
            aprobarContratos: false,
            verContratos: false,
            productos: false,
            comisiones: false,
            comercial: false,
            comercializadora: false,
            agencia: false,
            adFincas: false,
            jefeEquipo: false,
            idCard: "",
            idCardEmpresa:"",
            identificacionUsuario:"",
            identificacionEmpresa:"",
            esActivo: false

        },
        formCliente: {
            id: 0,
            first_name: "",
            last_name: "",
            email: "",
            password: "",
            created_at: "",
            modified_at: "",
            user_group_id: 0,
            query_limit: 0,
            profile_photo: "",
            profile_photo_dir: "",
            app_logo: "",
            app_logo_dir: "",
            company: "",
            company_info: "",
            locked_up: "",
            attempts_login: "",
            reset_token: "",
            reset_token_expire: "",
            companyId: "",
            newCompanyId: "",
            indexada: false,
            mercadolibre: false,
            gas: false,
            electricidad: false,
            empresa: false,
            informes: false,
            mercadolibregas: false,
            contratos : false,
            aprobarContratos: false,
            verContratos: false,
            productos: false,
            comisiones: false,
            comercial: false,
            comercializadora: false,
            agencia: false,
            adFincas: false,
            jefeEquipo: false,
            idCard: "",
            idCardEmpresa:"",
            identificacionUsuario:"",
            identificacionEmpresa:"",
            esActivo: false
        },

        companyIdUser: '',
        companyNameGlobal: '',
        userGroupId: 1000,
        userId: '',
        esAgencia: false,
        esComercializadora: false,
        esAdFincas: false,
        saveDataCliente: false,
        updateDataCliente: false,
        saveClienteButtonDisabled: true,
        updateClienteButtonDisabled: true,
        saveXMLButtonDisabled: true,
        buscarButtonDisabled: true,
        NIFClienteError: false,
        inputDisabled: false,
        show: false,
        imgCollection: [],
        saveButtonDisabled: true,
        showFilters: true,
        toggledClearRows: false,
        cupsError: false,
        cifError: false,
        locale: 'es',
        inputNombreProductoDisabled: false,
        inputCifDisabled: false,
        inputIdDisabled: false,
        clienteDesde: '',
        clienteHasta: '',
        created_at: '',
        modified_at: '',
        periodoValidezHasta: '',
        periodoValidezDesde: '',
        CodigoPostalTitularError: false,
        apellido1TitularError:  false,
        emailError: false,
        first_nameError: false,
        NIFError:false,
        DireccionFiscalError:false,
        TelefonoFijoError: false,
        TelefonMovilError: false,
        dicMunicipios: [],
        cnaeAux:"",
        alert: null,
        createResult: [],
createResult2: [],
        openModalComparative:false,
        empresaSeleccionada:[]

    };

    sipsCSVData: any = [];
    viewSipsConsumoAnualCSVData: any = [];
    sipsConsumosCSVData: any = [];
    sipstotal: any = [];





    constructor(props) {
        super(props);
        this.store = UserStore;
        this.formRef = React.createRef();

        this.showModal = this.showModal.bind(this);

        this.toggle = this.toggle.bind(this);

    }


    componentWillReceiveProps() {

        const usuario: any = JSON.parse(JSON.stringify(this.state.UserStore['data']))

        console.log('VALORES DE USUARIO EN componentWillReceiveProps')
        console.log(usuario)

        const companyNameGlobal = usuario.company;
        const companyIdUser = usuario.companyId;

        const userGroupId = usuario.user_group_id
        const userId = usuario.id
        const esComercializadora = usuario.comercializadora
        const esAgencia = usuario.agencia
        const esAdFincas = usuario.adFincas



        if (userGroupId === 1000) {
            this.setState({
                activeUser: true
            })
        };

        this.setState({
            companyNameGlobal,
            companyIdUser,
            userGroupId,
            userId,
            esComercializadora,
            esAgencia,
            esAdFincas

        });
    }



    componentDidMount() {
      this.fetchItems();
    }

    //////////////////////////////////////////////////////////////////////////////////////7

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    warningAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Error en la base de datos"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Existe un error en la Base de datos.
     </ReactBSAlert>
            )
        });
    };

    warningAlertNIF = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="No existen registros que cumplan los requisitos"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Revise los requisitos introducidos
     </ReactBSAlert>
            )
        });
    };

    warningAlertFiltros = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Existen campos obligatorios sin información"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Revise los datos introducidos
     </ReactBSAlert>
            )
        });
    };

    confirmAlertSave = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="¿Desea crear el cliente?"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.onSave()}
                    showCancel
                    confirmBtnBsStyle="secondary"
                    confirmBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    cancelBtnText="Guardar"
                    btnSize=""
                >
                    No se podrá volver atrás!
            </ReactBSAlert>
            )
        });
    };

    confirmAlertUpdate = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="¿Desea actualizar los datos del cliente?"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.onSave()}
                    showCancel
                    confirmBtnBsStyle="secondary"
                    confirmBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    cancelBtnText="Actualizar"
                    btnSize=""
                >
                    No se podrá volver atrás!
            </ReactBSAlert>
            )
        });
    };

    successAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Correcto"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Cliente guardado correctamente
        </ReactBSAlert>
            )
        });
    };



    //////////////////////////////////////////////////////////////////////////////////////7


    fetchItems = async () => {

      await client.query({
        query: LIST_DICMUNICIPIOS,
        variables: {}
      })
        .then((result) => {
          let data = result.data.listDicMunicipios;
          this.setState({ dicMunicipios: data })
        });
    };

    ///////////////////////////////////////////////////////////////////////////////////////////


    onChangeFieldCliente = (event) => {
        event.persist()

        var formData = { ...this.state.formCliente };
        var target = event.target;
        var value =
            event.target.type === "checkbox"
                ? event.target.checked
                : event.target.value;
        const name = event.target.name;
        console.log('Valor de event name: ', event.target.name)
        console.log('Valoe de event value: ', event.target.value)

        const nameFilters = ['first_name','last_name','email','password','created_at','user_group_id','query_limit','company','companyId','indexada','mercadolibre','gas','electricidad','idCard','idCardEmpresa','identificacionUsuario','identificacionEmpresa','esActivo'];

        if (nameFilters.indexOf(event.target.name) != -1) {
            this.setState({ saveDataCliente: true });
            this.setState({
                saveClienteButtonDisabled: false,
                updateClienteButtonDisabled: false
            })
        }
        if (event.target.name == 'first_name' && event.target.value.length > 0) {

            this.setState({ saveDataCliente: true });
            this.setState({
                saveClienteButtonDisabled: false,
                updateClienteButtonDisabled: false
            })
        }
        if (event.target.name == 'Apellido1Titular' && event.target.value.length > 0) {

            this.setState({ saveDataCliente: true });
            this.setState({
                saveClienteButtonDisabled: false,
                updateClienteButtonDisabled: false
            })
        }
        if (event.target.name == 'first_name' && event.target.value.length > 0) {
            //console.log('VALOR NOMBRE REPRESENTANTE ------> ', value, event.target.name)
            //this.setState ({representanteDetail.nombreRepresentante : event.target.value});
            this.setState({ saveDataCliente: true });
            this.setState({
                saveClienteButtonDisabled: true,
                updateClienteButtonDisabled: false
            })
        }

        if (event.target.name === 'NIF' && event.target.value.length == 0) {
            this.setState({ NIFClienteError: true })
        } else if (event.target.name === 'NIF' && event.target.value.length > 0) {
            const result = validateSpanishId(event.target.value)
            if (result === true) {
                this.setState({
                    saveDataCliente: true,
                    NIFClienteError: false
                });
                this.setState({
                    saveClienteButtonDisabled: true,
                    updateClienteButtonDisabled: false
                })
                const result = spainIdType(event.target.value)
                formData['descIdTipoTitular'] = result.toUpperCase();
                this.setState({ formCliente: formData });
            } else {
                this.setState({
                    NIFClienteError: true,
                    saveDataCliente: false,
                    updateClienteButtonDisabled: true
                })
            }
        }


        if (event.target.name === 'Email') {


            const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
            //const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
            const result = pattern.test(event.target.value);

            if (result === true) {
                this.setState({
                    emailError: false,
                })
            } else {
                this.setState({
                    emailError: true
                })
            }
        }


        if (name == 'MunicipioTitular') {

            const municipio: any = this.state.dicMunicipios
            const resultado = municipio.find(municipio => municipio ?.Nombre === value);

            console.log('VALOR DE FIND -------------------->', resultado.DescProvincia, resultado.DescAutonomia);

            //const filtered = municipio.filter(entry => Object.values(entry).some(val => typeof val === "string" && val.includes('Barromán')));

            formData['ProvinciaTitular'] = resultado.DescProvincia
            formData['AutonomiaTitular'] = resultado.DescAutonomia

        }


        if (name == 'nombreMunicipioPS') {
            const municipio: any = this.state.dicMunicipios
            const resultado = municipio.find(municipio => municipio ?.Nombre === value);
            const filtered = municipio.filter(entry => Object.values(entry).some(val => typeof val === "string" && val.includes('Barromán')));
            formData['nombreProvinciaPS'] = resultado.DescProvincia
            formData['AutonomiaTitular'] = resultado.DescAutonomia
        }


        if (event.target.name === 'esActivo' && event.target.value == 'Selecciona...') {
            value = null
            };



if (event.target.name === 'user_group_id' && event.target.value == 'Selecciona...') {
    value = "0"
    };


        formData[name] = value;
        //if (value === "" || value === null) {
        //    formData[name] = 0;
        //}
        this.setState({ formCliente: formData });

    };


    showFiltersToggle = () => {
        const showFilters = !this.state.showFilters;

        this.setState({ showFilters });
    };

    getFilterIcon = () => {
        return this.state.showFilters ? "ni-bold-up" : "ni-bold-down";
    };

    handleChangeDate = (value, formattedValue, id) => {

        console.log('VALORES DE FECHA DE ALATA')
        console.log(value)
        console.log(formattedValue)
        console.log(id)

        var formData = { ...this.state.formCliente };


        const name = id;
        var valueDay = formattedValue


        if (name == 'created_at') {
            //console.log('VALOR NOMBRE REPRESENTANTE ------> ', value, event.target.name)
            //this.setState ({clienteDetail.nombreOferta : event.target.value});
            this.setState({ created_at: value });

        }

        if (name == 'modified_at') {
            //this.setState ({clienteDetail.nombreOferta : event.target.value});
            this.setState({ modified_at: value });
        }


        console.log(formData)
        formData[name] = value;
        //if (value === "" || value === null) {
        //    formData[name] = 0;
        //}
        this.setState({ formCliente: formData });
    };

////////////////////////////////////////////////////////////////////////



////////////////////////////////////////////////////////////////////////

    clearForm = () => {
            const filters = {};
        this.setState({
            filters: {
                apellido1ClienteSearch: '',
                cifSearch: '',
                idSearch: ''
            },
            inputCifDisabled: false,
            inputNombreProductoDisabled: false,
            inputIdDisabled: false,
            inputDisabled: false,
            buscarButtonDisabled: true,
            clienteSeleccionado: false,
            clienteDetailTotal: [],
            formCliente: {
                id:'',
                idCliente:'',
                first_name:'',
                Apellido1Titular:'',
                Apellido2Titular:'',
                NIF:'',
                idTipoTitular:'',
                descIdTipoTitular:'',
                TipoViaTitular:'',
                ViaTitular:'',
                NumFincaTitular:'',
                PortalTitular:'',
                EscaleraTitular:'',
                PisoTitular:'',
                PuertaTitular:'',
                CodigoPostalTitular:'',
                AutonomiaTitular:'',
                ProvinciaTitular:'',
                MunicipioTitular:'',
                TelefonoFijo:'',
                TelefonMovil:'',
                Email:'',
                TipoCliente:'',
                ObservacionesCliente:'',
                GDPR:true,
                IBAN:'',
                idBanco:'',
                descBanco:'',
                CNAE:'',
                actividadCNAE:'',
                idCompany:0,
                idUser:'',
                created_at:'',
                modified_at:'',
                esActivo:true
            },
            toggledClearRows: !this.state.toggledClearRows,
            saveClienteButtonDisabled: true,
            updateClienteButtonDisabled: true,
            created_at: '',
            modified_at: '',
            saveDataCliente: false

        });

        this.formRef.current.reset();

    };

///////////////////////////////////////////////////////////////////////////////////

    showTable = () => {
        if (this.state.show === false) {
            this.setState({
                show: true
            });

            const reftp = document.getElementById("tablaLecturas")
            console.log('VALORES DE REFTP', reftp)
            reftp ?.scrollIntoView({ behavior: "smooth" })

} else {

            this.setState({
                show: false
            });
        }
    }



////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    updateHandler(props) {

        if (this.state.saveDataCliente && (this.state.formCliente.first_name && this.state.formCliente.identificacionUsuario)) {

            this.confirmAlertUpdate()

        } else {
            this.warningAlertFiltros()
        }
    };


onSave = async () => {
    console.log('ESTAMOS EN ONSAVE VALORE DE THIS.STATE-------->', this.state.saveDataCliente)

    //const fechaActivacion = this.state.fechaActivacion;
    const companyId = this.state.companyIdUser.toString();
    const userId = this.state.userId.toString();
    var self = this
    if (this.state.saveDataCliente == true) {

        console.log('ENTRA EN SAVE DATOS producto')
        console.log('DATOS DE THIS.STATE   --------> ', this.state)

        var modified_at = '';

        /*var created_at = this.state.formCliente.created_at;

        if (!created_at) {

            created_at = moment().format("YYYY-MM-DDTHH:mm:ss");
        }
*/

        var usuarioDataInsert = {}



        /*console.log('Valor de create_at: ', created_at)

        var newdate = new Date(this.state.formCliente.created_at);
        newdate.setHours(newdate.getHours() + 4);
        var isoDate = newdate.toISOString();

        console.log('Valor de isoDate: ', isoDate)

        var status = moment(this.state.formCliente.created_at).add(4, 'hours').format('YYYY-MM-DDThh:mm:ss:sssZ');

        console.log('Valor de status: ', status)

*/

        /*var newdate = created_at.split("-").reverse().join("-");
        console.log('Valor de newdate: ', newdate)
        const date = new Date(newdate);
        const timestampInMs = date.getTime();
        console.log('Valor de timestampInMs: ', timestampInMs)

        const unixTimestamp = Math.floor(Math.floor(date.getTime() / 1000)/1000);
        console.log('Valor de unixTimestamp: ', unixTimestamp);
        */


        usuarioDataInsert = {


            'first_name': this.state.formCliente.first_name.toUpperCase(),
            'last_name': this.state.formCliente.last_name,
            'email': this.state.formCliente.email,
            'password': this.state.formCliente.password,
            'created_at': this.state.formCliente.created_at,
            'modified_at': null,
            'user_group_id': this.state.formCliente.user_group_id,
            'query_limit': this.state.formCliente.query_limit,
            'profile_photo': this.state.formCliente.profile_photo,
            'profile_photo_dir': this.state.formCliente.profile_photo_dir,
            'app_logo': this.state.formCliente.app_logo,
            'app_logo_dir': this.state.formCliente.app_logo_dir,
            'company': this.state.formCliente.company,
            'company_info': this.state.formCliente.company_info,
            'locked_up': null,
            'attempts_login': 0,
            'reset_token': this.state.formCliente.reset_token,
            'reset_token_expire': null,
            'companyId': Number(this.state.formCliente.companyId),
            'newCompanyId': null,
            'indexada': this.state.formCliente.indexada,
            'mercadolibre': this.state.formCliente.mercadolibre,
            'gas': this.state.formCliente.gas,
            'electricidad': this.state.formCliente.electricidad,
            'empresa': this.state.formCliente.empresa,
            'informes': this.state.formCliente.informes,
            'mercadolibregas': this.state.formCliente.mercadolibregas,
            'contratos': this.state.formCliente.contratos,
            'aprobarContratos': this.state.formCliente.aprobarContratos,
            'verContratos': this.state.formCliente.verContratos,
            'productos': this.state.formCliente.productos,
            'comisiones': this.state.formCliente.comisiones,
            'comercial': this.state.formCliente.comercial,
            'comercializadora': this.state.formCliente.comercializadora,
            'agencia': this.state.formCliente.agencia,
            'adFincas': this.state.formCliente.adFincas,
            'jefeEquipo': this.state.formCliente.jefeEquipo,
            'idCard': this.state.formCliente.idCard,
            'idCardEmpresa': this.state.formCliente.idCardEmpresa,
            'identificacionUsuario': this.state.formCliente.identificacionUsuario,
            'identificacionEmpresa': this.state.formCliente.identificacionEmpresa,
            'esActivo': this.state.formCliente.esActivo


        };


        var resultados: any = [];
        var resultadoView: any = [];



        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };
        let direccionFile = "https://datapi.psgestion.es/usuario/crear";


        await axios.post(direccionFile, usuarioDataInsert, { headers })
            .then((res) => {
                console.log('Inserción correcta', res)
                console.log('Datos del registro: ', res.data)
                //resultados = res.data[0]
                //this.setState({ createResult : [...this.state.createResult, resultados] })
                this.successAlert();
                this.setState({
                    inputDisabled: false,
                    clienteSeleccionado: false,
                    clienteDetailTotal: [],
                    saveDataCliente: false,
                    saveClienteButtonDisabled: true,
                    updateClienteButtonDisabled: true,
                    created_at: '',
                    modified_at: '',
                    toggledClearRows: !this.state.toggledClearRows,
                    formCliente: {
                        id: 0,
                        idCliente: '',
                        first_name: '',
                        Apellido1Titular: '',
                        Apellido2Titular: '',
                        NIF: '',
                        idTipoTitular: '',
                        descIdTipoTitular: '',
                        TipoViaTitular: '',
                        ViaTitular: '',
                        NumFincaTitular: '',
                        PortalTitular: '',
                        EscaleraTitular: '',
                        PisoTitular: '',
                        PuertaTitular: '',
                        CodigoPostalTitular: '',
                        AutonomiaTitular: '',
                        ProvinciaTitular: '',
                        MunicipioTitular: '',
                        TelefonoFijo: '',
                        TelefonMovil: '',
                        Email: '',
                        TipoCliente: '',
                        ObservacionesCliente: '',
                        GDPR: true,
                        IBAN: '',
                        idBanco: '',
                        descBanco: '',
                        CNAE: '',
                        actividadCNAE: '',
                        idCompany: 0,
                        idUser: '',
                        created_at: '',
                        modified_at: '',
                        esActivo: true
                    }

                });

            }).catch((error) => {
                console.log('Failure')
                console.log('Error en el proceso: ', error)
                this.warningAlert();
            });

    }

};


    handleClearRows = () => {
        this.setState({ toggledClearRows: !this.state.toggledClearRows })
    }

    ///////////////////////////////////////////////////////////////////////////////////

    toggle() {
        this.setState({
            openModalComparative: !this.state.openModalComparative,
        });
    };

    ///////////////////////////////////////////////////////////////////////////////

    showModal() {
        this.setState({
            openModalComparative: true,
        });
    };

    ////////////////////////////////////////////////////////////////////////////

    openModalComparativa = async (event) => {
        this.showModal();
        return;

    };

        ///////////////////////////////////////////////


        empresaSeleccionada = async (event) => {


            var formData = { ...this.state.formCliente };

            formData['company'] = event.empresa.razonSocial;
            formData['companyId'] = event.empresa.id;
formData['idCardEmpresa'] = event.empresa.CIF;
formData['identificacionEmpresa'] = event.empresa.CIF;

            this.setState({ formCliente: formData ,
                empresaSeleccionada: event.empresa,
                saveDataRepre: true,
                saveProductButtonDisabled: false});


        };




    render() {

        const formCliente: any = this.state.formCliente;
        var createResult : any = this.state.createResult;

        console.log('VALOR DE THIS:STATE EN RENDER SIPDETAILSCONTRATOS ------> ', this.state)
console.log('valor de createResult --------------------->', this.state.createResult)
        const created_at = this.state.created_at
        const modified_at = this.state.modified_at

        const clienteDesde = this.state.clienteDesde
        const clienteHasta = this.state.clienteHasta

        //const listItems = createResult.map((d) => <li key="{d.idCliente}">{d.idCliente}</li>);
        //
        //
        //

    let freeModal = <></>;

        if (this.state.openModalComparative == true) {

            freeModal = (
                <EmpresaClienteFreeModal
                    isOpen={this.state.openModalComparative}
                    toggle={this.toggle}
                    cifEmpresa={""}
                    companyId={this.state.companyIdUser}
                    empresa={this.empresaSeleccionada}


                ></EmpresaClienteFreeModal>
            );
        }


if(this.state.userGroupId == 1000) {
        return (
            <>
                <Header title={'Alta Usuario Plataforma'} />
                <Container className=" mt--7" fluid>

                    <Card className="mb-4">

                            <CardBody>

                                    <Row>

                                        <Col className="text-right">
                                            <Button onClick={this.clearForm}>
                                                <i className={"ni ni-fat-remove"} /> Limpiar
                                    </Button>

                                        </Col>
                                    </Row>

                            </CardBody>

                    </Card>

                    <Row>
                        <div className="col">
                            <Card className="shadow">
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <Button color="primary" size="sm" type="button"
                                                className="btn-icon btn-sm btn-circle"
                                                onClick={this.props.history.goBack}
                                            >
                                                <span className="bi bi-box-arrow-left"> Volver</span>
                                            </Button>
                                        </Col>

                                        <Col className="col-auto d-flex">
                                            <Card className="p-3 bg-light">
                                        <FormGroup>

                                                <Button disabled={this.state.saveClienteButtonDisabled} id="saveRepreProptooltip" color="danger" size="m" type="button" className="btn-icon btn-sm btn-circle"
                                                    onClick={() => { this.updateHandler(this.state.saveDataCliente)  }}
                                                >
                                                    <span className="btn-inner--icon">
                                                        <i className="bi bi-person-plus-fill" style={{ fontSize: 25 }}></i>
                                                    </span>
                                                    <span className="btn-inner--text">Guardar</span>
                                                    <UncontrolledTooltip delay={0} placement="top" target="saveRepreProptooltip">
                                                        CREAR USUARIO
                                                    </UncontrolledTooltip>
                                                </Button>


                                        </FormGroup>
                                    </Card>
                                </Col>

                                    </Row>
                                    <div>
                                        <CardTitle>DATOS GENERALES USUARIO</CardTitle>
                                    </div>


                                                                        <Row>
                                                                            <Col md="2">
                                                                                <FormGroup>

                                                                                    <Label className="form-control-label" htmlFor="id">
                                                                                        ID
                                                                        </Label>
                                                                                    <Input
                                                                                        id="id"
                                                                                        placeholder=""
                                                                                        name="id"
                                                                                        maxLength={12}
                                                                                        onChange={this.onChangeFieldCliente}
                                                                                        value={formCliente.id}
                                                                                        type="text"
                                                                                        disabled={true}
                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>

                                                                            <Col>
                                                                                <FormGroup>

                                                                                    <Label className="form-control-label" htmlFor="first_name">
                                                                                        Nombre Usuario
                                                                                    </Label>
                                                                                    <Input
                                                                                        className="text-primary"
                                                                                        id="first_name"
                                                                                        placeholder=""
                                                                                        name="first_name"
                                                                                        maxLength={40}
                                                                                        onChange={this.onChangeFieldCliente}
                                                                                        value={formCliente.first_name || ''}
                                                                                        type="text"
                                                                                        disabled={this.state.inputDisabled}
                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>

                                                                            <Col>
                                                                                <FormGroup>

                                                                                    <Label className="form-control-label" htmlFor="last_name">
                                                                                        Primer Apellido
                                                                                    </Label>
                                                                                    <Input
                                                                                        id="last_name"
                                                                                        placeholder=""
                                                                                        name="last_name"
                                                                                        maxLength={50}
                                                                                        onChange={this.onChangeFieldCliente}
                                                                                        value={formCliente.last_name || ''}
                                                                                        type="text"
                                                                                        disabled={this.state.inputDisabled}
                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>

                                                                            <Col md="2">
                                                                                <FormGroup>
                                                                                    <Label className="form-control-label" htmlFor="idCard">
                                                                                        NIF Usuario
                                                                                    </Label>
                                                                                    <Input
                                                                                        id="idCard"
                                                                                        placeholder=""
                                                                                        name="idCard"
                                                                                        maxLength={16}
                                                                                        onChange={this.onChangeFieldCliente}
                                                                                        value={formCliente.idCard||""}
                                                                                        type="text"
                                                                                        disabled={this.state.inputDisabled}
                                                                                    />
                                                                                    {this.state.NIFClienteError ? <FormText color="red">Comprobar NIF/NIF</FormText> : ''}
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col md="2">
                                                                                <FormGroup>
                                                                                    <Label className="form-control-label" htmlFor="idCardEmpresa">
                                                                                        NIF Empresa
                                                                                    </Label>
                                                                                    <Input
                                                                                        id="idCardEmpresa"
                                                                                        placeholder=""
                                                                                        name="idCardEmpresa"
                                                                                        maxLength={16}
                                                                                        onChange={this.onChangeFieldCliente}
                                                                                        value={formCliente.idCardEmpresa||""}
                                                                                        type="text"
                                                                                        disabled={true}
                                                                                    />
                                                                                    {this.state.NIFClienteError ? <FormText color="red">Comprobar NIF/NIF</FormText> : ''}
                                                                                </FormGroup>
                                                                            </Col>

                                                                        </Row>
                                                                        <Row>
                                                                            <Col md="2">
                                                                                <FormGroup>

                                                                                    <Label className="form-control-label" htmlFor="email">
                                                                                        Email
                                                                        </Label>
                                                                                    <Input
                                                                                        id="email"
                                                                                        placeholder=""
                                                                                        name="email"
                                                                                        onChange={this.onChangeFieldCliente}
                                                                                        value={formCliente.email || ''}
                                                                                        type="text"
                                                                                        disabled={this.state.inputDisabled}
                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>

                                                                            <Col md="6">
                                                                                <FormGroup>

                                                                                    <Label className="form-control-label" htmlFor="password">
                                                                                        Clave
                                                                       </Label>
                                                                                    <Input
                                                                                        id="password"
                                                                                        placeholder=""
                                                                                        name="password"
                                                                                        maxLength={60}
                                                                                        onChange={this.onChangeFieldCliente}
                                                                                        value={formCliente.password}
                                                                                        type="text"
                                                                                        disabled={this.state.inputDisabled}

                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>


                                                                            <Col md="2">
                                                                                <FormGroup>

                                                                                    <Label className="form-control-label" htmlFor="query_limit">
                                                                                        Límite Consulta
                                                                        </Label>
                                                                                    <Input
                                                                                        id="query_limit"
                                                                                        placeholder=""
                                                                                        name="query_limit"
                                                                                        maxLength={7}
                                                                                        onChange={this.onChangeFieldCliente}
                                                                                        value={formCliente.query_limit}
                                                                                        type="text"
                                                                                        disabled={this.state.inputDisabled}

                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>


                                                                            <Col md="2" lg="2">
                                                                                <FormGroup>
                                                                                    <Label className="form-control-label" for="user_group_id">Grupo Usuario</Label>
                                                                                    <Input type="select" name="user_group_id" id="user_group_id"
                                                                                        onChange={this.onChangeFieldCliente}
                                                                                        value={formCliente.user_group_id}
                                                                                        disabled={this.state.inputDisabled}>
                                                                                        <option selected>Selecciona...</option>
                                                                                        <option value={1000}>SUPER ADMINISTRADOR (DYN)</option>
                                                                                        <option value={500}>ADMINISTRADOR</option>
                                                                                        <option value={450}>GERENTE</option>
                                                                                        <option value={400}>JEFE DE EQUIPO</option>
                                                                                        <option value={300}>COMERCIAL NIVEL 1</option>
                                                                                        <option value={200}>COMERCIAL NIVEL 2</option>
                                                                                        <option value={100}>COMERCIAL NIVEL 3</option>
                                                                                        <option value={0}>SIN GRUPO</option>

                                                                                    </Input>
                                                                                </FormGroup>
                                                                            </Col>

                                                                            <Col md="3">
                                                                                <FormGroup>
                                                                                    <Label className="form-control-label" htmlFor="company">
                                                                                        Empresa
                                                                        </Label>
                                                                                    <Input
                                                                                        id="company"
                                                                                        placeholder=""
                                                                                        name="company"
                                                                                        onChange={this.onChangeFieldCliente}
                                                                                        value={formCliente.company}
                                                                                        type="text"
                                                                                        disabled={true}

                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col md="2">
                                                                                <FormGroup>
                                                                                    <Label className="form-control-label" htmlFor="companyId">
                                                                                        ID Emp.
                                                                        </Label>
                                                                                    <Input
                                                                                        id="companyId"
                                                                                        placeholder=""
                                                                                        name="companyId"
                                                                                        onChange={this.onChangeFieldCliente}
                                                                                        value={formCliente.companyId}
                                                                                        type="text"
                                                                                        disabled={true}

                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>

                                                                            <Col md="1" sm="1" lg="1" className="align-self-center">

                                                                                                                            <Button
                                                                                                                                id="UpdateEmpresaProptooltip"
                                                                                                                                className="mx-auto align-self-center mt-2"
                                                                                                                                position="center"
                                                                                                                                margin
                                                                                                                                onClick={this.openModalComparativa}
                                                                                                                                color="primary"
                                                                                                                                type="button"
                                                                                                                                disabled={this.state.inputDisabled}
                                                                                                                            >
                                                                                                                                <i className={"bi bi-person-plus"} style={{fontSize: 15}}/>
                                                                                                                            <UncontrolledTooltip delay={0} placement="top" target="UpdateEmpresaProptooltip">
                                                                                                                                    AÑADIR EMPRESA AL USUARIO DEL CONTRATO
                                                                                                                            </UncontrolledTooltip>
                                                                                                                            </Button>

                                                                                                                        </Col>


                                                                            <Col md="2">
                                                                                <FormGroup>
                                                                                    <Label className="form-control-label" htmlFor="profile_photo">
                                                                                        Imagen Perfil
                                                                        </Label>
                                                                                    <Input
                                                                                        id="profile_photo"
                                                                                        placeholder=""
                                                                                        name="profile_photo"
                                                                                        onChange={this.onChangeFieldCliente}
                                                                                        value={formCliente.profile_photo}
                                                                                        type="text"
                                                                                        disabled={this.state.inputDisabled}

                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>

                                                                            <Col md="3">
                                                                                <FormGroup>
                                                                                    <Label className="form-control-label" htmlFor="profile_photo_dir">
                                                                                        Imagen Perfil Dir
                                                                       </Label>
                                                                                    <Input
                                                                                        id="profile_photo_dir"
                                                                                        placeholder=""
                                                                                        name="profile_photo_dir"
                                                                                        onChange={this.onChangeFieldCliente}
                                                                                        value={formCliente.profile_photo_dir}
                                                                                        type="text"
                                                                                        disabled={this.state.inputDisabled}

                                                                                    />
                                                                                    {this.state.CodigoPostalTitularError ? <FormText color="red">Por favor compruebe el código postal del titular</FormText> : ''}
                                                                                </FormGroup>
                                                                            </Col>

                                                                            <Col md="3">
                                                                                <FormGroup>
                                                                                    <Label className="form-control-label" htmlFor="app_logo">
                                                                                        Imagen Logo
                                                                        </Label>
                                                                                    <Input
                                                                                        id="app_logo"
                                                                                        placeholder=""
                                                                                        name="app_logo"
                                                                                        onChange={this.onChangeFieldCliente}
                                                                                        value={formCliente.app_logo}
                                                                                        type="text"
                                                                                        disabled={this.state.inputDisabled}

                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>

                                                                            <Col md="3">
                                                                                <FormGroup>
                                                                                    <Label className="form-control-label" htmlFor="app_logo_dir">
                                                                                        Imagen Logo Dir
                                                                       </Label>
                                                                                    <Input
                                                                                        id="app_logo_dir"
                                                                                        placeholder=""
                                                                                        name="app_logo_dir"
                                                                                        onChange={this.onChangeFieldCliente}
                                                                                        value={formCliente.app_logo_dir}
                                                                                        type="text"
                                                                                        disabled={this.state.inputDisabled}

                                                                                    />
                                                                                    {this.state.CodigoPostalTitularError ? <FormText color="red">Por favor compruebe el código postal del titular</FormText> : ''}
                                                                                </FormGroup>
                                                                            </Col>

                                                                            <Col md="2">
                                                                                <FormGroup>
                                                                                    <Label className="form-control-label" htmlFor="company_info">
                                                                                        Info Empresa
                                                                        </Label>
                                                                                    <Input
                                                                                        id="company_info"
                                                                                        placeholder=""
                                                                                        name="company_info"
                                                                                        maxLength={40}
                                                                                        onChange={this.onChangeFieldCliente}
                                                                                        value={formCliente.company_info}
                                                                                        type="text"
                                                                                        disabled={this.state.inputDisabled}

                                                                                    />
                                                                                </FormGroup>
                                                                            </Col>

                                                                            <Col md="2">
                                                                                <FormGroup>

                                                                                    <Label className="form-control-label" htmlFor="created_at">
                                                                                        Alta
                                                                                    </Label>

                                                                                    <DatePicker id="created_at"
                                                                                        value={created_at}
                                                                                        onChange={(v, f) => this.handleChangeDate(v, f, "created_at")}
                                                                                        instanceCount={1}
                                                                                        dateFormat={"DD-MM-YYYY"}
                                                                                        dayLabels={spanishDayLabels}
                                                                                        monthLabels={spanishMonthLabels}
                                                                                    />
                                                                                </FormGroup>

                                                                            </Col>

                                                                            <Col md="2">
                                                                                <FormGroup>

                                                                                    <Label className="form-control-label" htmlFor="modified_at">
                                                                                        Modificación
                                                                                    </Label>

                                                                                    <DatePicker id="modified_at"
                                                                                        value={modified_at}
                                                                                        instanceCount={1}
                                                                                        dateFormat={"DD-MM-YYYY"}
                                                                                        dayLabels={spanishDayLabels}
                                                                                        monthLabels={spanishMonthLabels}
                                                                                        disabled ={true}
                                                                                    />
                                                                                </FormGroup>

                                                                            </Col>

                                                                        </Row>
                                    <Row>
                                        <Col md="2">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="identificacionUsuario">
                                                    NIF Usuario
                                                </Label>
                                                <Input
                                                    id="identificacionUsuario"
                                                    placeholder=""
                                                    name="identificacionUsuario"
                                                    maxLength={16}
                                                    onChange={this.onChangeFieldCliente}
                                                    value={formCliente.identificacionUsuario||""}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                                {this.state.NIFClienteError ? <FormText color="red">Comprobar NIF/NIF</FormText> : ''}
                                            </FormGroup>
                                        </Col>
                                        <Col md="2">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="identificacionEmpresa">
                                                    NIF Empresa
                                                </Label>
                                                <Input
                                                    id="identificacionEmpresa"
                                                    placeholder=""
                                                    name="identificacionEmpresa"
                                                    maxLength={16}
                                                    onChange={this.onChangeFieldCliente}
                                                    value={formCliente.identificacionEmpresa||""}
                                                    type="text"
                                                    disabled={true}
                                                />
                                                {this.state.NIFClienteError ? <FormText color="red">Comprobar NIF/NIF</FormText> : ''}
                                            </FormGroup>
                                        </Col>
                                        <Col md="2">
                                            <Label className="form-control-label" htmlFor="identificacionEmpresa">
                                                USUARIO
                                            </Label>
                                            <FormGroup className="custom-control custom-checkbox">

                                                <Input
                                                    className="custom-control-input"
                                                    id="esActivo"
                                                    name="esActivo"
                                                    onChange={this.onChangeFieldCliente}
                                                    checked={formCliente.esActivo}
                                                    type="checkbox"
                                                    disabled={this.state.inputDisabled}

                                                />
                                                <label className="custom-control-label" htmlFor="esActivo">
                                                            Usuario Activo S/N
                                                          </label>
                                            </FormGroup>
                                        </Col>
                                    </Row>


                                                                    </CardBody>
                                                                </Card>

                                                                <Card className="shadow">
                                                                    <CardBody>
                                                                        <div>
                                                                            <CardTitle>OTROS DATOS</CardTitle>
                                                                        </div>

                                                                        <Row>

                                                                            <Col md="2">
                                                                                <FormGroup className="custom-control custom-checkbox">

                                                                                    <Input
                                                                                        className="custom-control-input"
                                                                                        id="indexada"
                                                                                        name="indexada"
                                                                                        onChange={this.onChangeFieldCliente}
                                                                                        checked={formCliente.indexada}
                                                                                        type="checkbox"
                                                                                        disabled={this.state.inputDisabled}

                                                                                    />
                                                                                    <label className="custom-control-label" htmlFor="indexada">
                                                                                                Indexada
                                                                                              </label>
                                                                                </FormGroup>
                                                                            </Col>

                                                                            <Col md="2">
                                                                                <FormGroup  className="custom-control custom-checkbox">

                                                                                    <Input
                                                                                        className="custom-control-input"
                                                                                        id="mercadolibre"
                                                                                        name="mercadolibre"
                                                                                        onChange={this.onChangeFieldCliente}
                                                                                        checked={formCliente.mercadolibre}
                                                                                        type="checkbox"
                                                                                        disabled={this.state.inputDisabled}

                                                                                    />
                                                                                    <label className="custom-control-label" htmlFor="mercadolibre">
                                                                                                Libre
                                                                                              </label>
                                                                                </FormGroup>
                                                                            </Col>


                                                                            <Col md="2">
                                                                                <FormGroup className="custom-control custom-checkbox">

                                                                                    <Input
                                                                                        className="custom-control-input"
                                                                                        id="electricidad"
                                                                                        name="electricidad"
                                                                                        onChange={this.onChangeFieldCliente}
                                                                                        checked={formCliente.electricidad}
                                                                                        type="checkbox"
                                                                                        disabled={this.state.inputDisabled}

                                                                                    />
                                                                                    <label className="custom-control-label" htmlFor="electricidad">
                                                                                                Electricidad
                                                                                              </label>
                                                                                </FormGroup>
                                                                            </Col>

                                                                            <Col md="2">
                                                                                <FormGroup className="custom-control custom-checkbox">

                                                                                    <Input
                                                                                        className="custom-control-input"
                                                                                        id="gas"
                                                                                        name="gas"
                                                                                        onChange={this.onChangeFieldCliente}
                                                                                        checked={formCliente.gas}
                                                                                        type="checkbox"
                                                                                        disabled={this.state.inputDisabled}

                                                                                    />
                                                                                    <label className="custom-control-label" htmlFor="gas">
                                                                                                Gas
                                                                                              </label>
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col md="2">
                                                                                <FormGroup className="custom-control custom-checkbox">

                                                                                    <Input
                                                                                        className="custom-control-input"
                                                                                        id="empresa"
                                                                                        name="empresa"
                                                                                        onChange={this.onChangeFieldCliente}
                                                                                        checked={formCliente.empresa}
                                                                                        type="checkbox"
                                                                                        disabled={this.state.inputDisabled}

                                                                                    />
                                                                                    <label className="custom-control-label" htmlFor="empresa">
                                                                                                Empresa
                                                                                              </label>
                                                                                </FormGroup>
                                                                            </Col>

                                                                            <Col md="2">
                                                                                <FormGroup className="custom-control custom-checkbox">

                                                                                    <Input
                                                                                        className="custom-control-input"
                                                                                        id="informes"
                                                                                        name="informes"
                                                                                        onChange={this.onChangeFieldCliente}
                                                                                        checked={formCliente.informes}
                                                                                        type="checkbox"
                                                                                        disabled={this.state.inputDisabled}

                                                                                    />
                                                                                    <label className="custom-control-label" htmlFor="informes">
                                                                                                Informes
                                                                                              </label>
                                                                                </FormGroup>
                                                                            </Col>


                                                                            <Col md="2">
                                                                                <FormGroup className="custom-control custom-checkbox">

                                                                                    <Input
                                                                                        className="custom-control-input"
                                                                                        id="mercadolibregas"
                                                                                        name="mercadolibregas"
                                                                                        onChange={this.onChangeFieldCliente}
                                                                                        checked={formCliente.mercadolibregas}
                                                                                        type="checkbox"
                                                                                        disabled={this.state.inputDisabled}

                                                                                    />
                                                                                    <label className="custom-control-label" htmlFor="mercadolibregas">
                                                                                                Libre Gas
                                                                                              </label>
                                                                                </FormGroup>
                                                                            </Col>

                                                                            <Col md="2">
                                                                                <FormGroup className="custom-control custom-checkbox">

                                                                                    <Input
                                                                                        className="custom-control-input"
                                                                                        id="contratos"
                                                                                        name="contratos"
                                                                                        onChange={this.onChangeFieldCliente}
                                                                                        checked={formCliente.contratos}
                                                                                        type="checkbox"
                                                                                        disabled={this.state.inputDisabled}

                                                                                    />
                                                                                    <label className="custom-control-label" htmlFor="contratos">
                                                                                                Contratos
                                                                                              </label>
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col md="2">
                                                                                <FormGroup className="custom-control custom-checkbox">

                                                                                    <Input
                                                                                        className="custom-control-input"
                                                                                        id="aprobarContratos"
                                                                                        name="aprobarContratos"
                                                                                        onChange={this.onChangeFieldCliente}
                                                                                        checked={formCliente.aprobarContratos}
                                                                                        type="checkbox"
                                                                                        disabled={this.state.inputDisabled}

                                                                                    />
                                                                                    <label className="custom-control-label" htmlFor="aprobarContratos">
                                                                                                Aprobar Contratos
                                                                                              </label>
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col md="2">
                                                                                <FormGroup className="custom-control custom-checkbox">

                                                                                    <Input
                                                                                        className="custom-control-input"
                                                                                        id="verContratos"
                                                                                        name="verContratos"
                                                                                        onChange={this.onChangeFieldCliente}
                                                                                        checked={formCliente.verContratos}
                                                                                        type="checkbox"
                                                                                        disabled={this.state.inputDisabled}

                                                                                    />
                                                                                    <label className="custom-control-label" htmlFor="verContratos">
                                                                                                Ver Contratos
                                                                                              </label>
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col md="2">
                                                                                <FormGroup className="custom-control custom-checkbox">

                                                                                    <Input
                                                                                        className="custom-control-input"
                                                                                        id="productos"
                                                                                        name="productos"
                                                                                        onChange={this.onChangeFieldCliente}
                                                                                        checked={formCliente.productos}
                                                                                        type="checkbox"
                                                                                        disabled={this.state.inputDisabled}

                                                                                    />
                                                                                    <label className="custom-control-label" htmlFor="productos">
                                                                                                Productos
                                                                                              </label>
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col md="2">
                                                                                <FormGroup className="custom-control custom-checkbox">

                                                                                    <Input
                                                                                        className="custom-control-input"
                                                                                        id="comisiones"
                                                                                        name="comisiones"
                                                                                        onChange={this.onChangeFieldCliente}
                                                                                        checked={formCliente.comisiones}
                                                                                        type="checkbox"
                                                                                        disabled={this.state.inputDisabled}

                                                                                    />
                                                                                    <label className="custom-control-label" htmlFor="comisiones">
                                                                                                Comisiones
                                                                                              </label>
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col md="2">
                                                                                <FormGroup className="custom-control custom-checkbox">

                                                                                    <Input
                                                                                        className="custom-control-input"
                                                                                        id="comercial"
                                                                                        name="comercial"
                                                                                        onChange={this.onChangeFieldCliente}
                                                                                        checked={formCliente.comercial}
                                                                                        type="checkbox"
                                                                                        disabled={this.state.inputDisabled}

                                                                                    />
                                                                                    <label className="custom-control-label" htmlFor="comercial">
                                                                                                Comercial
                                                                                              </label>
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col md="2">
                                                                                <FormGroup className="custom-control custom-checkbox">

                                                                                    <Input
                                                                                        className="custom-control-input"
                                                                                        id="comercializadora"
                                                                                        name="comercializadora"
                                                                                        onChange={this.onChangeFieldCliente}
                                                                                        checked={formCliente.comercializadora}
                                                                                        type="checkbox"
                                                                                        disabled={this.state.inputDisabled}

                                                                                    />
                                                                                    <label className="custom-control-label" htmlFor="comercializadora">
                                                                                                Comercializador
                                                                                              </label>
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col md="2">
                                                                                <FormGroup className="custom-control custom-checkbox">

                                                                                    <Input
                                                                                        className="custom-control-input"
                                                                                        id="agencia"
                                                                                        name="agencia"
                                                                                        onChange={this.onChangeFieldCliente}
                                                                                        checked={formCliente.agencia}
                                                                                        type="checkbox"
                                                                                        disabled={this.state.inputDisabled}

                                                                                    />
                                                                                    <label className="custom-control-label" htmlFor="agencia">
                                                                                                Agencia
                                                                                              </label>
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col md="2">
                                                                                <FormGroup className="custom-control custom-checkbox">

                                                                                    <Input
                                                                                        className="custom-control-input"
                                                                                        id="adFincas"
                                                                                        name="adFincas"
                                                                                        onChange={this.onChangeFieldCliente}
                                                                                        checked={formCliente.adFincas}
                                                                                        type="checkbox"
                                                                                        disabled={this.state.inputDisabled}

                                                                                    />
                                                                                    <label className="custom-control-label" htmlFor="adFincas">
                                                                                                AD Fincas
                                                                                              </label>
                                                                                </FormGroup>
                                                                            </Col>
                                                                            <Col md="2">
                                                                                <FormGroup className="custom-control custom-checkbox">

                                                                                    <Input
                                                                                        className="custom-control-input"
                                                                                        id="jefeEquipo"
                                                                                        name="jefeEquipo"
                                                                                        onChange={this.onChangeFieldCliente}
                                                                                        checked={formCliente.jefeEquipo}
                                                                                        type="checkbox"
                                                                                        disabled={this.state.inputDisabled}

                                                                                    />
                                                                                    <label className="custom-control-label" htmlFor="jefeEquipo">
                                                                                                Jefe Equipo
                                                                                              </label>
                                                                                </FormGroup>
                                                                            </Col>







                                                                        </Row>



                                </CardBody>
                            </Card>
    {this.state.alert}

                        </div>
                    </Row>
                </Container>

    {freeModal}
            </>
        );
} else {
  return (
    <>
      <Header title={"No tienes permisos para acceder a este apartado. Consulte con nuestro equipo comercial"}/>
    </>
  );
}
}
}

export default withApollo(crearCliente);
