import Index from "./views/Index";
import Login from "./views/Auth/Login";
import Profile from "./views/users/Profile";
import Recover from "./views/Auth/Recover";
import Reset from "./views/Auth/Reset";

import { HomeFilled } from "@ant-design/icons";

import crearCliente from "./views/admin/crearCliente";
import cerrarSesionesCliente from "./views/admin/cerrarSesionesCliente";
import actualizarCliente from "./views/admin/actualizarDatosCliente";

import crearClienteEmpresa from "./views/admin/crearEmpresaCliente";
import actualizarClienteEmpresa from "./views/admin/actualizarDatosEmpresa";

import ClienteConsulta from "./views/consultas/clienteConsulta";
import consultaEmpresa from "./views/consultas/empresaConsulta";

import actualizarEmpresaEnergia from "./views/adminBackend/empresasEnergia";
import alianzaEmpresaEnergia from "./views/adminBackend/alianzaEmpresasEnergia";

import crarAlianzaEmpresaEnergia from "./views/adminBackend/crearAlianzaEmpresasEnergia";

import crearEmpresas from "./views/adminBackend/crearEmpresasEnergia";

import Comercial from "./views/adminBackend/comercial";
import ComercialBackend from "./views/adminBackend/comercialBackendAjustes";

var routes = [
  {
    path: "/index",
    name: "Inicio",
    icon: "bi bi-app-indicator",
    component: Index,
    layout: "/admin",
    key: "index",
  },
  {
    path: "/user-profile",
    name: "Perfil",
    icon: "pe-7s-graph1",
    component: Profile,
    invisible: true,
    layout: "/admin",
    key: "user-profile",
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25",
    component: Login,
    layout: "/auth",
    key: "login",
  },
  {
    path: "/recover",
    name: "Recover",
    icon: "ni ni-key-25",
    component: Recover,
    layout: "/auth",
    key: "recover",
  },
  {
    path: "/reset",
    name: "Reset",
    icon: "ni ni-key-25",
    component: Reset,
    layout: "/auth",
    key: "reset",
  },
  {
    collapse: true,
    name: "ADMINISTRACION",
    miniName: "AD",
    state: "multiCollapseConsulta",
    hasSubmenu: true,
    icon: "bi bi-search",
    layout: "/admin",
    key: "AdminSeleccion",
    views: [
      {
        path: "/nuevo-cliente/",
        name: "Nuevo Cliente",
        icon: "iconClassName",
        component: crearCliente,
        invisible: true,
        layout: "/admin",
        key: "crear-cliente",
      },
      {
        path: "/modificar-cliente/",
        name: "Modificar Cliente",
        icon: "iconClassName",
        component: actualizarCliente,
        invisible: true,
        layout: "/admin",
        key: "modificar-cliente",
      },
      {
        path: "/nueva-empresa/",
        name: "Nueva Empresa",
        icon: "iconClassName",
        component: crearClienteEmpresa,
        invisible: true,
        layout: "/admin",
        key: "crear-empresa",
      },
      {
        path: "/modificar-cliente-empresa/",
        name: "Modificar Empresa",
        icon: "iconClassName",
        component: actualizarClienteEmpresa,
        invisible: true,
        layout: "/admin",
        key: "empresa-modificar-cliente",
      },
      {
        path: "/sesiones-cliente/",
        name: "Cerrar Sesiones Cliente",
        icon: "iconClassName",
        component: cerrarSesionesCliente,
        invisible: true,
        layout: "/admin",
        key: "sesiones-cliente",
      },
    ],
  },
  {
    collapse: true,
    name: "ADMINISTRACION BACKEND",
    miniName: "AB",
    state: "multiCollapseAdminBackend",
    hasSubmenu: true,
    icon: "bi bi-search",
    layout: "/admin",
    key: "AdminBackendSeleccion",
    views: [
      {
        path: "/nuevoUsuario/",
        name: "Nuevo Usuario",
        icon: "iconClassName",
        component: Comercial,
        invisible: true,
        layout: "/admin",
        key: "comercial-crear",
      },
    /*  {
        path: "/generar/comercial/",
        name: "Nuevo 2 Cliente",
        icon: "iconClassName",
        component: ComercialBackend,
        invisible: true,
        layout: "/admin",
        key: "crear-comercial",
      },*/
      /*     {
          path: "/crear/empresa/",
          name: "Nueva Empresa",
          icon: "iconClassName",
          component: crearEmpresas,
          invisible: true,
          layout: "/admin",
          key: 'add-empresa'
        }, */
      {
        path: "/crearbackendempresa/",
        name: "Datos Empresa",
        icon: "iconClassName",
        component: actualizarEmpresaEnergia, 
        invisible: true,
        layout: "/admin",
        key: "suma-empresa",
      },
      {
        path: "/agrupacionempresas/",
        name: "Nueva Agrupación",
        icon: "iconClassName",
        component: alianzaEmpresaEnergia, 
        invisible: true,
        layout: "/admin",
        key: "alianzas-empresa",
      }
    ],
  },
  {
    path: "/nuevaEmpresa",
    name: "Crear Empresa",
    component: crearEmpresas,
    invisible: true,
    layout: "/admin",
    key: "add-empresa",
  },
  {
    path: "/nuevaAlianza",
    name: "Crear Alianza",
    component: crarAlianzaEmpresaEnergia,
    invisible: true,
    layout: "/admin",
    key: "alianzaCrearempresa",
  },
  {
    collapse: true,
    name: "CONSULTAS",
    miniName: "CD",
    state: "multiCollapseConsulta",
    hasSubmenu: true,
    icon: "bi bi-search",
    layout: "/admin",
    key: "ConsultaSeleccion",
    views: [
      {
        path: "/infocliente",
        name: "Clientes",
        icon: "bi bi-person-square",
        component: ClienteConsulta,
        layout: "/admin",
        key: "ClienteConsulta",
      },
      {
        path: "/infoempresa",
        name: "Empresas",
        icon: "bi bi-person-square",
        component: consultaEmpresa,
        layout: "/admin",
        key: "empresaConsulta",
      },
    ],
  },
];
export default routes;
