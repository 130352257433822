import Environment from '../../constants/environment';
import React, { forwardRef } from "react";
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import Reflux from "reflux";
import axios from 'axios';
import memoize from 'memoize-one';
import client from "../../api/client";
import { validateSpanishId, spainIdType } from 'spain-id';
import {
    Card, Container, Row, CardTitle, CardBody, Label,
    FormGroup,
    Input, FormText,
    Form, Col, Button,
    UncontrolledTooltip, CardHeader, Collapse
} from "reactstrap";
import {
    Link,
    useRouteMatch
} from 'react-router-dom';
import { CSVLink, CSVDownload } from "react-csv";

// core components
import Header from "../../components/Headers/Header";

import { withApollo } from "react-apollo";
import moment from "moment";
import 'moment/locale/es'

import ReactBSAlert from "react-bootstrap-sweetalert";

import GetUserData from "../../components/User/GetUserData";
import { UserStore } from "../../store/UserStore";
import DataTable from 'react-data-table-component';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';

import CircularProgress from '@material-ui/core/CircularProgress';
import { saveAs } from 'file-saver';
import SipsOption from "../../components/Sips/SipsOption";
import SipsGasOption from "../../components/SipsGas/SipsGasOption";
import { LIST_DICMUNICIPIOS } from "../../queries/options/dic-option.graphql";

//const ibantools = require('ibantools');
const ibantools = require('ibantools');

var DatePicker = require("reactstrap-date-picker");

const FileDownload = require('js-file-download');
const formato = require('xml-formatter');
const spanishDayLabels = ['Dom', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sab'];
const spanishMonthLabels = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

const sortIcon = <ArrowDownward />;

const columnsDatosClientes = [
    { selector: row => row.id, name: 'ID', sortable: true, width: '120px', center: true },
    { selector: row => row.razonSocial, name: 'EMPRESA', sortable: true, width: '170px', center: true },
    { selector: row => row.nombre, name: 'NOMBRE', sortable: true, width: '140px', center: true },
    { selector: row => row.apellido1, name: 'APELLIDO', sortable: true, width: '140px', center: true },
    { selector: row => row.correoContacto, name: 'CORREO', sortable: true, width: '140px', center: true },
    { selector: row => row.telefonoContacto, name: 'TELEFONO', sortable: true, width: '140px', center: true },
    { selector: row => row.formapago, name: 'F. PAGO', sortable: true, width: '140px', center: true },
    { selector: row => row.esActivo, name: 'ACTIVO', sortable: true, width: '100px', center: true },
    { selector: row => row.tipoEmpresa, name: 'TIPO', sortable: true, width: '100px', center: true },
    { selector: row => row.ambitoEnergia, name: 'AMBITO', sortable: true, width: '100px', center: true },
];


const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.toLowerCase().charAt(0).toUpperCase() + s.slice(1)
}

const customStyles = {
    headRow: {
        style: {
            border: 'none',

        },
    },
    headCells: {
        style: {
            color: '#202124',
            fontSize: '12px',
            fontWeight: 'bold',
            wordBreak: 'break-word',
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px'

        },
    },
    cells: {
        style: {
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px',
            wordBreak: 'break-word',
            fontFamily: '"Open Sans", sans-serif;'
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 250, 250)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
            fontSize: '12px',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};


const Circular = () => (
    // we need to add some padding to circular progress to keep it from activating our scrollbar
    <div style={{ padding: '24px' }}>
        <CircularProgress size={75} />
    </div>
);





class actualizarClienteEmpresa extends Reflux.Component<any, any> {
    public maxFileSize = 3;
    public fileInput: any = null;
    public lecturasRef: any = [];
    public inputEl: any = null;
    public formRef: any = null;
    public validFileExtensions = ["jpg", "jpeg", "png"];
    lastFilters = {};
    state = {
        filters: {
            apellido1ClienteSearch: "",
            NIFSearch: "",
            tipoEstadoSearch: "",
            clienteDesde: ""
        },
        UserStore: {},
        nombreFichero: "",
        isLoading: false,
        copiedText: null,
        currentPage: 0,
        pageSize: 0,
        pageCount: 0,
        count: 0,
        contrato: "",
        TipoCliente: "",
        clienteDetailTotal: [],
        clienteSeleccionado: false,
        idCliente: '',
        clienteDetail: {
            id: 0,
            razonSocial: "",
            CIF: "",
            codigoProvincia: "",
            desProvincia: "",
            codigoPostal: "",
            codigoMunicipio: "",
            tipoVia: "",
            via: "",
            numFinca: "",
            portal: "",
            escalera: "",
            piso: "",
            puerta: "",
            tipoEmpresa: "",
            ambitoEnergia: "",
            CNAE: "",
            actividadCNAE: "",
            codigoAutonomia: "",
            desAutonomia: "",
            correoContacto: "",
            responsableContacto: "",
            telefonoContacto: "",
            nombre: "",
            apellido1: "",
            apellido2: "",
            formapago: "",
            IBAN: "",
            tarjetacredito: "",
            esActivo: false,
            fechainiciosuscripcion: null,
            fechaalta: null,
            suscripcion: "",
            cuota: 0,
            cvc: '',
            expiry: '',
            focus: '',
            nombretarjetacredito: '',
        },
        formCliente: {
            id: 0,
            razonSocial: "",
            CIF: "",
            codigoProvincia: "",
            desProvincia: "",
            codigoPostal: "",
            codigoMunicipio: "",
            tipoVia: "",
            via: "",
            numFinca: "",
            portal: "",
            escalera: "",
            piso: "",
            puerta: "",
            tipoEmpresa: "",
            ambitoEnergia: "",
            CNAE: "",
            actividadCNAE: "",
            codigoAutonomia: "",
            desAutonomia: "",
            correoContacto: "",
            responsableContacto: "",
            telefonoContacto: "",
            nombre: "",
            apellido1: "",
            apellido2: "",
            formapago: "",
            IBAN: "",
            tarjetacredito: "",
            esActivo: false,
            fechainiciosuscripcion: "",
            fechaalta: "",
            suscripcion: "",
            cuota: 0,
            cvc: '',
            expiry: '',
            focus: '',
            nombretarjetacredito: '',
        },
        updatedPasswordCliente: false,
        companyIdUser: '',
        companyNameGlobal: '',
        userGroupId: 1000,
        userId: '',
        esAgencia: false,
        esComercializadora: false,
        esAdFincas: false,
        saveDataCliente: false,
        updateDataCliente: false,
        saveClienteButtonDisabled: true,
        updateClienteButtonDisabled: true,
        saveXMLButtonDisabled: true,
        buscarButtonDisabled: false,
        NIFClienteError: false,
        show: false,
        imgCollection: [],
        saveButtonDisabled: true,
        showFilters: true,
        toggledClearRows: false,
        cupsError: false,
        NIFError: false,
        locale: 'es',
        inputIdDisabled: true,
        inputDisabled: true,
        inputDisabledCard:true,
        clienteDesde: '',
        clienteHasta: '',
        fechaalta: '',
        fechainiciosuscripcion: '',
        periodoValidezHasta: '',
        periodoValidezDesde: '',
        CodigoPostalTitularError: false,
        Apellido1TitularError: false,
        emailError: false,
        first_nameError: false,
        DireccionFiscalError: false,
        TelefonoFijoError: false,
        TelefonMovilError: false,
        dicMunicipios: [],
        cnaeAux: "",
        IBANError: false,
        alert: null,
        codigoPostal: false

    };

    sipsCSVData: any = [];
    viewSipsConsumoAnualCSVData: any = [];
    sipsConsumosCSVData: any = [];
    sipstotal: any = [];





    constructor(props) {
        super(props);
        this.store = UserStore;
        this.formRef = React.createRef();

    }


    componentWillReceiveProps() {

        const usuario: any = JSON.parse(JSON.stringify(this.state.UserStore['data']))

        const companyNameGlobal = usuario.company;
        const companyIdUser = usuario.companyId;

        const userGroupId = usuario.user_group_id
        const userId = usuario.id
        const esComercializadora = usuario.comercializadora
        const esAgencia = usuario.agencia
        const esAdFincas = usuario.adFincas



        if (userGroupId === 1000) {
            this.setState({
                activeUser: true
            })
        };

        this.setState({
            companyNameGlobal,
            companyIdUser,
            userGroupId,
            userId,
            esComercializadora,
            esAgencia,
            esAdFincas

        });
    }



    componentDidMount() {
        this.fetchItems();
    };

    //////////////////////////////////////////////////////////////////////////////////////7

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    warningAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Error en la base de datos"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Existe un error en la Base de datos.
     </ReactBSAlert>
            )
        });
    };

    warningAlertNIF = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="No existen registros que cumplan los requisitos"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Revise los requisitos introducidos
     </ReactBSAlert>
            )
        });
    };

    warningAlertFiltros = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Existen campos obligatorios sin información"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Revise los datos introducidos
     </ReactBSAlert>
            )
        });
    };

    confirmAlertUpdate = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="¿Desea actualizar los datos del cliente?"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.onSave()}
                    showCancel
                    confirmBtnBsStyle="secondary"
                    confirmBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    cancelBtnText="Actualizar"
                    btnSize=""
                >
                    No se podrá volver atrás!
            </ReactBSAlert>
            )
        });
    };


    successAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Correcto"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Empresa actualizada correctamente
        </ReactBSAlert>
            )
        });
    };

    //////////////////////////////////////////////////////////////////////////////////////



    fetchItems = async () => {

        await client.query({
            query: LIST_DICMUNICIPIOS,
            variables: {}
        })
            .then((result) => {
                let data = result.data.listDicMunicipios;
                this.setState({ dicMunicipios: data })
            });
    };

    ///////////////////////////////////////////////////////////////////////////////////////////


    onChangeFieldSearch = (event) => {
        event.persist()
        var filtersData = { ...this.state.filters };
        const name = event.target.name;
        let value = event.target.value;
        const filters = this.state.filters;


        var filtersData = { ...this.state.filters };
        filters[name] = value;
        /*if (event.target.type === "select-one" && value === "0") {
           delete filters[name];
         }

         if (value === "" || value === null) {
           delete filters[name];
         }
*/

        if (event.target.name === 'apellido1ClienteSearch' && event.target.value.length == 0) {
            this.setState({
                //cupsError: false,
                buscarButtonDisabled: false,
                //inputIdDisabled: false
            })
        } else if (event.target.name === 'apellido1ClienteSearch' && event.target.value.length > 0) {

            this.setState({
                //inputIdDisabled: true,
                buscarButtonDisabled: false

            })

        }


        if (event.target.name === 'NIFSearch' && event.target.value.length == 0) {

            this.setState({

                //NIFError: false,
                buscarButtonDisabled: false,
            })
        } else if (event.target.name === 'NIFSearch' && event.target.value.length > 0) {

            //const result = validateSpanishId(event.target.value)

            //if (result === true) {
            //    this.setState({
            //        NIFError: false,
            //        buscarButtonDisabled: false,
            //    })

            //} else {
            this.setState({
                //NIFError: true,
                buscarButtonDisabled: false,
            })
            //}
        }

        if (event.target.name === 'tipoEstadoSearch' && event.target.value == 'Selecciona...') {
            value = null

        }

        filtersData[name] = value;
        //if (value === "" || value === null) {
        //    formData[name] = 0;
        //}
        this.setState({ filters: filtersData });
    };

    ////////////////////////////////////////////////////////////////////////////////////////

    onChangeFieldCliente = (event) => {
        event.persist()

        var formData = { ...this.state.formCliente };
        var target = event.target;
        var value =
            event.target.type === "checkbox"
                ? event.target.checked
                : event.target.value;
        const name = event.target.name;


        const nameFilters = ['razonSocial', 'CIF', 'codigoProvincia', 'desProvincia', 'via', 'numFinca', 'portal', 'escalera', 'piso', 'puerta', 'tipoEmpresa', 'ambitoEnergia', 'CNAE', 'desAutonomia', 'correoContacto', 'responsableContacto', 'telefonoContacto', 'nombre', 'apellido1', 'apellido2', 'formapago', 'IBAN'];

        if (nameFilters.indexOf(event.target.name) != -1) {
            this.setState({ saveDataCliente: true });
            this.setState({
                saveClienteButtonDisabled: false,
                updateClienteButtonDisabled: false
            })
        }

        if (event.target.name == 'password' && event.target.value.length > 0) {

            this.setState({ saveDataCliente: true });
            this.setState({
                saveClienteButtonDisabled: false,
                updateClienteButtonDisabled: false,
                updatedPasswordCliente: true
            })
        }

        if (event.target.name == 'nombre' && event.target.value.length > 0) {
            //console.log('VALOR NOMBRE REPRESENTANTE ------> ', value, event.target.name)
            //this.setState ({representanteDetail.nombreRepresentante : event.target.value});
            this.setState({ saveDataCliente: true });
            this.setState({
                saveClienteButtonDisabled: true,
                updateClienteButtonDisabled: false
            })
        }

        if (event.target.name === 'CIF' && event.target.value.length == 0) {
            this.setState({ NIFClienteError: true })
        } else if (event.target.name === 'CIF' && event.target.value.length > 0) {
            const result = validateSpanishId(event.target.value)
            if (result === true) {
                this.setState({
                    saveDataCliente: true,
                    NIFClienteError: false
                });
                this.setState({
                    saveClienteButtonDisabled: true,
                    updateClienteButtonDisabled: false
                })
                const result = spainIdType(event.target.value)
                formData['descIdTipoTitular'] = result.toUpperCase();
                this.setState({ formCliente: formData });
            } else {
                this.setState({
                    NIFClienteError: true,
                    saveDataCliente: false,
                    updateClienteButtonDisabled: true
                })
            }
        }


        if (event.target.name === 'correoContacto') {


            const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
            //const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
            const result = pattern.test(event.target.value);

            if (result === true) {
                this.setState({
                    emailError: false,
                })
            } else {
                this.setState({
                    emailError: true
                })
            }
        }



        if (event.target.name === 'IBAN') {

            const resultIBAN = ibantools.isValidIBAN(event.target.value);

            if (resultIBAN === true) {
                this.setState({
                    ibanError: false,
                })
            } else {
                this.setState({
                    ibanError: true
                })
            }
        }

        if (name == 'MunicipioTitular') {

            const municipio: any = this.state.dicMunicipios
            const resultado = municipio.find(municipio => municipio ?.Nombre === value);

            //console.log('VALOR DE FIND -------------------->', resultado.DescProvincia, resultado.DescAutonomia);

            //const filtered = municipio.filter(entry => Object.values(entry).some(val => typeof val === "string" && val.includes('Barromán')));

            formData['desProvincia'] = resultado.DescProvincia
            formData['desAutonomia'] = resultado.DescAutonomia

        }


        if (name == 'codigoMunicipio') {
            const municipio: any = this.state.dicMunicipios
            const resultado = municipio.find(municipio => municipio ?.Nombre === value);
            const filtered = municipio.filter(entry => Object.values(entry).some(val => typeof val === "string" && val.includes('Barromán')));
            formData['desProvincia'] = resultado.DescProvincia
            formData['desAutonomia'] = resultado.DescAutonomia
        }


        if (event.target.name === 'esActivo' && event.target.value == 'Selecciona...') {
            value = null
        };

        if (event.target.name === 'formapago' && event.target.value == 'Selecciona...') {
            value = "CB"
        };

        if (event.target.name === 'formapago' && event.target.value == 'CB') {

            this.setState({ saveDataCliente: true });
            this.setState({
                saveClienteButtonDisabled: true,
                updateClienteButtonDisabled: false,
                inputDisabledCard: true

            })

        } else if (event.target.name === 'formapago' && event.target.value == 'TC'){

            this.setState({ saveDataCliente: true });
            this.setState({
                saveClienteButtonDisabled: true,
                updateClienteButtonDisabled: false,
                inputDisabledCard: false

            })

            }



        if (event.target.name === 'suscripcion' && event.target.value == 'Selecciona...') {
            value = "M"
        };


        if (event.target.name === 'tipoEmpresa' && event.target.value == 'Selecciona...') {
            value = "C"
        };

        if (event.target.name === 'ambitoEnergia' && event.target.value == 'Selecciona...') {
            value = "E"
        };


        if (event.target.name == 'CNAE') {
            //this.setState ({clienteDetail.nombreOferta : event.target.value});
            this.setState({ saveDataCliente: true });
            this.setState({
                saveClienteButtonDisabled: true,
                updateClienteButtonDisabled: false
            })
            const descripcionCNAE = event.target.value
            value = event.target.selectedOptions[0].innerText
            formData['actividadCNAE'] = value + " " + descripcionCNAE;
            //if (value === "" || value === null) {
            //    formData[name] = 0;
            //}
            this.setState({
                formCliente: formData,
                cnaeAux: descripcionCNAE
            });
        }



        formData[name] = value;
        //if (value === "" || value === null) {
        //    formData[name] = 0;
        //}
        this.setState({ formCliente: formData });

    };


    ///////////////////////////////////////////////////////////////////////////////////////

    showFiltersToggle = () => {
        const showFilters = !this.state.showFilters;

        this.setState({ showFilters });
    };

    //////////////////////////////////////////////////////////////////////////////////////

    getFilterIcon = () => {
        return this.state.showFilters ? "ni-bold-up" : "ni-bold-down";
    };

    ////////////////////////////////////////////////////////////////////////////////////

    clearForm = () => {
        const filters = {};
        this.setState({
            filters: {
                apellido1ClienteSearch: '',
                NIFSearch: '',
                idSearch: ''
            },
            inputIdDisabled: false,
            inputDisabled: false,
            buscarButtonDisabled: false,
            clienteSeleccionado: false,
            clienteDetailTotal: [],
            updatedPasswordCliente: false,
            formCliente: {
                id: 0,
                razonSocial: "",
                CIF: "",
                codigoProvincia: "",
                desProvincia: "",
                codigoPostal: "",
                codigoMunicipio: "",
                tipoVia: "",
                via: "",
                numFinca: "",
                portal: "",
                escalera: "",
                piso: "",
                puerta: "",
                tipoEmpresa: "",
                ambitoEnergia: "",
                CNAE: "",
                actividadCNAE: "",
                codigoAutonomia: "",
                desAutonomia: "",
                correoContacto: "",
                responsableContacto: "",
                telefonoContacto: "",
                nombre: "",
                apellido1: "",
                apellido2: "",
                formapago: "",
                IBAN: "",
                tarjetacredito: "",
                esActivo: false,
                fechainiciosuscripcion: null,
                fechaalta: null,
                suscripcion: "",
                cuota: 0,
                cvc: '',
                expiry: '',
                focus: '',
                nombretarjetacredito: ''

            },
            clienteDetail: {
                id: 0,
                razonSocial: "",
                CIF: "",
                codigoProvincia: "",
                desProvincia: "",
                codigoPostal: "",
                codigoMunicipio: "",
                tipoVia: "",
                via: "",
                numFinca: "",
                portal: "",
                escalera: "",
                piso: "",
                puerta: "",
                tipoEmpresa: "",
                ambitoEnergia: "",
                CNAE: "",
                actividadCNAE: "",
                codigoAutonomia: "",
                desAutonomia: "",
                correoContacto: "",
                responsableContacto: "",
                telefonoContacto: "",
                nombre: "",
                apellido1: "",
                apellido2: "",
                formapago: "",
                IBAN: "",
                tarjetacredito: "",
                esActivo: false,
                fechainiciosuscripcion: null,
                fechaalta: null,
                suscripcion: "",
                cuota: 0,
                cvc: '',
                expiry: '',
                focus: '',
                nombretarjetacredito: '',
            },
            toggledClearRows: !this.state.toggledClearRows,
            saveClienteButtonDisabled: true,
            updateClienteButtonDisabled: true,
            fechaalta: '',
            fechainiciosuscripcion: '',
        inputDisabledCard:true,

        });

        this.formRef.current.reset();

    };

    //////////////////////////////////////////////////////////////////////////////////////

    buscarCliente = async () => {

        /*    let esActiva: boolean = true;

                console.log('VALOR DE this.state.companyIdUser', this.state)

                if(this.state.filters.tipoEstadoSearch == "true"){

                    esActiva = true

        } else if (this.state.filters.tipoEstadoSearch == "false"){
                    esActiva = false
        } */

        var dataFilters = {
            //'idCompany': this.state.companyIdUser,
            //'idCompany': "",
            'apellido1': this.state.filters.apellido1ClienteSearch.toUpperCase(),
            'CIF': this.state.filters.NIFSearch.toUpperCase(),
            'fechaalta': "",
            'esActivo': this.state.filters.tipoEstadoSearch
        };

        const valores = JSON.stringify(dataFilters)
        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };
        let direccionFile = "https://datapi.psgestion.es/usuario/listadoempresa?id=" + this.state.companyIdUser;

        axios.get(direccionFile, { headers, params: { valores } }).
            then(result => {
                console.log('resultado del listados')
                if (result.data.length > 0) {
                    let data = result.data
                    this.setState({ clienteDetailTotal: result.data });

                } else {
                    this.warningAlertNIF()
                };

            }).catch((error) => {
                console.log('Failure')
                this.warningAlert()
            });

    };

    ///////////////////////////////////////////////////////////////////////////////////

    showTable = () => {
        if (this.state.show === false) {
            this.setState({
                show: true
            });

            const reftp = document.getElementById("tablaLecturas")
            //console.log('VALORES DE REFTP', reftp)
            reftp ?.scrollIntoView({ behavior: "smooth" })

} else {

            this.setState({
                show: false
            });
        }
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    updateHandler(props) {

        if (this.state.saveDataCliente && (this.state.formCliente.apellido1 && this.state.formCliente.CIF)) {

            this.confirmAlertUpdate()

        } else {
            this.warningAlertFiltros()
        }

    };


    onSave = async () => {

        //const fechaActivacion = this.state.fechaActivacion;
        const companyId = this.state.companyIdUser.toString();
        const userId = this.state.userId.toString();
        var self = this
        if (this.state.saveDataCliente == true) {



            var fechainiciosuscripcion = '';

            //var fechaalta = this.state.formCliente.fechaalta;

            if (!this.state.formCliente.fechainiciosuscripcion || this.state.formCliente.fechainiciosuscripcion == 'Fecha inválida') {

                fechainiciosuscripcion = moment().format("YYYY-MM-DD");

            } else {



                fechainiciosuscripcion = this.state.formCliente.fechainiciosuscripcion !== null ? this.state.formCliente.fechainiciosuscripcion.substring(0, 10) : '';
            }


            var usuarioDataInsert = {}

            var fechaalta = this.state.formCliente.fechaalta !== null ? this.state.formCliente.fechaalta.substring(0, 10) : '';


            /*console.log('Valor de fechainiciosuscripcion: ', fechainiciosuscripcion)

            var newdate = new Date(this.state.formCliente.fechainiciosuscripcion);
            newdate.setHours(newdate.getHours() + 4);
            var isoDate = newdate.toISOString();

            console.log('Valor de isoDate: ', isoDate)

            var status = moment(this.state.formCliente.fechainiciosuscripcion).add(4, 'hours').format('YYYY-MM-DDThh:mm:ss:sssZ');

            console.log('Valor de status: ', status)
*/
            usuarioDataInsert = {
                'id': this.state.formCliente.id,
                'razonSocial': this.state.formCliente.razonSocial,
                'CIF': this.state.formCliente.CIF,
                'codigoProvincia': this.state.formCliente.codigoProvincia,
                'desProvincia': this.state.formCliente.desProvincia,
                'codigoPostal': this.state.formCliente.codigoPostal,
                'codigoMunicipio': this.state.formCliente.codigoMunicipio,
                'tipoVia': this.state.formCliente.tipoVia,
                'via': this.state.formCliente.via,
                'numFinca': this.state.formCliente.numFinca,
                'portal': this.state.formCliente.portal,
                'escalera': this.state.formCliente.escalera,
                'piso': this.state.formCliente.piso,
                'puerta': this.state.formCliente.puerta,
                'tipoEmpresa': this.state.formCliente.tipoEmpresa,
                'ambitoEnergia': this.state.formCliente.ambitoEnergia,
                'CNAE': this.state.formCliente.actividadCNAE,
                'codigoAutonomia': this.state.formCliente.codigoAutonomia,
                'desAutonomia': this.state.formCliente.desAutonomia,
                'correoContacto': this.state.formCliente.correoContacto,
                'responsableContacto': this.state.formCliente.responsableContacto,
                'telefonoContacto': this.state.formCliente.telefonoContacto,
                'nombre': this.state.formCliente.nombre,
                'apellido1': this.state.formCliente.apellido1,
                'apellido2': this.state.formCliente.apellido2,
                'formapago': this.state.formCliente.formapago,
                'IBAN': this.state.formCliente.IBAN,
                'tarjetacredito': this.state.formCliente.tarjetacredito,
                'esActivo': this.state.formCliente.esActivo,
                'fechainiciosuscripcion': this.state.formCliente.fechainiciosuscripcion,
                'fechaalta': this.state.formCliente.fechaalta,
                'suscripcion': this.state.formCliente.suscripcion,
                'cuota': this.state.formCliente.cuota,
                'nombretarjetacredito': this.state.formCliente.nombretarjetacredito,
                'cvc': this.state.formCliente.cvc,
                'expiry': this.state.formCliente.expiry
            };

            var resultados: any = [];
            var resultadoView: any = [];



            const headers = {
                'Authorization': 'Bearer my-token',
                'My-Custom-Header': 'foobar',
                'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
            };
            let direccionFile = "https://datapi.psgestion.es/usuario/modificarempresa";


            await axios.post(direccionFile, usuarioDataInsert, { headers })
                .then((res) => {
                    console.log('Actualización correcta', res)
                    console.log('Datos del registro: ', res.data)
                    //resultados = res.data[0]
                    //this.setState({ createResult : [...this.state.createResult, resultados] })
                    this.successAlert();
                    this.setState({
                        inputDisabled: false,
                        clienteSeleccionado: false,
                        clienteDetailTotal: [],
                        saveDataCliente: false,
                        saveClienteButtonDisabled: true,
                        updateClienteButtonDisabled: true,
                        inputDisabledCard:true,
                        fechaalta: '',
                        fechainiciosuscripcion: '',
                        updatedPasswordCliente: false,
                        toggledClearRows: !this.state.toggledClearRows,
                        formCliente: {
                            id: 0,
                            razonSocial: "",
                            CIF: "",
                            codigoProvincia: "",
                            desProvincia: "",
                            codigoPostal: "",
                            codigoMunicipio: "",
                            tipoVia: "",
                            via: "",
                            numFinca: "",
                            portal: "",
                            escalera: "",
                            piso: "",
                            puerta: "",
                            tipoEmpresa: "",
                            ambitoEnergia: "",
                            CNAE: "",
                            actividadCNAE: "",
                            codigoAutonomia: "",
                            desAutonomia: "",
                            correoContacto: "",
                            responsableContacto: "",
                            telefonoContacto: "",
                            nombre: "",
                            apellido1: "",
                            apellido2: "",
                            formapago: "",
                            IBAN: "",
                            tarjetacredito: "",
                            esActivo: false,
                            fechainiciosuscripcion: null,
                            fechaalta: null,
                            suscripcion: "",
                            cuota: 0,
                            cvc: '',
                            expiry: '',
                            focus: '',
                            nombretarjetacredito: ''
                        },
                        clienteDetail: {
                                    id: 0,
                                    razonSocial: "",
                                    CIF: "",
                                    codigoProvincia: "",
                                    desProvincia: "",
                                    codigoPostal: "",
                                    codigoMunicipio: "",
                                    tipoVia: "",
                                    via: "",
                                    numFinca: "",
                                    portal: "",
                                    escalera: "",
                                    piso: "",
                                    puerta: "",
                                    tipoEmpresa: "",
                                    ambitoEnergia: "",
                                    CNAE: "",
                                    actividadCNAE: "",
                                    codigoAutonomia: "",
                                    desAutonomia: "",
                                    correoContacto: "",
                                    responsableContacto: "",
                                    telefonoContacto: "",
                                    nombre: "",
                                    apellido1: "",
                                    apellido2: "",
                                    formapago: "",
                                    IBAN: "",
                                    tarjetacredito: "",
                                    esActivo: false,
                                    fechainiciosuscripcion: null,
                                    fechaalta: null,
                                    suscripcion: "",
                                    cuota: 0,
                                    cvc: '',
                                    expiry: '',
                                    focus: '',
                                    nombretarjetacredito: '',
                                }

                    });

                }).catch((error) => {
                    console.log('Failure')
                    console.log('Error en el proceso: ', error)
                    this.warningAlert();
                });

        }

    };





    handleChangeDate = (value, formattedValue, id) => {


        var formData = { ...this.state.formCliente };


        const name = id;
        var valueDay = formattedValue


        if (name == 'fechaalta') {
            //console.log('VALOR NOMBRE REPRESENTANTE ------> ', value, event.target.name)
            //this.setState ({clienteDetail.nombreOferta : event.target.value});
            this.setState({ fechaalta: value });

        }

        if (name == 'fechainiciosuscripcion') {
            //this.setState ({clienteDetail.nombreOferta : event.target.value});
            this.setState({ fechainiciosuscripcion: value });
        }


        formData[name] = value;
        //if (value === "" || value === null) {
        //    formData[name] = 0;
        //}
        this.setState({ formCliente: formData });
    };


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////


    handleChangeDateFilter = (value, formattedValue, id) => {


        var formData = { ...this.state.filters };


        const name = id;
        var valueDay = formattedValue




        if (name == 'clienteDesde') {
            //console.log('VALOR NOMBRE REPRESENTANTE ------> ', value, event.target.name)
            //this.setState ({clienteDetail.nombreOferta : event.target.value});
            this.setState({
                clienteDesde: value,
                buscarButtonDisabled: false,
            });

        }

        if (name == 'clienteHasta') {
            //this.setState ({clienteDetail.nombreOferta : event.target.value});
            this.setState({
                clienteHasta: value,
                buscarButtonDisabled: false,
            });
        }


        if (name == 'fechaalta') {
            //console.log('VALOR NOMBRE REPRESENTANTE ------> ', value, event.target.name)
            //this.setState ({clienteDetail.nombreOferta : event.target.value});
            this.setState({
                fechaalta: value,
                buscarButtonDisabled: false,
            });

        }

        if (name == 'fechainiciosuscripcion') {
            //this.setState ({clienteDetail.nombreOferta : event.target.value});
            this.setState({
                fechainiciosuscripcion: value,
                buscarButtonDisabled: false,
            });
        }

        if (name == 'periodoValidezDesde') {
            //console.log('VALOR NOMBRE REPRESENTANTE ------> ', value, event.target.name)
            //this.setState ({clienteDetail.nombreOferta : event.target.value});
            this.setState({
                periodoValidezDesde: value,
                buscarButtonDisabled: false,
            });
        }

        if (name == 'periodoValidezHasta') {
            //this.setState ({clienteDetail.nombreOferta : event.target.value});
            this.setState({
                periodoValidezHasta: value,
                buscarButtonDisabled: false,
            });
        }


        formData[name] = valueDay;
        //if (value === "" || value === null) {
        //    formData[name] = 0;
        //}
        this.setState({ filters: formData });
    };

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    handleChange = state => {
        // eslint-disable-next-line no-console
        //console.log('state', state.selectedRows[0]);
        //console.log(state.selectedCount)

        var producto = '';

        if (state.selectedCount === 1) {
            var existeCliente = ''
            var cliente = state.selectedRows[0].id;
            this.setState({ clienteDetail: state.selectedRows[0] });
            let existeFechaAlta = state.selectedRows[0].fechaalta
            let existeFechaBaja = state.selectedRows[0].fechainiciosuscripcion
            existeCliente = state.selectedRows[0].id

            if (existeFechaAlta && existeFechaBaja) {

                this.setState({
                    inputDisabled: true,
                    updateClienteButtonDisabled: false
                })
            } else if (!existeFechaAlta && !existeFechaBaja) {
                this.setState({
                    inputDisabled: false,
                    updateClienteButtonDisabled: true
                })
            }



            if (existeCliente) {

                var newCreatedDate = moment(state.selectedRows[0].fechaalta).add(4, 'hours').format(' YYYY-MM-DD hh:mm:ss')
                var newModifiedDate = moment(state.selectedRows[0].fechainiciosuscripcion).add(4, 'hours').format('YYYY-MM-DD hh:mm:ss')
                var isonewCreatedDate = moment(newCreatedDate).toISOString()
                var isonewModifiedDate = moment(newModifiedDate).toISOString()
                var esTarjetadisabled = true

                if (state.selectedRows[0].formapago === "TC"){
                    esTarjetadisabled = false

                }

                this.setState({ idCliente: this.state.clienteDetail.id });
                this.setState({
                    formCliente: {

                        id: state.selectedRows[0].id,
                        razonSocial: state.selectedRows[0].razonSocial,
                        CIF: state.selectedRows[0].CIF,
                        codigoProvincia: state.selectedRows[0].codigoProvincia,
                        desProvincia: state.selectedRows[0].desProvincia,
                        codigoPostal: state.selectedRows[0].codigoPostal,
                        codigoMunicipio: state.selectedRows[0].codigoMunicipio,
                        tipoVia: state.selectedRows[0].tipoVia,
                        via: state.selectedRows[0].via,
                        numFinca: state.selectedRows[0].numFinca,
                        portal: state.selectedRows[0].portal,
                        escalera: state.selectedRows[0].escalera,
                        piso: state.selectedRows[0].piso,
                        puerta: state.selectedRows[0].puerta,
                        tipoEmpresa: state.selectedRows[0].tipoEmpresa,
                        ambitoEnergia: state.selectedRows[0].ambitoEnergia,
                        CNAE: state.selectedRows[0].CNAE,
                        actividadCNAE: state.selectedRows[0].CNAE,
                        codigoAutonomia: state.selectedRows[0].codigoAutonomia,
                        desAutonomia: state.selectedRows[0].desAutonomia,
                        correoContacto: state.selectedRows[0].correoContacto,
                        responsableContacto: state.selectedRows[0].responsableContacto,
                        telefonoContacto: state.selectedRows[0].telefonoContacto,
                        nombre: state.selectedRows[0].nombre,
                        apellido1: state.selectedRows[0].apellido1,
                        apellido2: state.selectedRows[0].apellido2,
                        formapago: state.selectedRows[0].formapago,
                        IBAN: state.selectedRows[0].IBAN,
                        tarjetacredito: state.selectedRows[0].tarjetacredito,
                        esActivo: state.selectedRows[0].esActivo,
                        fechainiciosuscripcion: state.selectedRows[0].fechainiciosuscripcion,
                        fechaalta: state.selectedRows[0].fechaalta,
                        suscripcion: state.selectedRows[0].suscripcion,
                        cuota: state.selectedRows[0].cuota,
                        expiry: state.selectedRows[0].expiry,
                        focus: '',
                        nombretarjetacredito: state.selectedRows[0].nombretarjetacredito

                    },
                    clienteSeleccionado: true,
                    inputDisabled: false,
                    inputDisabledCard: esTarjetadisabled,
                    fechaalta: isonewCreatedDate,
                    fechainiciosuscripcion: isonewModifiedDate,
                });

            } else {
                this.setState({
                    clienteSeleccionado: false,
                    formCliente: {
                        id: 0,
                        razonSocial: "",
                        CIF: "",
                        codigoProvincia: "",
                        desProvincia: "",
                        codigoPostal: "",
                        codigoMunicipio: "",
                        tipoVia: "",
                        via: "",
                        numFinca: "",
                        portal: "",
                        escalera: "",
                        piso: "",
                        puerta: "",
                        tipoEmpresa: "",
                        ambitoEnergia: "",
                        CNAE: "",
                        actividadCNAE: "",
                        codigoAutonomia: "",
                        desAutonomia: "",
                        correoContacto: "",
                        responsableContacto: "",
                        telefonoContacto: "",
                        nombre: "",
                        apellido1: "",
                        apellido2: "",
                        formapago: "",
                        IBAN: "",
                        tarjetacredito: "",
                        esActivo: false,
                        fechainiciosuscripcion: null,
                        fechaalta: null,
                        suscripcion: "",
                        cuota: 0,
                        cvc: '',
                        expiry: '',
                        focus: '',
                        nombretarjetacredito: ''
                    },

                    saveDataCliente: false,
                    saveClienteButtonDisabled: true,
                    updateClienteButtonDisabled: true,
                    inputDisabledCard:true,
                });
            }
        } else {

            this.setState({
                clienteSeleccionado: false,
                inputDisabled: false,
                inputDisabledCard:true,
                saveDataCliente: false,
                saveClienteButtonDisabled: true,
                updateClienteButtonDisabled: true,
                formCliente: {
                    id: 0,
                    razonSocial: "",
                    CIF: "",
                    codigoProvincia: "",
                    desProvincia: "",
                    codigoPostal: "",
                    codigoMunicipio: "",
                    tipoVia: "",
                    via: "",
                    numFinca: "",
                    portal: "",
                    escalera: "",
                    piso: "",
                    puerta: "",
                    tipoEmpresa: "",
                    ambitoEnergia: "",
                    CNAE: "",
                    actividadCNAE: "",
                    codigoAutonomia: "",
                    desAutonomia: "",
                    correoContacto: "",
                    responsableContacto: "",
                    telefonoContacto: "",
                    nombre: "",
                    apellido1: "",
                    apellido2: "",
                    formapago: "",
                    IBAN: "",
                    tarjetacredito: "",
                    esActivo: false,
                    fechainiciosuscripcion: null,
                    fechaalta: null,
                    suscripcion: "",
                    cuota: 0,
                    cvc: '',
                    expiry: '',
                    focus: '',
                    nombretarjetacredito: ''
                }
            });



        };

    }



    handleClearRows = () => {
        this.setState({ toggledClearRows: !this.state.toggledClearRows })
    }

    handleInputFocus = (e) => {
        this.setState({ focus: e.target.name });
    }

    handleInputChangeCard = (event) => {

        event.persist()

        var formData = { ...this.state.formCliente };
        var target = event.target;
        var value = event.target.value;
        const name = event.target.name;

        formData[name] = value;
        //if (value === "" || value === null) {
        //    formData[name] = 0;
        //}
        this.setState({ formCliente: formData });
    }


    render() {

        const formCliente: any = this.state.formCliente;
        const fechaalta = this.state.fechaalta ? this.state.fechaalta : ""
        const fechainiciosuscripcion = this.state.fechainiciosuscripcion ? this.state.fechainiciosuscripcion : ""

        const clienteDesde = this.state.clienteDesde
        const clienteHasta = this.state.clienteHasta

        return (
            <>
                <Header title={'Información Empresa Plataforma ENERCOM/DYNARGY'} />
                <Container className=" mt--7" fluid>

                    <Card className="mb-4">
                        <CardHeader>
                            <Button
                                onClick={this.showFiltersToggle}
                                size="sm"
                                className="float-right"
                            >
                                <i className={"ni " + this.getFilterIcon()} />
                            </Button>
                            <h3 className="mb-0">Filtrar</h3>
                        </CardHeader>
                        <Collapse isOpen={this.state.showFilters}>
                            <CardBody>
                                <Form innerRef={this.formRef}>
                                    <Row>
                                        <Col md="12" lg="3">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="clienteRef">
                                                    APELLIDO
                                  </Label>
                                                <Input
                                                    id="apellido1ClienteSearch"
                                                    placeholder="Ingresar Apellido del cliente"
                                                    name="apellido1ClienteSearch"
                                                    maxLength={80}
                                                    onChange={this.onChangeFieldSearch}
                                                    value={this.state.filters.apellido1ClienteSearch}
                                                    type="text"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg="2">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="NIFSearch">
                                                    DNI/NIF
                                            </Label>
                                                <Input
                                                    id="NIFSearch"
                                                    placeholder="Ingresar NIF del representante"
                                                    name="NIFSearch"
                                                    maxLength={16}
                                                    onChange={this.onChangeFieldSearch}
                                                    value={this.state.filters.NIFSearch}
                                                    type="text"

                                                />
                                                {this.state.NIFError ? <FormText color="red">Comprobar NIF/DNI</FormText> : ''}
                                            </FormGroup>
                                        </Col>



                                        <Col md="2" lg="2">
                                            <FormGroup>
                                                <Label className="form-control-label" for="tipoEstadoProducto">ESTADO</Label>
                                                <Input type="select" name="tipoEstadoSearch" id="tipoEstadoSearch"
                                                    onChange={this.onChangeFieldSearch}
                                                    value={this.state.filters.tipoEstadoSearch}>
                                                    <option selected>Selecciona...</option>
                                                    <option value="true">ACTIVO</option>
                                                    <option value="false">BAJA</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>

                                        <Col className="text-right">
                                            <Button onClick={this.clearForm}>
                                                <i className={"ni ni-fat-remove"} /> Limpiar
                                    </Button>
                                            <Button
                                                color="primary"
                                                onClick={this.buscarCliente}
                                                disabled={this.state.buscarButtonDisabled}
                                            >
                                                <i className={"bi bi-search"} /> Buscar
                                    </Button>
                                        </Col>
                                    </Row>

                                    <Card className="shadow">
                                        <DataTable
                                            title="LISTADO DE CLIENTES"
                                            columns={columnsDatosClientes}
                                            data={this.state.clienteDetailTotal}
                                            selectableRows
                                            //selectableRowsComponent={radio} // Pass the function only
                                            selectableRowsComponentProps={{ type: 'radio' }}// optionally, pass Material Ui supported props down to our custom checkbox
                                            selectableRowsSingle
                                            sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you           fixedHeader = {true}
                                            noDataComponent={"Sin datos para mostrar"}
                                            pagination
                                            paginationPerPage={10}
                                            paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 40]}
                                            onSelectedRowsChange={this.handleChange}
                                            clearSelectedRows={this.state.toggledClearRows}
                                            highlightOnHover
                                            striped
                                            pointerOnHover
                                            selectableRowsHighlight
                                            customStyles={customStyles}
                                            contextMessage={{ singular: 'producto', plural: 'productos', message: 'seleccionado' }}
                                            subHeader
                                            subHeaderComponent={

                                                <div style={{ display: 'flex', alignItems: 'center' }}>

                                                    <CSVLink
                                                        data={this.state.clienteDetailTotal}
                                                        filename={"ClientesRegistrados.csv"}
                                                        className="btn btn-default btn-sm btn-circle"
                                                        color="default"
                                                        target="_blank"
                                                        separator={";"}
                                                    >

                                                        <span className="btn-inner--icon">
                                                            <i className={"bi bi-file-earmark-spreadsheet"} style={{ fontSize: 25 }}></i>
                                                        </span>
                                                        <span className="btn-inner--text">Fichero CSV</span>

                                                    </CSVLink>
                                                </div>
                                            }
                                            progressComponent={<Circular />}
                                        />
                                    </Card>

                                </Form>
                            </CardBody>
                        </Collapse>
                    </Card>

                    <Row>
                        <div className="col">
                            <Card className="shadow">
                                <CardBody>

                                    <Row>
                                        <Col>
                                            <Button color="primary" size="sm" type="button"
                                                className="btn-icon btn-sm btn-circle"
                                                onClick={this.props.history.goBack}
                                            >
                                                <span className="bi bi-box-arrow-left"> Volver</span>
                                            </Button>
                                        </Col>

                                        <Col className="col-auto d-flex">
                                            <Card className="p-3 bg-light">
                                                <FormGroup>

                                                    <Button disabled={this.state.saveClienteButtonDisabled} id="saveRepreProptooltip" color="danger" size="m" type="button" className="btn-icon btn-sm btn-circle"
                                                        onClick={() => { this.updateHandler(this.state.saveDataCliente) }}
                                                    >
                                                        <span className="btn-inner--icon">
                                                            <i className="bi bi-person-plus-fill" style={{ fontSize: 25 }}></i>
                                                        </span>
                                                        <span className="btn-inner--text">Guardar</span>
                                                        <UncontrolledTooltip delay={0} placement="top" target="saveRepreProptooltip">
                                                            MODIFICAR EMPRESA
                                                    </UncontrolledTooltip>
                                                    </Button>


                                                </FormGroup>
                                            </Card>
                                        </Col>

                                    </Row>

                                    <div>
                                        <CardTitle>DATOS GENERALES EMPRESA</CardTitle>
                                    </div>

                                    <Row>
                                        <Col md="2">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="id">
                                                    ID
                                    </Label>
                                                <Input
                                                    id="id"
                                                    placeholder=""
                                                    name="id"
                                                    maxLength={12}
                                                    onChange={this.onChangeFieldCliente}
                                                    value={formCliente.id}
                                                    type="text"
                                                    disabled={true}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col>
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="razonSocial">
                                                    Razón Social
                                                </Label>
                                                <Input
                                                    className="text-primary"
                                                    id="razonSocial"
                                                    placeholder=""
                                                    name="razonSocial"
                                                    maxLength={40}
                                                    onChange={this.onChangeFieldCliente}
                                                    value={formCliente.razonSocial}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="2">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="CIF">
                                                    CIF
                                                </Label>
                                                <Input
                                                    id="CIF"
                                                    placeholder=""
                                                    name="CIF"
                                                    maxLength={16}
                                                    onChange={this.onChangeFieldCliente}
                                                    value={formCliente.CIF || ""}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                                {this.state.NIFClienteError ? <FormText color="red">Comprobar NIF/NIF</FormText> : ''}
                                            </FormGroup>
                                        </Col>

                                        <Col md="2" lg="2">
                                            <FormGroup>
                                                <Label className="form-control-label" for="tipoEmpresa">Tipo Empresa</Label>
                                                <Input type="select" name="tipoEmpresa" id="tipoEmpresa"
                                                    onChange={this.onChangeFieldCliente}
                                                    value={formCliente.tipoEmpresa}
                                                    disabled={this.state.inputDisabled}>
                                                    <option selected>Selecciona...</option>
                                                    <option value="C">COMERCIALIZADORA</option>
                                                    <option value="A">AGENCIA</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>

                                        <Col md="2" lg="2">
                                            <FormGroup>
                                                <Label className="form-control-label" for="ambitoEnergia">Tipo Energía</Label>
                                                <Input type="select" name="ambitoEnergia" id="ambitoEnergia"
                                                    onChange={this.onChangeFieldCliente}
                                                    value={formCliente.ambitoEnergia}
                                                    disabled={this.state.inputDisabled}>
                                                    <option selected>Selecciona...</option>
                                                    <option value="E">ELECTRICIDAD</option>
                                                    <option value="G">GAS</option>
                                                    <option value="EG">ELECTR/GAS</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>

                                        <Col>
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="nombre">
                                                    Nombre Contacto
            </Label>
                                                <Input
                                                    id="nombre"
                                                    placeholder=""
                                                    name="nombre"
                                                    maxLength={50}
                                                    onChange={this.onChangeFieldCliente}
                                                    value={formCliente.nombre}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col>
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="apellido1">
                                                    Apellido 1
                                                </Label>
                                                <Input
                                                    id="apellido1"
                                                    placeholder=""
                                                    name="apellido1"
                                                    maxLength={50}
                                                    onChange={this.onChangeFieldCliente}
                                                    value={formCliente.apellido1}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col>
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="apellido2">
                                                    Apellido 2
                                                </Label>
                                                <Input
                                                    id="apellido2"
                                                    placeholder=""
                                                    name="apellido2"
                                                    maxLength={50}
                                                    onChange={this.onChangeFieldCliente}
                                                    value={formCliente.apellido2}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>


                                        <Col>
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="correoContacto">
                                                    Correo Elec.
                                                </Label>
                                                <Input
                                                    id="correoContacto"
                                                    placeholder=""
                                                    name="correoContacto"
                                                    maxLength={50}
                                                    onChange={this.onChangeFieldCliente}
                                                    value={formCliente.correoContacto}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col>
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="telefonoContacto">
                                                    Teléfono
                                                </Label>
                                                <Input
                                                    id="telefonoContacto"
                                                    placeholder=""
                                                    name="telefonoContacto"
                                                    maxLength={50}
                                                    onChange={this.onChangeFieldCliente}
                                                    value={formCliente.telefonoContacto}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>

                                    </Row>
                                    <Row>

                                        <Col md="2">
                                            <Label className="form-control-label" htmlFor="identificacionEmpresa">
                                                EMPRESA
                                    </Label>
                                            <FormGroup className="custom-control custom-checkbox">

                                                <Input
                                                    className="custom-control-input"
                                                    id="esActivo"
                                                    name="esActivo"
                                                    onChange={this.onChangeFieldCliente}
                                                    checked={formCliente.esActivo}
                                                    type="checkbox"
                                                    disabled={this.state.inputDisabled}

                                                />
                                                <label className="custom-control-label" htmlFor="esActivo">
                                                    Empresa Activa S/N
                                    </label>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <div>
                                    </div>
                                    <div>
                                        <CardTitle>DATOS PAGO SUSCRIPCIÓN</CardTitle>
                                    </div>
                                    <Row>

                                        <Col md="2" lg="2">
                                            <FormGroup>
                                                <Label className="form-control-label" for="formapago">Forma Pago</Label>
                                                <Input type="select" name="formapago" id="formapago"
                                                    onChange={this.onChangeFieldCliente}
                                                    value={formCliente.formapago}
                                                    disabled={this.state.inputDisabled}>
                                                    <option selected>Selecciona...</option>
                                                    <option value="TC">TARJETA CREDITO</option>
                                                    <option value="CB">CUENTA BANCARIA</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>

                                        <Col md="3">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="IBAN">
                                                    Cuenta Bancaria.
    </Label>
                                                <Input
                                                    id="IBAN"
                                                    placeholder=""
                                                    name="IBAN"
                                                    maxLength={12}
                                                    onChange={this.onChangeFieldCliente}
                                                    value={formCliente.IBAN}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>



                                        <Col md="2" lg="2">
                                            <FormGroup>
                                                <Label className="form-control-label" for="suscripcion">Suscripción</Label>
                                                <Input type="select" name="suscripcion" id="suscripcion"
                                                    onChange={this.onChangeFieldCliente}
                                                    value={formCliente.suscripcion}
                                                    disabled={this.state.inputDisabled}>
                                                    <option selected>Selecciona...</option>
                                                    <option value="M">MENSUAL</option>
                                                    <option value="A">ANUAL</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>


                                        <Col md="2">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="cuota">
                                                    Cuota
    </Label>
                                                <Input
                                                    id="cuota"
                                                    placeholder=""
                                                    name="cuota"
                                                    maxLength={12}
                                                    min={0}
                                                    onChange={this.onChangeFieldCliente}
                                                    value={formCliente.cuota}
                                                    type="number"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="2" lg="2">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="fechaalta">
                                                    Alta
    </Label>

                                                <DatePicker id="fechaalta"
                                                    value={fechaalta}
                                                    onChange={(v, f) => this.handleChangeDate(v, f, "fechaalta")}
                                                    instanceCount={1}
                                                    dateFormat={"DD-MM-YYYY"}
                                                    dayLabels={spanishDayLabels}
                                                    monthLabels={spanishMonthLabels}
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>

                                        </Col>

                                        <Col md="2" lg="2">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="fechainiciosuscripcion">
                                                    In. Subsc.
    </Label>

                                                <DatePicker id="fechainiciosuscripcion"
                                                    value={fechainiciosuscripcion}
                                                    onChange={(v, f) => this.handleChangeDate(v, f, "fechainiciosuscripcion")}
                                                    instanceCount={2}
                                                    dateFormat={"DD-MM-YYYY"}
                                                    dayLabels={spanishDayLabels}
                                                    monthLabels={spanishMonthLabels}
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>

                                        </Col>

                                    </Row>

                                    <Row>


                                    </Row>

                                </CardBody>
                            </Card>

                            <Row className="margin-reset w-100">



                                    <Card >
                                        <CardBody>

<Row>
                                    <Col md="5">
                                        <Cards
                                            cvc={formCliente.cvc}
                                            expiry={formCliente.expiry}
                                            focused={formCliente.focus}
                                            name={formCliente.nombretarjetacredito}
                                            number={formCliente.tarjetacredito}
                                            disabled={this.state.inputDisabledCard}
                                        />
                                    </Col>
                                    <Col md="5">
                                        <div className="form-group">
                                            <Input
                                                type="tel"
                                                name="tarjetacredito"
                                                placeholder="Número Tarjeta"
                                                onChange={this.handleInputChangeCard}
                                                onFocus={this.handleInputFocus}
                                                disabled={this.state.inputDisabledCard}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <Input
                                                type="text"
                                                name="nombretarjetacredito"
                                                className="form-control"
                                                placeholder="Nombre"
                                                required
                                                onChange={this.handleInputChangeCard}
                                                onFocus={this.handleInputFocus}
                                                disabled={this.state.inputDisabledCard}
                                            />
                                        </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <Input
                                                    type="tel"
                                                    name="expiry"
                                                    className="form-control"
                                                    placeholder="Valido Hasta"
                                                    pattern="\d\d/\d\d"
                                                    required
                                                    onChange={this.handleInputChangeCard}
                                                    onFocus={this.handleInputFocus}
                                                    disabled={this.state.inputDisabledCard}
                                                />
                                            </div>
                                            <div className="col-6">
                                                <Input
                                                    type="tel"
                                                    name="cvc"
                                                    className="form-control"
                                                    placeholder="CVC"
                                                    pattern="\d{3,4}"
                                                    required
                                                    onChange={this.handleInputChangeCard}
                                                    onFocus={this.handleInputFocus}
                                                    disabled={this.state.inputDisabledCard}
                                                />


                                            </div>
</div>
                                        </Col>

</Row>
                                        </CardBody>
                                    </Card>

</Row>



                            <Card className="shadow">
                                <CardBody>
                                    <div>
                                        <CardTitle>OTROS DATOS</CardTitle>
                                    </div>

                                    <Row>

                                        <Col md="2">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="tipoVia">
                                                    Tipo Via
                                                                                                            </Label>
                                                <Input
                                                    id="tipoVia"
                                                    placeholder=""
                                                    name="tipoVia"
                                                    maxLength={12}
                                                    onChange={this.onChangeFieldCliente}
                                                    value={formCliente.tipoVia}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="6">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="via">
                                                    Dirección
                                                                                                           </Label>
                                                <Input
                                                    id="via"
                                                    placeholder=""
                                                    name="via"
                                                    maxLength={60}
                                                    onChange={this.onChangeFieldCliente}
                                                    value={formCliente.via}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}

                                                />
                                            </FormGroup>
                                        </Col>


                                        <Col md="2">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="numFinca">
                                                    Nº Finca
                                                                                                            </Label>
                                                <Input
                                                    id="numFinca"
                                                    placeholder=""
                                                    name="numFinca"
                                                    maxLength={10}
                                                    onChange={this.onChangeFieldCliente}
                                                    value={formCliente.numFinca}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}

                                                />
                                            </FormGroup>
                                        </Col>



                                        <Col md="2">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="portal">
                                                    Portal
                                                                                                            </Label>
                                                <Input
                                                    id="portal"
                                                    placeholder=""
                                                    name="portal"
                                                    maxLength={10}
                                                    onChange={this.onChangeFieldCliente}
                                                    value={formCliente.portal}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}

                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="1">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="escalera">
                                                    ESC.
                                                                                                            </Label>
                                                <Input
                                                    id="escalera"
                                                    placeholder=""
                                                    name="escalera"
                                                    maxLength={5}
                                                    onChange={this.onChangeFieldCliente}
                                                    value={formCliente.escalera}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}

                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="1">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="piso">
                                                    Piso
                                                                                                            </Label>
                                                <Input
                                                    id="piso"
                                                    placeholder=""
                                                    name="piso"
                                                    maxLength={5}
                                                    onChange={this.onChangeFieldCliente}
                                                    value={formCliente.piso}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}

                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="1">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="puerta">
                                                    Puerta
                                                                                                            </Label>
                                                <Input
                                                    id="puerta"
                                                    placeholder=""
                                                    name="puerta"
                                                    maxLength={5}
                                                    onChange={this.onChangeFieldCliente}
                                                    value={formCliente.puerta}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}

                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="2">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="codigoPostal">
                                                    C.P.
                                                                                                           </Label>
                                                <Input
                                                    id="codigoPostal"
                                                    placeholder=""
                                                    name="codigoPostal"
                                                    maxLength={5}
                                                    onChange={this.onChangeFieldCliente}
                                                    value={formCliente.codigoPostal}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}

                                                />
                                                {this.state.codigoPostal ? <FormText color="red">Por favor compruebe el código postal del titular</FormText> : ''}
                                            </FormGroup>
                                        </Col>

                                        <Col md="3">
                                            <FormGroup>
                                                <Label className="form-control-label">
                                                    Municipio
                                                                                                              </Label>
                                                <SipsOption
                                                    placeholder="Seleccionar localidad"
                                                    //                     invalid={forceUseLocality}
                                                    id="codigoMunicipio"
                                                    name="codigoMunicipio"
                                                    maxLength={60}
                                                    query="LIST_LOCALITIESNAME"
                                                    disabled={this.state.inputDisabled}
                                                    onChange={this.onChangeFieldCliente}
                                                    value={formCliente.codigoMunicipio}
                                                />
                                            </FormGroup>
                                        </Col>


                                        <Col md="2">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="desProvincia">
                                                    Provincia
                                                                                                            </Label>
                                                <Input
                                                    id="desProvincia"
                                                    placeholder=""
                                                    name="desProvincia"
                                                    maxLength={40}
                                                    onChange={this.onChangeFieldCliente}
                                                    value={formCliente.desProvincia}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}

                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="3">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="desAutonomia">
                                                    C. Autónoma
                                                                                                            </Label>
                                                <Input
                                                    id="desAutonomia"
                                                    placeholder=""
                                                    name="desAutonomia"
                                                    maxLength={80}
                                                    onChange={this.onChangeFieldCliente}
                                                    value={formCliente.desAutonomia}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}

                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="12" lg="4">
                                            <FormGroup>
                                                <Label
                                                    className="form-control-label"
                                                    htmlFor="CNAE"
                                                >
                                                    CNAE
                                                                                                                                                                    </Label>
                                                <SipsOption
                                                    placeholder="Seleccionar Código CNAE"
                                                    id="CNAE"
                                                    name="CNAE"
                                                    value={this.state.cnaeAux}
                                                    query="LIST_CNAE"
                                                    onChange={this.onChangeFieldCliente}
                                                    disabled={this.state.inputDisabled}

                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="3">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="actividadCNAE">
                                                    Actividad CNAE
                                                                                                                                                                    </Label>
                                                <Input
                                                    id="actividadCNAE"
                                                    placeholder=""
                                                    name="actividadCNAE"
                                                    onChange={this.onChangeFieldCliente}
                                                    value={formCliente.actividadCNAE}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}

                                                />
                                            </FormGroup>
                                        </Col>










                                    </Row>

                                </CardBody>
                            </Card>
                            {this.state.alert}

                        </div>
                    </Row>
                </Container>

            </>
        );
    }
}


export default withApollo(actualizarClienteEmpresa);
