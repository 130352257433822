/*eslint-disable*/
import React from "react";
import Reflux from "reflux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
// reactstrap components
import {
Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  UncontrolledCollapse,
  Row,
  Col,
  Button,
  DropdownToggle,
  Navbar,
  Nav,
  NavItem,
  Container,
  Media,
} from "reactstrap";
// Utils
import { removeToken } from "../../utils";
// Avatar
import Avatar from "react-avatar";
// Apollo
import { GET_USER_DATA, LOGOUT } from "../../queries/users/users.queries";
import { withApollo } from "react-apollo";
// Store
import { UserStore } from "../../store/UserStore";
import classnames from "classnames";

class AdminNavbar extends Reflux.Component {
  state = {
    fullname: "",
    first_name: "",
    last_name: "",
    avatar: "",
  };

  constructor(props) {
    super(props);
    this.store = UserStore;
    this.logout = this.logout.bind(this);
  }
  componentDidMount() {
    this.getUserData();
  }
  async getUserData() {

    const resp = await this.props.client.query({
      query: GET_USER_DATA,
    });

    const user = resp.data.userDataAdministratorBackend;
    this.setState({
      fullname: user.fullname,
      first_name: user.first_name,
      last_name: user.last_name,
      avatar: "",
    });
  }
  logout = async () => {
    const client = this.props.client;
    const resp = await client.mutate({
      mutation: LOGOUT,
    });
    const session = resp.data.logout;
    if (session.session_id && !session.active) {
      removeToken();
      localStorage.removeItem("lastFilters");
      localStorage.removeItem("user");
      localStorage.removeItem("lastFiltersEmpre");
      localStorage.removeItem("lastFiltersMulti");
      localStorage.removeItem('lastFiltersMultiGas');
      localStorage.removeItem('lastFiltersGas');

      this.props.client.resetStore();
      this.props.history.push(`/auth/login`);
    } else {
      alert("Problema al cerrar sesión");
    }
  };
  render() {
    return (
      <Navbar className="navbar-top navbar-expand border-bottom navbar-dark bg-info" expand="md" id="navbar-main">
        <Container fluid>
        <Collapse navbar isOpen={true}>
          <Link
            className="h2 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            to="/"
          >
            {this.props.brandText}
          </Link>
          <Nav className="align-items-center ml-md-auto" navbar>
         <NavItem className="d-xl-none">
                  <div
                    className={classnames(
                      "pr-3 sidenav-toggler",
                      { active: this.props.sidenavOpen },
                      { "sidenav-toggler-dark": this.props.theme === "dark" }
                    )}
                    onClick={this.props.toggleSidenav}
                  >
                    <div className="sidenav-toggler-inner">
                      <i className="sidenav-toggler-line" />
                      <i className="sidenav-toggler-line" />
                      <i className="sidenav-toggler-line" />
                    </div>
                  </div>
                </NavItem>


              </Nav>

          <Nav className="align-items-center ml-auto ml-md-0" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="nav-link pr-0" nav>

                <Media className="align-items-center">
                  <Avatar
                    name={this.state.fullname}
                    src={this.state.avatar}
                    round={true}
                    color={"#fff"}
                    fgColor={"#000"}
                    maxInitials={2}
                    size={"36px"}
                  />
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      {this.state.UserStore.data.fullname}
                    </span>
                  </Media>
                </Media>

              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Bienvenido!</h6>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>Mi perfil</span>
                </DropdownItem>

                <DropdownItem divider />
                <DropdownItem href="#" onClick={this.logout}>
                  <i className="ni ni-user-run" />
                  <span>Cerrar sesión</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>

              </Nav>

          </Collapse>
        </Container>
      </Navbar>
    );
  }
}
AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: true
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"])
};

export default withApollo(AdminNavbar);
