import React from "react";
import Reflux from "reflux";
import axios from 'axios';
import { removeToken } from "../../utils";
import { validateSpanishId, spainIdType } from 'spain-id';
import {
    Card, Container, Row, CardTitle, CardBody, Label,
    FormGroup,
    Input, FormText,
    Form, Col, Button,
    UncontrolledTooltip, CardHeader, Collapse, FormFeedback
} from "reactstrap";
import {
    Link
} from 'react-router-dom';
import { CSVLink } from "react-csv";
// core components
import Header from "../../components/Headers/Header";
import { withApollo } from "react-apollo";
import moment from "moment";
import 'moment/locale/es'
import ReactBSAlert from "react-bootstrap-sweetalert";
import client from "../../api/client";
import { LIST_DICMUNICIPIOS, LIST_DICMUNICIPIOSPOSTALSHORT } from "../../queries/options/dic-option.graphql";

import { UserStore } from "../../store/UserStore";
import DataTable from 'react-data-table-component';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import CircularProgress from '@material-ui/core/CircularProgress';

var DatePicker = require("reactstrap-date-picker");

const spanishDayLabels = ['Dom', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sab'];
const spanishMonthLabels = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
const sortIcon = <ArrowDownward />;
const estado = (s) => {
    if (s !== true) return <i className="fas fa-exclamation-circle fa-2x" style={{ color: "#ff1900" }} ></i>
    return <i className="far fa-check-circle fa-2x" style={{ color: "#7ee06e" }} ></i>
}

const columnsDatosEmpresas = [
    { selector: row => row.nif, name: 'NIF', sortable: true, width: '150px', format: (row => capitalize(row.nif)) },
    { selector: row => row.empresa, name: 'EMPRESA', sortable: true, width: '240px', center: true, format: (row => capitalize(row.empresa)) },
    { selector: row => row.tipo, name: 'TIPO', sortable: true, width: '100px', center: true, format: (row => capitalize(row.tipo)) },
    { selector: row => row.tipoServicio, name: 'SERVICIO', sortable: true, width: '100px', center: true, format: (row => capitalize(row.tipoServicio)) },
    { selector: row => row.numOrden, name: 'ORDEN', sortable: true, width: '100px', center: true, format: (row => capitalize(row.numOrden)) },
    { selector: row => row.correoContacto, name: 'CORREO', sortable: true, width: '150px', center: true, format: (row => capitalize(row.correoContacto)) },
    { selector: row => row.nombreContacto, name: 'CONTACTO', sortable: true, width: '140px', center: true, format: (row => capitalize(row.nombreContacto)) },
    { selector: row => row.telefonoContacto, name: 'TELEFONO', sortable: true, width: '140px', center: true, format: (row => capitalize(row.telefonoContacto)) }
];


const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.toLowerCase().charAt(0).toUpperCase() + s.slice(1)
}

const customStyles = {
    headRow: {
        style: {
            border: 'none',

        },
    },
    headCells: {
        style: {
            color: '#202124',
            fontSize: '12px',
            fontWeight: 'bold',
            wordBreak: 'break-word',
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px'

        },
    },
    cells: {
        style: {
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px',
            wordBreak: 'break-word',
            fontFamily: '"Open Sans", sans-serif;'
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 250, 250)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
            fontSize: '12px',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};


const Circular = () => (
    // we need to add some padding to circular progress to keep it from activating our scrollbar
    <div style={{ padding: '24px' }}>
        <CircularProgress size={75} />
    </div>
);


class actualizarEmpresaEnergia extends Reflux.Component<any, any> {
    public formRef: any = null;
    public maxFileSize = 3;
    public validFileExtensions = ["jpg", "jpeg", "png"];
    lastFilters = {};
    state = {
        filters: {
            apellidosUsuariosSearch: "",
            NIFSearch: "",
            tipoEstadoSearch: "",
            usuariosDesde: ""
        },
        UserStore: {},
        isLoading: false,
        currentPage: 0,
        pageSize: 0,
        pageCount: 0,
        count: 0,
        contrato: "",
        empresaDetailTotal: [],
        empresaSeleccionado: false,
        passwordModificado: false,
        idUser: '',
        empresaDetail: {
            id: 0,
            idEmpresa: "",
            nif: "",
            tipo: "",
            tipoServicio: "",
            numOrden: "SIN ORDEN",
            empresa: "",
            direccion: "",
            codigoPostal: "",
            municipio: "",
            provincia: "",
            telefonoAtt: "",
            telefonoUrgencias: "",
            ambito: "",
            fechaAlta: "",
            fechaBaja: "",
            web: "",
            estado: "a",
            correoContacto: "",
            nombreContacto: "",
            telefonoContacto: "",
            fechAltaDatos: "",
            usuario: 0,
            logoApp: "https://datapi.psgestion.es/files/images/dynargy_logo_verde_small.png",
            logoPropuesta: "https://datapi.psgestion.es/files/images/dynargy_logo_verde_small.png",
            autonomia: "",
            licencias: 1,
            idAlianza: ""
        },
        formEmpresa: {
            id: 0,
            idEmpresa: "",
            nif: "",
            tipo: "",
            tipoServicio: "",
            numOrden: "SIN ORDEN",
            empresa: "",
            direccion: "",
            codigoPostal: "",
            municipio: "",
            provincia: "",
            telefonoAtt: "",
            telefonoUrgencias: "",
            ambito: "",
            fechaAlta: "",
            fechaBaja: "",
            web: "",
            estado: "a",
            correoContacto: "",
            nombreContacto: "",
            telefonoContacto: "",
            fechAltaDatos: "",
            usuario: 0,
            logoApp: "https://datapi.psgestion.es/files/images/dynargy_logo_verde_small.png",
            logoPropuesta: "https://datapi.psgestion.es/files/images/dynargy_logo_verde_small.png",
            autonomia: "",
            licencias: 1,
            idAlianza: ""
        },
        companyIdUser: '',
        companyNameGlobal: '',
        userGroupId: 1000,
        userId: '',
        esAgencia: false,
        esComercializadora: false,
        esAdFincas: false,
        saveDataEmpresa: false,
        updateDataEmpresa: false,
        updateEmpresaButtonDisabled: true,
        saveEmpreButtonDisabled: true,
        saveXMLButtonDisabled: true,
        buscarButtonDisabled: true,
        NIFClienteError: false,
        show: false,
        saveButtonDisabled: true,
        showFilters: true,
        toggledClearRows: false,
        cupsError: false,
        NIFError: false,
        locale: 'es',
        inputIdDisabled: true,
        inputDisabled: true,
        usuariosDesde: '',
        clienteHasta: '',
        createdAt: '',
        updatedAt: '',
        periodoValidezHasta: '',
        periodoValidezDesde: '',
        CodigoPostalTitularError: false,
        Apellido1TitularError: false,
        emailError: false,
        NombreTitularError: false,
        DireccionFiscalError: false,
        TelefonoFijoError: false,
        TelefonMovilError: false,
        cnaeAux: "",
        IBANError: false,
        alert: null,
        createResult: [],
        createResult2: [],
        dicMunicipios: [],
        dicMunicipiosList: [],
        errores: {
            codigoPostal: false,
            nif: false
        },
        textErrores: {
            codigoPostal: "INTRODUZCA MUNICIPIO/CODIGO POSTAL",
            nif: "INTRODUZCA NIF/CIF DEL CLIENTE"
        }


    };

    constructor(props) {
        super(props);
        this.store = UserStore;
        this.formRef = React.createRef();
        this.confirmAlertExit = this.confirmAlertExit.bind(this);
        this.logout = this.logout.bind(this);
    }


    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    componentDidMount() {

        const usuario: any = JSON.parse(JSON.stringify(this.state.UserStore['data']))
        const companyNameGlobal = usuario.company;
        const companyIdUser = usuario.companyId;
        const userGroupId = usuario.user_group_id
        const userId = usuario.id
        const esComercializadora = usuario.comercializadora
        const esAgencia = usuario.agencia
        const esAdFincas = usuario.adFincas

        if (userGroupId === 1000) {
            this.setState({
                activeUser: true
            })
        };
        this.setState({
            companyNameGlobal,
            companyIdUser,
            userGroupId,
            userId,
            esComercializadora,
            esAgencia,
            esAdFincas

        });

        this.getMunicipiosLista();
    }

    //////////////////////////////////////////////////////////////////////////////////////7

    async getMunicipiosLista() {

        await client.query({
            query: LIST_DICMUNICIPIOS,
            variables: {}
        })
            .then((result) => {
                let data = result.data.listDicMunicipios;
                this.setState({ dicMunicipios: data })
            });
    };

    fetchItemsMunicipio = async (codigoMunicipio) => {
        const codigoPostal = codigoMunicipio
        await client.query({
            query: LIST_DICMUNICIPIOSPOSTALSHORT,
            variables: { codigoPostal: codigoPostal }
        })
            .then((result) => {
                let data = result.data.listDicMunicipiosPostalShort;
                this.setState({ dicMunicipiosList: data })
            });
    };

    ///////////////////////////////////////////////////////////////////////////////////////////

    logout = async () => {

        removeToken();
        localStorage.removeItem("lastFilters");
        localStorage.removeItem("user");
        localStorage.removeItem("lastFiltersEmpre");
        localStorage.removeItem("lastFiltersMulti");
        localStorage.removeItem('lastFiltersMultiGas');
        localStorage.removeItem('lastFiltersGas');
        this.props.client.cache.reset();
        this.props.client.resetStore();
        this.props.history.push(`/auth/login`);
    };

    //////////////////////////////////////////////////////////////////////////////////////7

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    warningAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Error en la base de datos"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Existe un error en la Base de datos.
                </ReactBSAlert>
            )
        });
    };

    warningAlertNIF = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="No existen registros que cumplan los requisitos"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Revise los requisitos introducidos
                </ReactBSAlert>
            )
        });
    };

    confirmAlertExit = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Vuelva a iniciar sesión"
                    onConfirm={() => this.logout()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Salir"
                    btnSize=""
                    focusConfirmBtn
                >
                    Se ha cerrado la sesión por seguridad. Por favor, vuelva a iniciar sesión
                </ReactBSAlert>
            )
        });
    };

    warningAlertFiltros = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Existen campos obligatorios sin información"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Revise los datos introducidos
                </ReactBSAlert>
            )
        });
    };

    confirmAlertUpdate = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="¿Desea actualizar los datos de la empresa?"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.onUpdate()}
                    showCancel
                    confirmBtnBsStyle="secondary"
                    confirmBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    cancelBtnText="Actualizar"
                    btnSize=""
                >
                </ReactBSAlert>
            )
        });
    };

    successAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Correcto"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Empresa actualizada correctamente
                </ReactBSAlert>
            )
        });
    };


    successAlertLogo = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Correcto"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Logo actualizado correctamente
                </ReactBSAlert>
            )
        });
    };

    warningFicheroGrande = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Fichero seleccionado supera el lìmite de tamaño"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    El fichero seleccionado supera el límite de tamaño
                </ReactBSAlert>
            )
        });
    };


    warningFicheroPermitido = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Fichero seleccionado no permitido"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Solo se permiten las siguientes extensiones: jpg, jpeg, png
                </ReactBSAlert>
            )
        });
    };

    errorDB = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Existe un problema con el servidor"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Existe un problema con el servidor. Si persite este error, por favor, contacte con el área de soporte
                </ReactBSAlert>
            )
        });
    };

    //////////////////////////////////////////////////////////////////////////////////////

    onChangeFieldSearch = (event) => {
        event.persist()
        var filtersData = { ...this.state.filters };
        const name = event.target.name;
        let value = event.target.value;
        const filters = this.state.filters;

        var filtersData = { ...this.state.filters };
        filters[name] = value;
        /*if (event.target.type === "select-one" && value === "0") {
           delete filters[name];
         }

         if (value === "" || value === null) {
           delete filters[name];
         }
*/

        if (event.target.name === 'apellidosUsuariosSearch' && event.target.value.length == 0) {
            this.setState({
                cupsError: false,
                buscarButtonDisabled: true,
                inputIdDisabled: false
            })
        } else if (event.target.name === 'apellidosUsuariosSearch' && event.target.value.length > 0) {
            this.setState({
                inputIdDisabled: true,
                buscarButtonDisabled: false
            })
        };


        if (event.target.name === 'NIFSearch' && event.target.value.length == 0) {
            this.setState({
                NIFError: false,
                buscarButtonDisabled: true,
            })
        } else if (event.target.name === 'NIFSearch' && event.target.value.length > 0) {

            const result = validateSpanishId(event.target.value)

            if (result === true) {
                this.setState({
                    NIFError: false,
                    buscarButtonDisabled: false,
                })
            } else {
                this.setState({
                    NIFError: true,
                    buscarButtonDisabled: true,
                })
            }
        }

        if (event.target.name === 'tipoEstadoSearch' && event.target.value == 'Selecciona...') {
            value = null
            this.setState({
                buscarButtonDisabled: true
            })
        } else if (event.target.name === 'tipoEstadoSearch' && event.target.value !== 'Selecciona...') {
            this.setState({
                buscarButtonDisabled: false
            })
        };

        filtersData[name] = value;
        //if (value === "" || value === null) {
        //    formData[name] = 0;
        //}
        this.setState({ filters: filtersData });
    };

    ////////////////////////////////////////////////////////////////////////////////////////

    onChangeFieldUsuario = (event) => {
        event.persist()
        let errorsData = { ...this.state.errores }
        let textErroresData = { ...this.state.textErrores }

        var formData = { ...this.state.formEmpresa };
        var target = event.target;
        var value =
            event.target.type === "checkbox"
                ? event.target.checked
                : event.target.value;
        const name = event.target.name;

        console.log('valor de formData: ', formData)
        console.log('valor de target: ', target)
        console.log('valor de value: ', value)

        const nameFilters = ['idEmpresa', 'nif', 'tipo', 'tipoServicio', 'numOrden', 'empresa', 'direccion', 'codigoPostal', 'municipio', 'provincia', 'telefonoAtt', 'telefonoUrgencias', 'ambito', 'fechaAlta', 'fechaBaja', 'web', 'estado', 'correoContacto', 'nombreContacto', 'telefonoContacto', 'fechAltaDatos', 'usuario']
        const optionsFilters = ['user_group_id', 'esActiva', 'esAdmin', 'esComercial', 'esConsultor', 'esFuncionario', 'informes', 'detalles'];


        if (this.state.empresaSeleccionado) {

            if (nameFilters.indexOf(event.target.name) != -1) {
                this.setState({ saveDataEmpresa: true });
                this.setState({
                    saveEmpreButtonDisabled: false,
                    updateEmpresaButtonDisabled: false
                })
            }
            if (event.target.name == 'empresa' && event.target.value.length > 0) {

                this.setState({ saveDataEmpresa: true });
                this.setState({
                    saveEmpreButtonDisabled: false,
                    updateEmpresaButtonDisabled: false
                })
            }

            if (event.target.name === 'correoContacto') {
                const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
                //const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
                const result = pattern.test(event.target.value);
                if (result === true) {
                    this.setState({
                        emailError: false,
                    })
                } else {
                    this.setState({
                        emailError: true
                    })
                }
            };

            if (optionsFilters.indexOf(event.target.name) != -1 && event.target.value == 'Selecciona...') {
                value = null
            };

            if (value === "true" || value == "false") {
                value = JSON.parse(value);
            };

            if (name == "nif" && (!value || value.length == 0 || value == "")) {
                errorsData['nif'] = false
                textErroresData['nif'] = "INTRODUZCA NIF/CIF DEL CLIENTE"
            } else if (name == "nif" && (value || value.length > 0 || value !== "")) {
                const result = validateSpanishId(event.target.value)
                if (result === true) {
                    const resultTipo = spainIdType(event.target.value)
                    //formData['tipoTitular'] = resultTipo.toUpperCase();
                    errorsData['nif'] = true
                    textErroresData['nif'] = ""
                    this.setState({
                        isDisabledCIF: false
                    })
                } else {
                    errorsData['nif'] = false
                    textErroresData['nif'] = "NIF/CIF INCORRECTO"
                    this.setState({
                        isDisabledCIF: true
                    })
                }
            };

            if (name == 'municipio') {
                const valueArray = event.target.value.split("*");
                value = valueArray[1]
                let codigoMunicipio = valueArray[0]
                const municipio: any = this.state.dicMunicipios
                const resultado = municipio.find(municipio => municipio.Cmunicipio === codigoMunicipio);
                //const filtered = municipio.filter(entry => Object.values(entry).some(val => typeof val === "string" && val.includes('Barromán')));
                const valorEntidad = resultado.Cmunicipio
                formData['provincia'] = resultado.DescProvincia
                formData['autonomia'] = resultado.DescAutonomia
                this.fetchItemsMunicipio(valorEntidad);
                formData['codigoPostal'] = 'Selecciona CP'
            };

            formData[name] = value;

            this.setState({
                saveDataEmpresa: true
            })
            this.setState({ formEmpresa: formData });
        };
    };

    ///////////////////////////////////////////////////////////////////////////////////////

    showFiltersToggle = () => {
        const showFilters = !this.state.showFilters;

        this.setState({ showFilters });
    };

    //////////////////////////////////////////////////////////////////////////////////////

    getFilterIcon = () => {
        return this.state.showFilters ? "ni-bold-up" : "ni-bold-down";
    };

    ////////////////////////////////////////////////////////////////////////////////////

    clearForm = () => {
        this.setState({
            filters: {
                apellidosUsuariosSearch: "",
                NIFSearch: "",
                tipoEstadoSearch: "",
                usuariosDesde: ""
            },
            inputIdDisabled: false,
            inputDisabled: false,
            buscarButtonDisabled: true,
            empresaSeleccionado: false,
            empresaDetailTotal: [],
            passwordModificado: false,
            formEmpresa: {
                id: "",
                idEmpresa: "",
                nif: "",
                tipo: "",
                tipoServicio: "",
                numOrden: "SIN ORDEN",
                empresa: "",
                direccion: "",
                codigoPostal: "",
                municipio: "",
                provincia: "",
                telefonoAtt: "",
                telefonoUrgencias: "",
                ambito: "",
                fechaAlta: "",
                fechaBaja: "",
                web: "",
                estado: "a",
                correoContacto: "",
                nombreContacto: "",
                telefonoContacto: "",
                fechAltaDatos: "",
                usuario: "",
                logoApp: "https://datapi.psgestion.es/files/images/dynargy_logo_verde_small.png",
                logoPropuesta: "https://datapi.psgestion.es/files/images/dynargy_logo_verde_small.png",
                licencias: 1,
                autonomia: "",
                idAlianza: ""
            },
            toggledClearRows: !this.state.toggledClearRows,
            updateEmpresaButtonDisabled: true,
            saveEmpreButtonDisabled: true,
            createdAt: '',
            updatedAt: '',
            saveDataEmpresa: false,
            errores: {
                codigoPostal: false,
                nif: false
            },
            textErrores: {
                codigoPostal: "INTRODUZCA MUNICIPIO/CODIGO POSTAL",
                nif: "INTRODUZCA NIF/CIF DEL CLIENTE"
            }
        });

        this.formRef.current.reset();

    };

    //////////////////////////////////////////////////////////////////////////////////////

    buscarEmpresa = async () => {
        this.setState({
            isLoading: true,
        })

        const parsed = await JSON.parse(await localStorage.getItem("user")!);
        const userId = parsed.id;
        const companyIdUser = Number(parsed.companyId);


        var dataFilters = {
            'empresa': this.state.filters.apellidosUsuariosSearch?.toUpperCase() || "",
            'nif': this.state.filters.NIFSearch?.toUpperCase() || "",
            'tipoServicio': this.state.filters.tipoEstadoSearch.toUpperCase() || ""
        };

        const valores = JSON.stringify(dataFilters)

        ///// para GET REST //////

        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };
        //let direccionFile = "https://datapi.psgestion.es/cliente/create?id="+this.state.companyIdUser+"&user="+userId;

        let direccionFile = "https://datapi.psgestion.es/empresas/listadoempresa/admin?id=" + companyIdUser + "&user=" + userId;

        await axios.get(direccionFile, { headers, params: { valores } }).
            then((result) => {
                //console.log(JSON.stringify(result.data));
                //console.log('resultado del listados')
                if (result.data.rows.length > 0) {
                    let data = result.data.rows
                    this.setState({
                        empresaDetailTotal: result.data.rows,
                        inputDisabled: false,
                        empresaSeleccionado: false,
                        saveDataEmpresa: false,
                        saveEmpreButtonDisabled: true,
                        updateEmpresaButtonDisabled: true,
                        passwordModificado: false,
                        createdAt: '',
                        updatedAt: '',
                        toggledClearRows: !this.state.toggledClearRows,
                        errores: {
                            codigoPostal: false,
                            nif: false
                        },
                        textErrores: {
                            codigoPostal: "INTRODUZCA MUNICIPIO/CODIGO POSTAL",
                            nif: "INTRODUZCA NIF/CIF DEL CLIENTE"
                        },
                        formEmpresa: {
                            id: "",
                            idEmpresa: "",
                            nif: "",
                            tipo: "",
                            tipoServicio: "",
                            numOrden: "SIN ORDEN",
                            empresa: "",
                            direccion: "",
                            codigoPostal: "",
                            municipio: "",
                            provincia: "",
                            telefonoAtt: "",
                            telefonoUrgencias: "",
                            ambito: "",
                            fechaAlta: "",
                            fechaBaja: "",
                            web: "",
                            estado: "a",
                            correoContacto: "",
                            nombreContacto: "",
                            telefonoContacto: "",
                            fechAltaDatos: "",
                            usuario: "",
                            logoApp: "https://datapi.psgestion.es/files/images/dynargy_logo_verde_small.png",
                            logoPropuesta: "https://datapi.psgestion.es/files/images/dynargy_logo_verde_small.png",
                            licencias: 1,
                            autonomia: "",
                            idAlianza: ""
                        }
                    });

                } else {
                    this.warningAlertNIF()
                };
            }).catch((error) => {
                this.errorDB()
                this.setState({
                    isLoading: false,
                    inputDisabled: false,
                    empresaSeleccionado: false,
                    empresaDetailTotal: [],
                    saveDataEmpresa: false,
                    saveEmpreButtonDisabled: true,
                    updateEmpresaButtonDisabled: true,
                    passwordModificado: false,
                    createdAt: '',
                    updatedAt: '',
                    toggledClearRows: !this.state.toggledClearRows,
                    errores: {
                        codigoPostal: false,
                        nif: false
                    },
                    textErrores: {
                        codigoPostal: "INTRODUZCA MUNICIPIO/CODIGO POSTAL",
                        nif: "INTRODUZCA NIF/CIF DEL CLIENTE"
                    },
                    formEmpresa: {
                        id: "",
                        idEmpresa: "",
                        nif: "",
                        tipo: "",
                        tipoServicio: "",
                        numOrden: "SIN ORDEN",
                        empresa: "",
                        direccion: "",
                        codigoPostal: "",
                        municipio: "",
                        provincia: "",
                        telefonoAtt: "",
                        telefonoUrgencias: "",
                        ambito: "",
                        fechaAlta: "",
                        fechaBaja: "",
                        web: "",
                        estado: "a",
                        correoContacto: "",
                        nombreContacto: "",
                        telefonoContacto: "",
                        fechAltaDatos: "",
                        usuario: "",
                        logoApp: "https://datapi.psgestion.es/files/images/dynargy_logo_verde_small.png",
                        logoPropuesta: "https://datapi.psgestion.es/files/images/dynargy_logo_verde_small.png",
                        licencias: 1,
                        autonomia: "",
                        idAlianza: ""
                    }
                });
            });
    };

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    updateHandler(props) {

        let existenErrores = Object.keys(this.state.errores).some(k => !this.state.errores[k]);

        if (existenErrores == true) {
            this.confirmAlertUpdate()

        } else {
            this.warningAlertFiltros()
        }
    };

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    onUpdate = async () => {

        const token = localStorage.getItem("token")

        const user: any = localStorage.getItem("user");

        var datosUser: any = []
        if (user) {
            datosUser = JSON.parse(user);
        }

        const parsed = await JSON.parse(await localStorage.getItem("user")!);
        const iduser = parsed.id;
        const companyIdUser = Number(parsed.companyId);

        if (this.state.saveDataEmpresa == true) {

            var empresaDataUpdate = {
                'id': this.state.formEmpresa.id,
                'idEmpresa': this.state.formEmpresa.idEmpresa,
                'nif': this.state.formEmpresa.nif,
                'tipo': this.state.formEmpresa.tipo,
                'tipoServicio': this.state.formEmpresa.tipoServicio,
                'numOrden': this.state.formEmpresa.numOrden,
                'empresa': this.state.formEmpresa.empresa?.toUpperCase() || '',
                'direccion': this.state.formEmpresa.direccion?.toUpperCase() || '',
                'codigoPostal': this.state.formEmpresa.codigoPostal,
                'municipio': this.state.formEmpresa.municipio?.toUpperCase() || '',
                'provincia': this.state.formEmpresa.provincia?.toUpperCase() || '',
                'telefonoAtt': this.state.formEmpresa.telefonoAtt,
                'telefonoUrgencias': this.state.formEmpresa.telefonoUrgencias,
                'ambito': this.state.formEmpresa.ambito?.toUpperCase() || '',
                'fechaAlta': this.state.formEmpresa.fechaAlta,
                'fechaBaja': this.state.formEmpresa.fechaBaja,
                'web': this.state.formEmpresa.web,
                'estado': this.state.formEmpresa.estado,
                'correoContacto': this.state.formEmpresa.correoContacto,
                'nombreContacto': this.state.formEmpresa.nombreContacto?.toUpperCase() || '',
                'telefonoContacto': this.state.formEmpresa.telefonoContacto,
                'fechAltaDatos': this.state.formEmpresa.fechAltaDatos,
                'usuario': iduser,
                'logoApp': this.state.formEmpresa.logoApp,
                'logoPropuesta': this.state.formEmpresa.logoPropuesta,
                'licencias': this.state.formEmpresa.licencias,
                'autonomia': this.state.formEmpresa.autonomia,
                'idAlianza': this.state.formEmpresa.idAlianza
            }

            const headers = {

                'Authorization': 'Bearer my-token',
                'My-Custom-Header': 'foobar',
                'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W',
                'Content-Type': 'application/json',
            };

            let direccionFile = "https://datapi.psgestion.es/empresas/empresaupdate/admin?id=" + companyIdUser + "&user=" + iduser;


            axios.post(direccionFile, empresaDataUpdate, { headers, responseType: 'json' })
                .then((result) => {

                    console.log('Actualización correcta', result)
                    console.log('Datpos del registro: ', result.data)
                    this.successAlert();
                    this.setState({
                        inputDisabled: false,
                        empresaSeleccionado: false,
                        empresaDetailTotal: [],
                        saveDataEmpresa: false,
                        saveEmpreButtonDisabled: true,
                        updateEmpresaButtonDisabled: true,
                        passwordModificado: false,
                        createdAt: '',
                        updatedAt: '',
                        toggledClearRows: !this.state.toggledClearRows,
                        errores: {
                            codigoPostal: false,
                            nif: false
                        },
                        textErrores: {
                            codigoPostal: "INTRODUZCA MUNICIPIO/CODIGO POSTAL",
                            nif: "INTRODUZCA NIF/CIF DEL CLIENTE"
                        },
                        formEmpresa: {
                            id: "",
                            idEmpresa: "",
                            nif: "",
                            tipo: "",
                            tipoServicio: "",
                            numOrden: "SIN ORDEN",
                            empresa: "",
                            direccion: "",
                            codigoPostal: "",
                            municipio: "",
                            provincia: "",
                            telefonoAtt: "",
                            telefonoUrgencias: "",
                            ambito: "",
                            fechaAlta: "",
                            fechaBaja: "",
                            web: "",
                            estado: "a",
                            correoContacto: "",
                            nombreContacto: "",
                            telefonoContacto: "",
                            fechAltaDatos: "",
                            usuario: "",
                            logoApp: "https://datapi.psgestion.es/files/images/dynargy_logo_verde_small.png",
                            logoPropuesta: "https://datapi.psgestion.es/files/images/dynargy_logo_verde_small.png",
                            licencias: 1,
                            autonomia: ""
                        }

                    });

                }).catch((error) => {
                    this.errorDB()
                    this.setState({
                        isLoading: false,
                    });
                });

            await this.buscarEmpresa();
        };
    }



    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    handleChange = state => {
        // eslint-disable-next-line no-console

        if (state.selectedCount === 1) {
            let errorsData = { ...this.state.errores }
            let textErroresData = { ...this.state.textErrores }
            var existeEmpresa = ''
            var empresa = state.selectedRows[0].idEmpresa;
            this.setState({ empresaDetail: state.selectedRows[0] });
            let existeFechaAlta = state.selectedRows[0].createdAt
            let existeFechaBaja = state.selectedRows[0].updatedAt
            existeEmpresa = state.selectedRows[0].idEmpresa

            if (existeFechaAlta && existeFechaBaja) {

                this.setState({
                    inputDisabled: true,
                    updateEmpresaButtonDisabled: false
                })
            } else if (!existeFechaAlta && !existeFechaBaja) {
                this.setState({
                    inputDisabled: false,
                    updateEmpresaButtonDisabled: true
                })
            }



            if (existeEmpresa) {
                this.setState({ id: this.state.empresaDetail.id });

                if (state.selectedRows[0].nif) {
                    const result = validateSpanishId(state.selectedRows[0].nif)
                    if (result === true) {
                        errorsData['nif'] = true
                        textErroresData['nif'] = ""
                    } else {
                        errorsData['nif'] = false
                        textErroresData['nif'] = "NIF/CIF ERRONEO"
                    }
                };

                if (!state.selectedRows[0].codigoPostal || state.selectedRows[0].codigoPostal.length == 0 || state.selectedRows[0].codigoPostal == "" || state.selectedRows[0].codigoPostal == "00000") {
                    errorsData['codigoPostal'] = false
                    textErroresData['codigoPostal'] = "SIN DATOS"
                } else if (state.selectedRows[0].codigoPostal || state.selectedRows[0].codigoPostal.length > 0 || state.selectedRows[0].codigoPostal != "" || state.selectedRows[0].codigoPostal != "00000") {
                    errorsData['codigoPostal'] = true
                    textErroresData['codigoPostal'] = ""
                };



                this.setState({
                    formEmpresa: {
                        id: state.selectedRows[0].id,
                        idEmpresa: state.selectedRows[0].idEmpresa,
                        nif: state.selectedRows[0].nif,
                        tipo: state.selectedRows[0].tipo,
                        tipoServicio: state.selectedRows[0].tipoServicio,
                        numOrden: state.selectedRows[0].numOrden,
                        empresa: state.selectedRows[0].empresa?.toUpperCase() || '',
                        direccion: state.selectedRows[0].direccion?.toUpperCase() || '',
                        codigoPostal: state.selectedRows[0].codigoPostal,
                        municipio: state.selectedRows[0].municipio?.toUpperCase() || '',
                        provincia: state.selectedRows[0].provincia?.toUpperCase() || '',
                        telefonoAtt: state.selectedRows[0].telefonoAtt,
                        telefonoUrgencias: state.selectedRows[0].telefonoUrgencias,
                        ambito: state.selectedRows[0].ambito?.toUpperCase() || '',
                        fechaAlta: state.selectedRows[0].fechaAlta,
                        fechaBaja: state.selectedRows[0].fechaBaja,
                        web: state.selectedRows[0].web,
                        estado: state.selectedRows[0].estado,
                        correoContacto: state.selectedRows[0].correoContacto,
                        nombreContacto: state.selectedRows[0].nombreContacto?.toUpperCase() || '',
                        telefonoContacto: state.selectedRows[0].telefonoContacto,
                        fechAltaDatos: state.selectedRows[0].fechAltaDatos,
                        usuario: state.selectedRows[0].usuario,
                        logoApp: state.selectedRows[0].logoApp,
                        logoPropuesta: state.selectedRows[0].logoPropuesta,
                        licencias: state.selectedRows[0].licencias,
                        autonomia: state.selectedRows[0].autonomia,
                        idAlianza: state.selectedRows[0].idAlianza
                    },
                    empresaSeleccionado: true,
                    inputDisabled: false,
                    createdAt: state.selectedRows[0].createdAt,
                    errores: errorsData,
                    textErrores: textErroresData

                });

            } else {
                this.setState({
                    empresaSeleccionado: false,
                    formEmpresa: {
                        id: "",
                        idEmpresa: "",
                        nif: "",
                        tipo: "",
                        tipoServicio: "",
                        numOrden: "SIN ORDEN",
                        empresa: "",
                        direccion: "",
                        codigoPostal: "",
                        municipio: "",
                        provincia: "",
                        telefonoAtt: "",
                        telefonoUrgencias: "",
                        ambito: "",
                        fechaAlta: "",
                        fechaBaja: "",
                        web: "",
                        estado: "a",
                        correoContacto: "",
                        nombreContacto: "",
                        telefonoContacto: "",
                        fechAltaDatos: "",
                        usuario: "",
                        logoApp: "https://datapi.psgestion.es/files/images/dynargy_logo_verde_small.png",
                        logoPropuesta: "https://datapi.psgestion.es/files/images/dynargy_logo_verde_small.png",
                        licencias: 1,
                        autonomia: "",
                        idAlianza: ""
                    },
                    createdAt: null,
                    updatedAt: null,
                    passwordModificado: false,
                    errores: {
                        codigoPostal: false,
                        nif: false
                    },
                    textErrores: {
                        codigoPostal: "INTRODUZCA MUNICIPIO/CODIGO POSTAL",
                        nif: "INTRODUZCA NIF/CIF DEL CLIENTE"
                    }
                });
            }
        } else {

            this.setState({
                empresaSeleccionado: false,
                inputDisabled: false,
                updateEmpresaButtonDisabled: true,
                saveEmpreButtonDisabled: true,
                passwordModificado: false,
                createdAt: null,
                updatedAt: null,
                formEmpresa: {
                    id: "",
                    idEmpresa: "",
                    nif: "",
                    tipo: "",
                    tipoServicio: "",
                    numOrden: "SIN ORDEN",
                    empresa: "",
                    direccion: "",
                    codigoPostal: "",
                    municipio: "",
                    provincia: "",
                    telefonoAtt: "",
                    telefonoUrgencias: "",
                    ambito: "",
                    fechaAlta: "",
                    fechaBaja: "",
                    web: "",
                    estado: "a",
                    correoContacto: "",
                    nombreContacto: "",
                    telefonoContacto: "",
                    fechAltaDatos: "",
                    usuario: "",
                    logoApp: "https://datapi.psgestion.es/files/images/dynargy_logo_verde_small.png",
                    logoPropuesta: "https://datapi.psgestion.es/files/images/dynargy_logo_verde_small.png",
                    licencias: 1,
                    autonomia: "",
                    idAlianza: "",
                },
                errores: {
                    codigoPostal: false,
                    nif: false
                },
                textErrores: {
                    codigoPostal: "INTRODUZCA MUNICIPIO/CODIGO POSTAL",
                    nif: "INTRODUZCA NIF/CIF DEL CLIENTE"
                }
            });
        };
    };



    handleClearRows = () => {
        this.setState({ toggledClearRows: !this.state.toggledClearRows })
    };


    ////////////////////////////////////// GESTION DE IMAGENES ////////////////////////

    ///////////////////////////////////////////////////////////////////////////////////

    validateFile(file) {
        const extension = file.name.split(".").pop() || "";
        // Get length of file in bytes
        const fileSizeInBytes = file.size;
        // Convert the bytes to Kilobytes (1 KB = 1024 Bytes)
        const fileSizeInKB = fileSizeInBytes / 1024;
        // Convert the KB to MegaBytes (1 MB = 1024 KBytes)
        const fileSizeInMB = fileSizeInKB / 1024;
        if (!this.validFileExtensions.includes(extension)) {
            const extensionsAllowed = this.validFileExtensions.join(", ");
            this.warningFicheroPermitido()
            return false;
        }
        if (fileSizeInMB > this.maxFileSize) {
            this.warningFicheroGrande()
            return false;
        }

        return true;

        // onChangeFile(file);
    }

    // On App Logo Change

    onAppLogoChange = (e) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];
        let file2 = e.target.files

        if (this.validateFile(file)) {

            var formData = new FormData();

            formData.append('file', file)

            const userID: any = localStorage.getItem("uuidUser") ? localStorage.getItem("uuidUser") : ""
            const userCompany: any = localStorage.getItem("companyId") ? localStorage.getItem("companyId") : ""

            //var user: any = [];

            const datosUsuario = JSON.parse(JSON.stringify(this.state.UserStore['data']))

            const user: any = localStorage.getItem("user");

            var datosUser: any = []


            const headers = {
                'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W',
                "Content-Type": "multipart/form-data"
            };
            let direccionFile = "https://datapi.psgestion.es/files/upload/logo/empresa?id=" + this.state.companyIdUser + "&user=" + this.state.userId;


            axios.post(direccionFile, formData, { headers }
            ).then(result => {
                let direccionImagen = result.data.resultado
                var formData = { ...this.state.formEmpresa };

                formData['logoApp'] = direccionImagen

                this.setState({
                    isLoading: false,
                    formEmpresa: formData
                })

                this.successAlertLogo()
                // UserStoreActions.update(result.data);

            }).catch((error) => {
                console.log('Failure: ', error)
                this.errorDB()
                this.setState({
                    isLoading: false,
                });

            });
            reader.readAsDataURL(file);
        }
    };

    onAppLogoChangePropuesta = (e) => {
        e.preventDefault();

        let reader = new FileReader();
        let file = e.target.files[0];
        let file2 = e.target.files

        if (this.validateFile(file)) {

            var formData = new FormData();

            formData.append('file', file)

            const userID: any = localStorage.getItem("uuidUser") ? localStorage.getItem("uuidUser") : ""
            const userCompany: any = localStorage.getItem("companyId") ? localStorage.getItem("companyId") : ""

            const datosUsuario = JSON.parse(JSON.stringify(this.state.UserStore['data']))

            const user: any = localStorage.getItem("user");

            var datosUser: any = []

            const headers = {
                'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W',
                "Content-Type": "multipart/form-data"
            };
            let direccionFile = "https://datapi.psgestion.es/files/upload/logo/propuesta?id=" + this.state.companyIdUser + "&user=" + this.state.userId;

            axios.post(direccionFile, formData, { headers }
            ).then(result => {
                let direccionImagen = result.data.resultado
                var formData = { ...this.state.formEmpresa };

                formData['logoPropuesta'] = direccionImagen

                this.setState({
                    isLoading: false,
                    formEmpresa: formData
                })

                this.successAlertLogo()
                // UserStoreActions.update(result.data);

            }).catch((error) => {
                console.log('Failure: ', error)
                this.errorDB()
                this.setState({
                    isLoading: false,
                });

            });
            reader.readAsDataURL(file);
        }
    }
    eventFire = (el, etype) => {
        if (el.fireEvent) {
            el.fireEvent("on" + etype);
        } else {
            var evObj = document.createEvent("Events");
            evObj.initEvent(etype, true, false);
            el.dispatchEvent(evObj);
        }
    };
    onAppLogoButtonClick = () => {
        const input = document.getElementById("label-upload-button");
        this.eventFire(input, "click");
    };

    onAppLogoPropuestaClick = () => {
        const input = document.getElementById("label-upload-propuesta-button");
        this.eventFire(input, "click");
    };

    //////////////////////////////////////////////////////////////////////////////////////////

    render() {

        var formEmpresa = this.state.formEmpresa

        var previewImage = this.state.formEmpresa.logoApp ? this.state.formEmpresa.logoApp : ""
        var previewImagePropuesta = this.state.formEmpresa.logoPropuesta ? this.state.formEmpresa.logoPropuesta : ""

        console.log('VALOR DE THIS.STATE EN RENDER: ', this.state)

        return (
            <>
                <Header title={'Información Empresas'} />
                <Container className=" mt--7" fluid>

                    <Card className="mb-4">
                        <CardHeader>
                            <Button
                                onClick={this.showFiltersToggle}
                                size="sm"
                                className="float-right"
                            >
                                <i className={"ni " + this.getFilterIcon()} />
                            </Button>
                            <h3 className="mb-0">Filtrar</h3>
                        </CardHeader>
                        <Collapse isOpen={this.state.showFilters}>
                            <CardBody>
                                <Form innerRef={this.formRef}>
                                    <Row>
                                        <Col md="12" lg="3">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="clienteRef">
                                                    RAZON SOCIAL/NOMBRE
                                                </Label>
                                                <Input
                                                    id="apellidosUsuariosSearch"
                                                    placeholder="Ingresar Razón Social de la Empresa"
                                                    name="apellidosUsuariosSearch"
                                                    maxLength={80}
                                                    onChange={this.onChangeFieldSearch}
                                                    value={this.state.filters.apellidosUsuariosSearch}
                                                    type="text"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg="2">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="NIFSearch">
                                                    NIF
                                                </Label>
                                                <Input
                                                    id="NIFSearch"
                                                    placeholder="Ingresar CIF empresa"
                                                    name="NIFSearch"
                                                    maxLength={16}
                                                    onChange={this.onChangeFieldSearch}
                                                    value={this.state.filters.NIFSearch}
                                                    type="text"

                                                />
                                                {this.state.NIFError ? <FormText color="red">Comprobar CIF</FormText> : ''}
                                            </FormGroup>
                                        </Col>

                                        <Col md="2" lg="2">
                                            <FormGroup>
                                                <Label className="form-control-label" for="tipoEstadoProducto">ESTADO</Label>
                                                <Input type="select" name="tipoEstadoSearch" id="tipoEstadoSearch"
                                                    onChange={this.onChangeFieldSearch}
                                                    value={this.state.filters.tipoEstadoSearch}>
                                                    <option selected>Selecciona...</option>
                                                    <option value="ELEC">ELECTRICIDAD</option>
                                                    <option value="GAS">GAS</option>
                                                    <option value="ELEGAS">AMBOS</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>

                                        <Col className="text-right">
                                            <Button onClick={this.clearForm}>
                                                <i className={"ni ni-fat-remove"} /> Limpiar
                                            </Button>
                                            <Button
                                                color="primary"
                                                onClick={this.buscarEmpresa}
                                                disabled={this.state.buscarButtonDisabled}
                                            >
                                                <i className={"bi bi-search"} /> Buscar
                                            </Button>
                                        </Col>
                                    </Row>

                                    <Card className="shadow">
                                        <DataTable
                                            title="LISTADO DE EMPRESAS"
                                            columns={columnsDatosEmpresas}
                                            data={this.state.empresaDetailTotal}
                                            selectableRows
                                            selectableRowsComponentProps={{ type: 'radio' }}// optionally, pass Material Ui supported props down to our custom checkbox
                                            selectableRowsSingle
                                            sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you           fixedHeader = {true}
                                            noDataComponent={"Sin datos para mostrar"}
                                            pagination
                                            paginationPerPage={10}
                                            paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 40]}
                                            onSelectedRowsChange={this.handleChange}
                                            clearSelectedRows={this.state.toggledClearRows}
                                            highlightOnHover
                                            striped
                                            pointerOnHover
                                            selectableRowsHighlight
                                            customStyles={customStyles}
                                            contextMessage={{ singular: 'empresa', plural: 'empresa', message: 'seleccionada' }}
                                            subHeader
                                            subHeaderComponent={

                                                <div style={{ display: 'flex', alignItems: 'center' }}>

                                                    <CSVLink
                                                        data={this.state.empresaDetailTotal}
                                                        filename={"empresasRegistradas.csv"}
                                                        className="btn btn-default btn-sm btn-circle"
                                                        color="default"
                                                        target="_blank"
                                                        separator={";"}
                                                    >

                                                        <span className="btn-inner--icon">
                                                            <i className={"bi bi-file-earmark-spreadsheet"} style={{ fontSize: 25 }}></i>
                                                        </span>
                                                        <span className="btn-inner--text">Fichero CSV</span>

                                                    </CSVLink>
                                                </div>
                                            }
                                            progressComponent={<Circular />}
                                        />
                                    </Card>
                                </Form>
                            </CardBody>
                        </Collapse>
                    </Card>




                    <Row>
                        <div className="col">
                            <Card className="shadow">
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <button
                                                className="btn btn-sm btn-primary"
                                                onClick={this.props.history.goBack}
                                            >
                                                Atrás
                                            </button>
                                        </Col>

                                        <Col className="col-auto d-flex">
                                            <Card className="p-3 bg-light">
                                                <FormGroup>

                                                    <Button disabled={this.state.updateEmpresaButtonDisabled} id="UpdateRepreProptooltip" color="danger" size="m" type="button" className="btn-icon btn-sm btn-circle"
                                                        onClick={() => { this.updateHandler(this.state.saveDataEmpresa) }}
                                                    >
                                                        <span className="btn-inner--icon">
                                                            <i className="bi bi-person-lines-fill" style={{ fontSize: 30 }}></i>
                                                        </span>
                                                        <span className="btn-inner--text">Actualizar</span>
                                                        <UncontrolledTooltip delay={0} placement="top" target="UpdateRepreProptooltip">
                                                            MODIFICAR EMPRESA EXISTENTE
                                                        </UncontrolledTooltip>
                                                    </Button>

                                                    <Link
                                                        color="info" type="button" className="btn btn-primary btn-sm btn-circle"
                                                        data-toggle="modal"
                                                        to={{ pathname: "/admin/nuevaEmpresa" }}
                                                        id="newElecProduct"
                                                    >
                                                        <span className="btn-inner--icon">
                                                            <i className="bi bi-person-plus-fill" style={{ fontSize: 30 }}></i>
                                                        </span>
                                                        <span className="btn-inner--text">Nuevo</span>

                                                        <UncontrolledTooltip delay={0} placement="top" target="newElecProduct">
                                                            CREAR NUEVA EMPRESA
                                                        </UncontrolledTooltip>
                                                    </Link>

                                                </FormGroup>

                                            </Card>
                                        </Col>

                                    </Row>
                                    <div>
                                        <CardTitle>DATOS GENERALES EMPRESA</CardTitle>
                                    </div>

                                    <Row>
                                        <Col>
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="id">
                                                    ID
                                                </Label>
                                                <Input
                                                    className="text-primary"
                                                    id="id"
                                                    placeholder=""
                                                    name="nombre"
                                                    maxLength={100}
                                                    onChange={this.onChangeFieldUsuario}
                                                    value={formEmpresa.id}
                                                    type="text"
                                                    disabled={true}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="empresa">
                                                    Razón Social
                                                </Label>
                                                <Input
                                                    className="text-primary"
                                                    id="empresa"
                                                    placeholder=""
                                                    name="empresa"
                                                    maxLength={100}
                                                    onChange={this.onChangeFieldUsuario}
                                                    value={formEmpresa.empresa}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="numOrden">
                                                    N Orden
                                                </Label>
                                                <Input
                                                    className="text-primary"
                                                    id="numOrden"
                                                    placeholder=""
                                                    name="numOrden"
                                                    maxLength={10}
                                                    onChange={this.onChangeFieldUsuario}
                                                    value={formEmpresa.numOrden}
                                                    type="text"
                                                    disabled={true}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col>
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="nif">
                                                    CIF
                                                </Label>
                                                <Input
                                                    id="nif"
                                                    placeholder=""
                                                    name="nif"
                                                    maxLength={16}
                                                    onChange={this.onChangeFieldUsuario}
                                                    value={formEmpresa.nif}
                                                    type="text"
                                                    invalid={this.state.errores.nif == false}
                                                />
                                                <FormFeedback>{this.state.textErrores.nif}</FormFeedback>
                                            </FormGroup>
                                        </Col>

                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="direccion">
                                                    Dirección
                                                </Label>
                                                <Input
                                                    id="direccion"
                                                    placeholder=""
                                                    name="direccion"
                                                    maxLength={150}
                                                    onChange={this.onChangeFieldUsuario}
                                                    value={formEmpresa.direccion}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="3">
                                            <Label className="form-control-label" htmlFor="codigoPostal">
                                                C.P.
                                            </Label>
                                            <Input type="select" value={formEmpresa.codigoPostal} name="codigoPostal" id="codigoPostal" invalid={this.state.errores.codigoPostal == false} onChange={this.onChangeFieldUsuario}>
                                                <option value={formEmpresa.codigoPostal} disabled>{formEmpresa.codigoPostal}</option>
                                                {this.state.dicMunicipiosList.map((item: any, key) => (
                                                    <option key={key} value={item.codigoPostal}>{item.codigoPostal} - {item.entidadsingularnombre}</option>
                                                ))
                                                }
                                            </Input>
                                            <FormFeedback>{this.state.textErrores.codigoPostal}</FormFeedback>
                                        </Col>


                                        <Col md="3">
                                            <Label className="form-control-label" htmlFor="nombreMunicipio">
                                                Municipio
                                            </Label>
                                            <Input type="select" value={formEmpresa.municipio} name="municipio" id="municipio" onChange={this.onChangeFieldUsuario}>
                                                <option value={formEmpresa.municipio} disabled>{formEmpresa.municipio}</option>
                                                {this.state.dicMunicipios.map((item: any, key) => (
                                                    <option key={key} value={item.Cmunicipio + "*" + item.Nombre}>{item.Nombre}</option>
                                                ))
                                                }
                                            </Input>
                                        </Col>
                                        <Col>
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="provincia">
                                                    Provincia
                                                </Label>
                                                <Input
                                                    id="provincia"
                                                    placeholder=""
                                                    name="provincia"
                                                    maxLength={150}
                                                    onChange={this.onChangeFieldUsuario}
                                                    value={formEmpresa.provincia}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col lg="auto">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="autonomia">
                                                    Autonomia
                                                </Label>
                                                <Input
                                                    id="autonomia"
                                                    placeholder=""
                                                    name="autonomia"
                                                    maxLength={150}
                                                    onChange={this.onChangeFieldUsuario}
                                                    value={formEmpresa.autonomia}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>

                                    </Row>



                                    <Row>
                                        <Col md="2">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="nombreContacto">
                                                    Nombre Contacto
                                                </Label>
                                                <Input
                                                    className="text-primary"
                                                    id="nombreContacto"
                                                    placeholder=""
                                                    name="nombreContacto"
                                                    maxLength={80}
                                                    onChange={this.onChangeFieldUsuario}
                                                    value={formEmpresa.nombreContacto}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>


                                        <Col md="2">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="telefonoContacto">
                                                    Teléfono Contacto
                                                </Label>
                                                <Input
                                                    className="text-primary"
                                                    id="telefonoContacto"
                                                    placeholder=""
                                                    name="telefonoContacto"
                                                    maxLength={20}
                                                    onChange={this.onChangeFieldUsuario}
                                                    value={formEmpresa.telefonoContacto}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="3">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="correoContacto">
                                                    Correo Contacto
                                                </Label>
                                                <Input
                                                    id="correoContacto"
                                                    placeholder=""
                                                    name="correoContacto"
                                                    maxLength={80}
                                                    onChange={this.onChangeFieldUsuario}
                                                    value={formEmpresa.correoContacto}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}

                                                />
                                            </FormGroup>
                                        </Col>

                                    </Row>
                                </CardBody>
                            </Card>

                            <Card className="shadow">
                                <CardBody>
                                    <div>
                                        <CardTitle>LOGOS</CardTitle>
                                    </div>

                                    <Row>

                                        <Col md="3" lg="3">
                                            <FormGroup>
                                                <Label className="form-control-label" for="tipo">Logo Empresa</Label>
                                                {previewImage && (
                                                    <div>
                                                        <img className="preview" src={previewImage} alt="" />
                                                    </div>
                                                )}
                                                <FormGroup>
                                                    <label htmlFor="upload-button" id="label-upload-button">
                                                        <span className="btn btn-sm btn-primary mt-3">
                                                            Subir logo
                                                        </span>
                                                    </label>
                                                    <input
                                                        type="file"
                                                        id="upload-button"
                                                        style={{ display: "none" }}
                                                        onChange={this.onAppLogoChange}
                                                        accept="image/*"
                                                    />
                                                </FormGroup>

                                            </FormGroup>
                                        </Col>



                                        <Col md="3" lg="3">
                                            <FormGroup>
                                                <Label className="form-control-label" for="tipo">Logo Propuesta</Label>
                                                {previewImagePropuesta && (
                                                    <div>
                                                        <img className="preview" src={previewImagePropuesta} alt="" />
                                                    </div>
                                                )}
                                                <FormGroup>
                                                    <label htmlFor="upload-button-propuesta" id="label-upload-propuesta-button">
                                                        <span className="btn btn-sm btn-primary mt-3">
                                                            Subir logo
                                                        </span>
                                                    </label>
                                                    <input
                                                        type="file"
                                                        id="upload-button-propuesta"
                                                        style={{ display: "none" }}
                                                        onChange={this.onAppLogoChangePropuesta}
                                                        accept="image/*"
                                                    />
                                                </FormGroup>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>


                            <Card className="shadow">
                                <CardBody>
                                    <div>
                                        <CardTitle>OTROS DATOS</CardTitle>
                                    </div>

                                    <Row>
                                        <Col md="2" lg="2">
                                            <FormGroup>
                                                <Label className="form-control-label" for="tipo">Tipo Empresa</Label>
                                                <Input type="select" name="tipo" id="tipo"
                                                    disabled={this.state.inputDisabled}
                                                    onChange={this.onChangeFieldUsuario}
                                                    value={formEmpresa.tipo}>
                                                    <option selected>Selecciona...</option>
                                                    <option value="COM"> Comercializadora</option>
                                                    <option value="DIS"> Distribuidora</option>
                                                    <option value="AGN"> Agencia</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>

                                        <Col md="2" lg="2">
                                            <FormGroup>
                                                <Label className="form-control-label" for="tipoverde">Tipo Servicio</Label>
                                                <Input type="select" name="tipoServicio" id="tipoServicio"
                                                    disabled={this.state.inputDisabled}
                                                    onChange={this.onChangeFieldUsuario}
                                                    value={formEmpresa.tipoServicio}>
                                                    <option selected>Selecciona...</option>
                                                    <option value="ELEC">Electricidad</option>
                                                    <option value="GAS">Gas</option>
                                                    <option value="ELEGAS">Ambos</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>

                                        <Col lg="auto">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="licencias">
                                                    Licencias
                                                </Label>
                                                <Input
                                                    id="licencias"
                                                    placeholder=""
                                                    name="licencias"
                                                    maxLength={3}
                                                    onChange={this.onChangeFieldUsuario}
                                                    value={formEmpresa.licencias}
                                                    type="number"
                                                    disabled={this.state.inputDisabled}

                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                            {this.state.alert}

                        </div>
                    </Row>
                </Container>

            </>
        );
    }
};
export default withApollo(actualizarEmpresaEnergia);
