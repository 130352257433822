import React from "react";
import Reflux from "reflux";
import axios from 'axios';
import { removeToken } from "../../utils";
import { MyCommandCell } from "./myCommandCell";
import { validateSpanishId, spainIdType } from 'spain-id';
import {
    Card, Container, Row, CardTitle, CardBody, Label,
    FormGroup,
    Input, FormText,
    Form, Col, Button,
    UncontrolledTooltip, CardHeader, Collapse, FormFeedback
} from "reactstrap";
import {
    Link
} from 'react-router-dom';
import { CSVLink } from "react-csv";

import { Multiselect } from "multiselect-react-dropdown";

import {
    Grid,
    GridColumn as Column,
    GridToolbar,
} from "@progress/kendo-react-grid";

// core components
import Header from "../../components/Headers/Header";
import { withApollo } from "react-apollo";
import moment from "moment";
import 'moment/locale/es'
import ReactBSAlert from "react-bootstrap-sweetalert";

import { UserStore } from "../../store/UserStore";
import DataTable from 'react-data-table-component';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import CircularProgress from '@material-ui/core/CircularProgress';

var DatePicker = require("reactstrap-date-picker");

const spanishDayLabels = ['Dom', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sab'];
const spanishMonthLabels = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
const sortIcon = <ArrowDownward />;
const estado = (s) => {
    if (s !== true) return <i className="fas fa-exclamation-circle fa-2x" style={{ color: "#ff1900" }} ></i>
    return <i className="far fa-check-circle fa-2x" style={{ color: "#7ee06e" }} ></i>
}

const columnsDatosEmpresas = [
    { selector: row => row.id, name: 'NIF', sortable: true, width: '150px', format: (row => capitalize(row.nif)) },
    { selector: row => row.empresa, name: 'EMPRESA', sortable: true, width: '240px', center: true, format: (row => capitalize(row.empresa)) },
    { selector: row => row.numOrden, name: 'ORDEN', sortable: true, width: '100px', center: true, format: (row => capitalize(row.numOrden)) },
    { selector: row => row.correoContacto, name: 'CORREO', sortable: true, width: '150px', center: true, format: (row => capitalize(row.correoContacto)) },
    { selector: row => row.nombreContacto, name: 'CONTACTO', sortable: true, width: '140px', center: true, format: (row => capitalize(row.nombreContacto)) },
    { selector: row => row.telefonoContacto, name: 'TELEFONO', sortable: true, width: '140px', center: true, format: (row => capitalize(row.telefonoContacto)) }
];


const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.toLowerCase().charAt(0).toUpperCase() + s.slice(1)
}

const customStyles = {
    headRow: {
        style: {
            border: 'none',

        },
    },
    headCells: {
        style: {
            color: '#202124',
            fontSize: '12px',
            fontWeight: 'bold',
            wordBreak: 'break-word',
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px'

        },
    },
    cells: {
        style: {
            paddingLeft: '8px',
            paddingRight: '8px',
            minWidth: '55px',
            wordBreak: 'break-word',
            fontFamily: '"Open Sans", sans-serif;'
        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: 'rgb(230, 250, 250)',
            borderBottomColor: '#FFFFFF',
            borderRadius: '25px',
            outline: '1px solid #FFFFFF',
            fontSize: '12px',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};


const Circular = () => (
    // we need to add some padding to circular progress to keep it from activating our scrollbar
    <div style={{ padding: '24px' }}>
        <CircularProgress size={75} />
    </div>
);


class alianzaEmpresaEnergia extends Reflux.Component<any, any> {
    public formRef: any = null;
    public multiselectRef: any = null;

    lastFilters = {};
    state = {
        filters: {
            apellidosUsuariosSearch: "",
            NIFSearch: "",
            tipoEstadoSearch: "",
            usuariosDesde: ""
        },
        filtersEmpresa: {
            apellidosUsuariosSearch: "",
            NIFSearch: "",
            tipoEstadoSearch: "",
            usuariosDesde: ""
        },
        UserStore: {},
        isLoading: false,
        currentPage: 0,
        pageSize: 0,
        pageCount: 0,
        count: 0,
        contrato: "",
        empresaDetailTotal: [],
        agrupacionDetailTotal: [],
        empresaSeleccionado: false,
        passwordModificado: false,
        idUser: '',
        alianzaDetail: {
            id: 0,
            nombreAlianza: "",
            fechaAlta: "",
            fechaBaja: "",
            miembros: []

        },
        miembrosAlianza: [],
        empresaDetail: {
            id: "",
            idEmpresa: 0,
            empresa: "",
            nif: "",
            nombreContacto: "",
            telefonoContacto: "",
            correoContacto: "",
            fechaAlta: "",
            miembros: []
        },
        formEmpresa: {
            id: "",
            idEmpresa: 0,
            empresa: "",
            nif: "",
            nombreContacto: "",
            telefonoContacto: "",
            correoContacto: "",
            fechaAlta: "",
            miembros: []
        },
        companyIdUser: '',
        companyNameGlobal: '',
        userGroupId: 1000,
        userId: '',
        esAgencia: false,
        esComercializadora: false,
        esAdFincas: false,
        updateDataEmpresa: false,
        updateEmpresaButtonDisabled: true,
        saveEmpreButtonDisabled: true,
        saveXMLButtonDisabled: true,
        buscarButtonDisabled: true,
        buscarButtonEmpresaDisabled: true,
        NIFClienteError: false,
        show: false,
        saveButtonDisabled: true,
        showFilters: true,
        toggledClearRows: false,
        cupsError: false,
        NIFError: false,
        locale: 'es',
        inputIdDisabled: true,
        inputDisabled: true,
        usuariosDesde: '',
        clienteHasta: '',
        createdAt: '',
        updatedAt: '',
        periodoValidezHasta: '',
        periodoValidezDesde: '',
        CodigoPostalTitularError: false,
        Apellido1TitularError: false,
        emailError: false,
        NombreTitularError: false,
        DireccionFiscalError: false,
        TelefonoFijoError: false,
        TelefonMovilError: false,
        cnaeAux: "",
        IBANError: false,
        alert: null,
        createResult: [],
        createResult2: [],
        dicMunicipios: [],
        miembrosAlianzaAux: [],
        errores: {
            empresa: false,
            nif: false
        },
        textErrores: {
            empresa: "INTRODUZCA NOMBRE",
            nif: "INTRODUZCA NIF/CIF DEL CLIENTE"
        }


    };

    constructor(props) {
        super(props);
        this.store = UserStore;
        this.formRef = React.createRef();
        this.confirmAlertExit = this.confirmAlertExit.bind(this);
        this.logout = this.logout.bind(this);
        this.multiselectRef = React.createRef();
    }


    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    componentDidMount() {
        const usuario: any = JSON.parse(JSON.stringify(this.state.UserStore['data']))
        const companyNameGlobal = usuario.company;
        const companyIdUser = usuario.companyId;
        const userGroupId = usuario.user_group_id
        const userId = usuario.id
        const esComercializadora = usuario.comercializadora
        const esAgencia = usuario.agencia
        const esAdFincas = usuario.adFincas

        if (userGroupId === 1000) {
            this.setState({
                activeUser: true
            })
        };
        this.setState({
            companyNameGlobal,
            companyIdUser,
            userGroupId,
            userId,
            esComercializadora,
            esAgencia,
            esAdFincas

        });
    }

    ///////////////////////////////////////////////////////////////////////////////////////////

    logout = async () => {

        removeToken();
        localStorage.removeItem("lastFilters");
        localStorage.removeItem("user");
        localStorage.removeItem("lastFiltersEmpre");
        localStorage.removeItem("lastFiltersMulti");
        localStorage.removeItem('lastFiltersMultiGas');
        localStorage.removeItem('lastFiltersGas');
        this.props.client.cache.reset();
        this.props.client.resetStore();
        this.props.history.push(`/auth/login`);
    };

    //////////////////////////////////////////////////////////////////////////////////////7

    hideAlert = () => {
        this.setState({
            alert: null
        });
    };

    warningAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Error en la base de datos"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Existe un error en la Base de datos.
                </ReactBSAlert>
            )
        });
    };

    warningAlertNIF = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="No existen registros que cumplan los requisitos"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Revise los requisitos introducidos
                </ReactBSAlert>
            )
        });
    };

    confirmAlertExit = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Vuelva a iniciar sesión"
                    onConfirm={() => this.logout()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Salir"
                    btnSize=""
                    focusConfirmBtn
                >
                    Se ha cerrado la sesión por seguridad. Por favor, vuelva a iniciar sesión
                </ReactBSAlert>
            )
        });
    };

    warningAlertFiltros = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Existen campos obligatorios sin información"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Revise los datos introducidos
                </ReactBSAlert>
            )
        });
    };

    confirmAlertUpdate = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="¿Desea actualizar los datos de la agrupación?"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.onUpdate()}
                    showCancel
                    confirmBtnBsStyle="secondary"
                    confirmBtnText="Cancelar"
                    cancelBtnBsStyle="danger"
                    cancelBtnText="Actualizar"
                    btnSize=""
                >
                </ReactBSAlert>
            )
        });
    };

    successAlert = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    success
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Correcto"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="success"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Agrupación actualizada correctamente
                </ReactBSAlert>
            )
        });
    };

    errorDB = () => {
        this.setState({
            alert: (
                <ReactBSAlert
                    warning
                    style={{ display: "block", marginTop: "-100px" }}
                    title="Existe un problema con el servidor"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnBsStyle="warning"
                    confirmBtnText="Ok"
                    btnSize=""
                >
                    Existe un problema con el servidor. Si persite este error, por favor, contacte con el área de soporte
                </ReactBSAlert>
            )
        });
    };

    //////////////////////////////////////////////////////////////////////////////////////

    onChangeFieldSearch = (event) => {
        event.persist()
        var filtersData = { ...this.state.filters };
        const name = event.target.name;
        let value = event.target.value;
        

 
        /*if (event.target.type === "select-one" && value === "0") {
           delete filters[name];
         }

         if (value === "" || value === null) {
           delete filters[name];
         }
*/

        if (event.target.name === 'apellidosUsuariosSearch' && event.target.value.length == 0) {
            this.setState({
                cupsError: false,
                buscarButtonDisabled: true,
                inputIdDisabled: false
            })
        } else if (event.target.name === 'apellidosUsuariosSearch' && event.target.value.length > 0) {
            this.setState({
                inputIdDisabled: true,
                buscarButtonDisabled: false
            })
        };


        if (event.target.name === 'NIFSearch' && event.target.value.length == 0) {
            this.setState({
                NIFError: false,
                buscarButtonDisabled: true,
            })
        } else if (event.target.name === 'NIFSearch' && event.target.value.length > 0) {

            const result = validateSpanishId(event.target.value)

            if (result === true) {
                this.setState({
                    NIFError: false,
                    buscarButtonDisabled: false,
                })
            } else {
                this.setState({
                    NIFError: true,
                    buscarButtonDisabled: true,
                })
            }
        }

        if (event.target.name === 'tipoEstadoSearch' && event.target.value == 'Selecciona...') {
            value = null
            this.setState({
                buscarButtonDisabled: true
            })
        } else if (event.target.name === 'tipoEstadoSearch' && event.target.value !== 'Selecciona...') {
            this.setState({
                buscarButtonDisabled: false
            })
        };

        filtersData[name] = value;
        //if (value === "" || value === null) {
        //    formData[name] = 0;
        //}
        this.setState({ filters: filtersData });
    };

    //////////////////////////////////////////////////////////////////////////////////////

    onChangeFieldSearchEmpresa = (event) => {
        event.persist()
        var filtersData = { ...this.state.filtersEmpresa };
        const name = event.target.name;
        let value = event.target.value;

        /*if (event.target.type === "select-one" && value === "0") {
           delete filters[name];
         }

         if (value === "" || value === null) {
           delete filters[name];
         }
*/

        if (event.target.name === 'apellidosUsuariosSearch' && event.target.value.length == 0) {
            this.setState({
                cupsError: false,
                buscarButtonEmpresaDisabled: true,
                inputIdDisabled: false
            })
        } else if (event.target.name === 'apellidosUsuariosSearch' && event.target.value.length > 0) {
            this.setState({
                inputIdDisabled: true,
                buscarButtonEmpresaDisabled: false
            })
        };


        if (event.target.name === 'NIFSearch' && event.target.value.length == 0) {
            this.setState({
                NIFError: false,
                buscarButtonEmpresaDisabled: true,
            })
        } else if (event.target.name === 'NIFSearch' && event.target.value.length > 0) {

            const result = validateSpanishId(event.target.value)

            if (result === true) {
                this.setState({
                    NIFError: false,
                    buscarButtonEmpresaDisabled: false,
                })
            } else {
                this.setState({
                    NIFError: true,
                    buscarButtonEmpresaDisabled: true,
                })
            }
        }

        if (event.target.name === 'tipoEstadoSearch' && event.target.value == 'Selecciona...') {
            value = null
            this.setState({
                buscarButtonEmpresaDisabled: true
            })
        } else if (event.target.name === 'tipoEstadoSearch' && event.target.value !== 'Selecciona...') {
            this.setState({
                buscarButtonEmpresaDisabled: false
            })
        };

        filtersData[name] = value;
        //if (value === "" || value === null) {
        //    formData[name] = 0;
        //}
        this.setState({ filtersEmpresa: filtersData });
    };


    ////////////////////////////////////////////////////////////////////////////////////////

    onChangeFieldUsuario = (event) => {
        event.persist()
        let errorsData = { ...this.state.errores }
        let textErroresData = { ...this.state.textErrores }

        var formData = { ...this.state.formEmpresa };
        var target = event.target;
        var value =
            event.target.type === "checkbox"
                ? event.target.checked
                : event.target.value;
        const name = event.target.name;

        const nameFilters = ['idEmpresa', 'nif', 'tipoServicio', 'empresa', 'fechaAlta', 'estado', 'correoContacto', 'nombreContacto', 'telefonoContacto', 'usuario']
        const optionsFilters = ['user_group_id', 'esActiva', 'esAdmin', 'esComercial', 'esConsultor', 'esFuncionario', 'informes', 'detalles'];




        if (nameFilters.indexOf(event.target.name) != -1) {
            this.setState({ updateDataEmpresa: true });
            this.setState({
                saveEmpreButtonDisabled: false,
                updateEmpresaButtonDisabled: false
            })
        };


        if (name == "empresa" && (!value || value.length == 0 || value == "")) {

            console.log('entra en NO_valor de name: ', name, " value: ", value,)

            errorsData['empresa'] = false
            textErroresData['empresa'] = "INTRODUZCA NOMBRE"
            console.log('entra en 1')
        } else if (name == "empresa" && (value || value.length > 0 || value !== "")) {

            console.log('entra en  SI valor de name: ', name, " value: ", value,)

            errorsData['empresa'] = true
            textErroresData['empresa'] = ""
            this.setState({ updateDataEmpresa: true });
            this.setState({
                saveEmpreButtonDisabled: false,
                updateEmpresaButtonDisabled: false
            })
        }

        if (event.target.name === 'correoContacto') {
            const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
            //const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
            const result = pattern.test(event.target.value);
            if (result === true) {
                this.setState({
                    emailError: false,
                })
            } else {
                this.setState({
                    emailError: true
                })
            }
        };

        if (name == "nif" && (!value || value.length == 0 || value == "")) {
            errorsData['nif'] = false
            textErroresData['nif'] = "INTRODUZCA NIF/CIF DEL CLIENTE"
        } else if (name == "nif" && (value || value.length > 0 || value !== "")) {
            const result = validateSpanishId(event.target.value)
            if (result === true) {
                const resultTipo = spainIdType(event.target.value)
                //formData['tipoTitular'] = resultTipo.toUpperCase();
                errorsData['nif'] = false
                textErroresData['nif'] = ""
                this.setState({
                    isDisabledCIF: false
                })
            } else {
                errorsData['nif'] = false
                textErroresData['nif'] = "NIF/CIF INCORRECTO"
                this.setState({
                    isDisabledCIF: true
                })
            }
        };

        if (optionsFilters.indexOf(event.target.name) != -1 && event.target.value == 'Selecciona...') {
            value = null
        };

        if (value === "true" || value == "false") {
            value = JSON.parse(value);
        };

        formData[name] = value;

        this.setState({
            updateDataEmpresa: true
        })
        this.setState({
            formEmpresa: formData,
            errores: errorsData,
            textErrores: textErroresData
        });

    };

    ///////////////////////////////////////////////////////////////////////////////////////

    showFiltersToggle = () => {
        const showFilters = !this.state.showFilters;

        this.setState({ showFilters });
    };

    //////////////////////////////////////////////////////////////////////////////////////

    getFilterIcon = () => {
        return this.state.showFilters ? "ni-bold-up" : "ni-bold-down";
    };

    ////////////////////////////////////////////////////////////////////////////////////

    clearForm = () => {
        this.multiselectRef.current.resetSelectedValues();
        this.formRef.current.reset();
        this.setState({
            filters: {
                apellidosUsuariosSearch: "",
                NIFSearch: "",
                tipoEstadoSearch: "",
                usuariosDesde: ""
            },
            filtersEmpresa: {
                apellidosUsuariosSearch: "",
                NIFSearch: "",
                tipoEstadoSearch: "",
                usuariosDesde: ""
            },
            inputIdDisabled: false,
            inputDisabled: false,
            buscarButtonDisabled: true,
            buscarButtonDisabledEmpresa: true,
            empresaSeleccionado: false,
            empresaDetailTotal: [],
            agrupacionDetailTotal: [],
            passwordModificado: false,
            formEmpresa: {
                id: "",
                idEmpresa: 0,
                empresa: "",
                nif: "",
                nombreContacto: "",
                telefonoContacto: "",
                correoContacto: "",
                fechaAlta: "",
                miembros: []
            },
            toggledClearRows: !this.state.toggledClearRows,
            updateEmpresaButtonDisabled: true,
            saveEmpreButtonDisabled: true,
            createdAt: '',
            updatedAt: '',
            miembrosAlianza: [],
            miembrosAlianzaAux: [],
            updateDataEmpresa: false,
            errores: {
                empresa: false,
                nif: false
            },
            textErrores: {
                empresa: "INTRODUZCA NOMBRE",
                nif: "INTRODUZCA NIF/CIF DEL CLIENTE"
            }
        });

        this.formRef.current.reset();
        this.multiselectRef.current.resetSelectedValues();


    };

    //////////////////////////////////////////////////////////////////////////////////////

    buscarEmpresa = async () => {
        this.setState({
            isLoading: true,
        })

        const parsed = await JSON.parse(await localStorage.getItem("user")!);
        const userId = parsed.id;
        const companyIdUser = Number(parsed.companyId);


        var dataFilters = {
            'empresa': this.state.filtersEmpresa.apellidosUsuariosSearch?.toUpperCase() || "",
            'nif': this.state.filtersEmpresa.NIFSearch?.toUpperCase() || "",
            'tipoServicio': this.state.filtersEmpresa.tipoEstadoSearch.toUpperCase() || ""
        };

        const valores = JSON.stringify(dataFilters)

        ///// para GET REST //////

        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };

        let direccionFile = "https://datapi.psgestion.es/empresas/listadoempresa/admin?id=" + companyIdUser + "&user=" + userId;

        await axios.get(direccionFile, { headers, params: { valores } }).
            then((result) => {
                //console.log(JSON.stringify(result.data));
                //console.log('resultado del listados')
                if (result.data.rows.length > 0) {
                    let data = result.data.rows
                    this.setState({
                        empresaDetailTotal: result.data.rows,
                        inputDisabled: false,
                        empresaSeleccionado: false,
                        updateDataEmpresa: false,
                        saveEmpreButtonDisabled: true,
                    });

                } else {
                    this.warningAlertNIF()
                };
            }).catch((error) => {
                this.errorDB()
                this.setState({
                    isLoading: false,
                    inputDisabled: false,
                    empresaSeleccionado: false,
                    empresaDetailTotal: [],
                    updateDataEmpresa: false,
                    saveEmpreButtonDisabled: true,
                    updateEmpresaButtonDisabled: true,
                    passwordModificado: false,
                    createdAt: '',
                    updatedAt: '',
                    miembrosAlianza: [],
                    miembroaAlianzaAux: [],
                    toggledClearRows: !this.state.toggledClearRows,
                    errores: {
                        empresa: false,
                        nif: false
                    },
                    textErrores: {
                        empresa: "INTRODUZCA NOMBRE",
                        nif: "INTRODUZCA NIF/CIF DEL CLIENTE"
                    },
                    formEmpresa: {
                        id: "",
                        idEmpresa: 0,
                        empresa: "",
                        nif: "",
                        nombreContacto: "",
                        telefonoContacto: "",
                        correoContacto: "",
                        fechaAlta: "",
                        miembros: []
                    }
                });
            });
    };

    //////////////////////////////////////////////////////////////////////////////////////

    buscarAgrupacion = async () => {
        this.setState({
            isLoading: true,
        })

        const parsed = await JSON.parse(await localStorage.getItem("user")!);
        const userId = parsed.id;
        const companyIdUser = Number(parsed.companyId);


        var dataFilters = {
            'empresa': this.state.filters.apellidosUsuariosSearch?.toUpperCase() || "",
            'nif': this.state.filters.NIFSearch?.toUpperCase() || "",
            'tipoServicio': this.state.filters.tipoEstadoSearch.toUpperCase() || ""
        };

        const valores = JSON.stringify(dataFilters)

        ///// para GET REST //////

        const headers = {
            'Authorization': 'Bearer my-token',
            'My-Custom-Header': 'foobar',
            'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W'
        };

        let direccionFile = "https://datapi.psgestion.es/empresas/listadoagrupacion/admin?id=" + companyIdUser + "&user=" + userId;

        await axios.get(direccionFile, { headers, params: { valores } }).
            then((result) => {
                //console.log(JSON.stringify(result.data));
                //console.log('resultado del listados')
                if (result.data.rows.length > 0) {
                    let data = result.data.rows
                    this.setState({
                        agrupacionDetailTotal: result.data.rows,
                        inputDisabled: false,
                        empresaSeleccionado: false,
                        updateDataEmpresa: false,
                        saveEmpreButtonDisabled: true,
                        updateEmpresaButtonDisabled: true,
                        passwordModificado: false,
                        createdAt: '',
                        updatedAt: '',
                        toggledClearRows: !this.state.toggledClearRows,
                        errores: {
                            empresa: false,
                            nif: false
                        },
                        textErrores: {
                            empresa: "INTRODUZCA NOMBRE",
                            nif: "INTRODUZCA NIF/CIF DEL CLIENTE"
                        },
                        formEmpresa: {
                            id: "",
                            idEmpresa: 0,
                            empresa: "",
                            nif: "",
                            nombreContacto: "",
                            telefonoContacto: "",
                            correoContacto: "",
                            fechaAlta: "",
                            miembros: []
                        }
                    });

                } else {
                    this.warningAlertNIF()
                };
            }).catch((error) => {
                this.errorDB()
                this.setState({
                    isLoading: false,
                    inputDisabled: false,
                    empresaSeleccionado: false,
                    agrupacionDetailTotal: [],
                    updateDataEmpresa: false,
                    saveEmpreButtonDisabled: true,
                    updateEmpresaButtonDisabled: true,
                    passwordModificado: false,
                    createdAt: '',
                    updatedAt: '',
                    miembrosAlianza: [],
                    miembroaAlianzaAux: [],
                    toggledClearRows: !this.state.toggledClearRows,
                    errores: {
                        empresa: false,
                        nif: false
                    },
                    textErrores: {
                        empresa: "INTRODUZCA NOMBRE",
                        nif: "INTRODUZCA NIF/CIF DEL CLIENTE"
                    },
                    formEmpresa: {
                        id: "",
                        idEmpresa: 0,
                        empresa: "",
                        nif: "",
                        nombreContacto: "",
                        telefonoContacto: "",
                        correoContacto: "",
                        fechaAlta: "",
                        miembros: []
                    }
                });
            });
    };


    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    updateHandler(props) {

        //let existenErrores = Object.keys(this.state.errores).some(k => !this.state.errores[k]);

        //if (existenErrores == true) {

        if (!this.state.errores.empresa) {
            this.warningAlertFiltros()

        } else {
            this.confirmAlertUpdate()
        }
    };
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    onUpdate = async () => {

        const token = localStorage.getItem("token")

        const user: any = localStorage.getItem("user");

        var datosUser: any = []
        if (user) {
            datosUser = JSON.parse(user);
        }

        const parsed = await JSON.parse(await localStorage.getItem("user")!);
        const iduser = parsed.id;
        const companyIdUser = Number(parsed.companyId);

        if (this.state.updateDataEmpresa == true) {

            var empresaDataUpdate = {
                'id': this.state.formEmpresa.id,
                'idEmpresa': this.state.formEmpresa.idEmpresa,
                'empresa': this.state.formEmpresa.empresa?.toUpperCase() || '',
                'nif': this.state.formEmpresa.nif,
                'nombreContacto': this.state.formEmpresa.nombreContacto?.toUpperCase() || '',
                'telefonoContacto': this.state.formEmpresa.telefonoContacto,
                'correoContacto': this.state.formEmpresa.correoContacto,
                'fechaAlta': this.state.formEmpresa.fechaAlta,
                'miembros': this.state.miembrosAlianza
            }

            const headers = {

                'Authorization': 'Bearer my-token',
                'My-Custom-Header': 'foobar',
                'x-api-key': '$2y$10$bcT1Ukm4V/6/z5GvitZv0unu8I91mpixuwUO6z5CyEGev9DDDY28W',
                'Content-Type': 'application/json',
            };

            let direccionFile = "https://datapi.psgestion.es/empresas/agrupacion/modificar/admin?id=" + companyIdUser + "&user=" + iduser;


            axios.post(direccionFile, empresaDataUpdate, { headers, responseType: 'json' })
                .then((result) => {

                    console.log('Actualización correcta', result)
                    console.log('Datpos del registro: ', result.data)
                    this.successAlert();
                    this.setState({
                        inputDisabled: false,
                        empresaSeleccionado: false,
                        empresaDetailTotal: [],
                        updateDataEmpresa: false,
                        saveEmpreButtonDisabled: true,
                        updateEmpresaButtonDisabled: true,
                        passwordModificado: false,
                        createdAt: '',
                        updatedAt: '',
                        miembrosAlianza: [],
                        miembrosAlianzaAux: [],
                        toggledClearRows: !this.state.toggledClearRows,
                        errores: {
                            empresa: false,
                            nif: false
                        },
                        textErrores: {
                            empresa: "INTRODUZCA NOMBRE",
                            nif: "INTRODUZCA NIF/CIF DEL CLIENTE"
                        },
                        formEmpresa: {
                            id: "",
                            idEmpresa: 0,
                            empresa: "",
                            nif: "",
                            nombreContacto: "",
                            telefonoContacto: "",
                            correoContacto: "",
                            fechaAlta: "",
                            miembros: []
                        }
                    });

                }).catch((error) => {
                    this.errorDB()
                    this.setState({
                        isLoading: false,
                    });
                });

            await this.buscarAgrupacion();
        };
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    handleChangeEmpresas = state => {
        // eslint-disable-next-line no-console

        if (state.selectedCount === 1) {
            let errorsData = { ...this.state.errores }
            let textErroresData = { ...this.state.textErrores }
            var existeEmpresa = ''
            var empresa = state.selectedRows[0].idEmpresa;
            this.setState({ empresaDetail: state.selectedRows[0] });
            let existeFechaAlta = state.selectedRows[0].fechaAlata
            existeEmpresa = state.selectedRows[0].idEmpresa

            if (existeFechaAlta) {

                this.setState({
                    inputDisabled: true,
                    updateEmpresaButtonDisabled: false
                })
            } else if (!existeFechaAlta) {
                this.setState({
                    inputDisabled: false,
                    updateEmpresaButtonDisabled: true
                })
            }



            if (existeEmpresa) {
                this.setState({ id: this.state.empresaDetail.id });

                if (state.selectedRows[0].nif && (!state.selectedRows[0].nif || state.selectedRows[0].nif.length == 0 || state.selectedRows[0].nif == "")) {
                    errorsData['nif'] = false
                    textErroresData['nif'] = "INTRODUZCA NIF/CIF DEL CLIENTE"
                } else if (state.selectedRows[0].nif && (state.selectedRows[0].nif || state.selectedRows[0].nif.length > 0 || state.selectedRows[0].nif !== "")) {
                    const result = validateSpanishId(state.selectedRows[0].nif)
                    if (result === true) {
                        const resultTipo = spainIdType(state.selectedRows[0].nif)
                        //formData['tipoTitular'] = resultTipo.toUpperCase();
                        errorsData['nif'] = false
                        textErroresData['nif'] = ""
                        this.setState({
                            isDisabledCIF: false
                        })
                    } else {
                        errorsData['nif'] = false
                        textErroresData['nif'] = "NIF/CIF INCORRECTO"
                        this.setState({
                            isDisabledCIF: true
                        })
                    }
                };

                if (state.selectedRows[0].empresa && (!state.selectedRows[0].empresa || state.selectedRows[0].empresa.length == 0 || state.selectedRows[0].empresa == "")) {

                    console.log('entra en NO_valor de name: ')

                    errorsData['empresa'] = false
                    textErroresData['empresa'] = "INTRODUZCA NOMBRE"
                    console.log('entra en 1')
                } else if (state.selectedRows[0].empresa && (state.selectedRows[0].empresa || state.selectedRows[0].empresa.length > 0 || state.selectedRows[0].empresa !== "")) {

                    console.log('entra en  SI valor de name: ')

                    errorsData['empresa'] = true
                    textErroresData['empresa'] = ""
                    this.setState({ saveDataEmpresa: true });
                    this.setState({
                        saveEmpreButtonDisabled: false,
                        updateEmpresaButtonDisabled: false
                    })
                };

                this.setState({
                    formEmpresa: {
                        id: state.selectedRows[0].id,
                        idEmpresa: state.selectedRows[0].idEmpresa,
                        empresa: state.selectedRows[0].empresa?.toUpperCase() || '',
                        nif: state.selectedRows[0].nif,
                        fechaAlta: state.selectedRows[0].fechaAlta,
                        correoContacto: state.selectedRows[0].correoContacto,
                        nombreContacto: state.selectedRows[0].nombreContacto?.toUpperCase() || '',
                        telefonoContacto: state.selectedRows[0].telefonoContacto,
                        miembros: state.selectedRows[0].miembros
                    },
                    miembrosAlianza: state.selectedRows[0].miembros,
                    empresaSeleccionado: true,
                    inputDisabled: false,
                    createdAt: state.selectedRows[0].createdAt,
                    errores: errorsData,
                    textErrores: textErroresData

                });

            } else {
                this.setState({
                    empresaSeleccionado: false,
                    formEmpresa: {
                        id: "",
                        idEmpresa: 0,
                        empresa: "",
                        nif: "",
                        nombreContacto: "",
                        telefonoContacto: "",
                        correoContacto: "",
                        fechaAlta: "",
                        miembros: []
                    },
                    createdAt: null,
                    updatedAt: null,
                    passwordModificado: false,
                    errores: {
                        codigoPostal: false,
                        nif: false
                    },
                    textErrores: {
                        codigoPostal: "INTRODUZCA MUNICIPIO/CODIGO POSTAL",
                        nif: "INTRODUZCA NIF/CIF DEL CLIENTE"
                    }
                });
            }
        } else {

            this.setState({
                empresaSeleccionado: false,
                inputDisabled: false,
                updateEmpresaButtonDisabled: true,
                saveEmpreButtonDisabled: true,
                passwordModificado: false,
                createdAt: null,
                updatedAt: null,
                formEmpresa: {
                    id: "",
                    idEmpresa: 0,
                    empresa: "",
                    nif: "",
                    nombreContacto: "",
                    telefonoContacto: "",
                    correoContacto: "",
                    fechaAlta: "",
                    miembros: []
                },
                errores: {
                    codigoPostal: false,
                    nif: false
                },
                textErrores: {
                    codigoPostal: "INTRODUZCA MUNICIPIO/CODIGO POSTAL",
                    nif: "INTRODUZCA NIF/CIF DEL CLIENTE"
                }
            });
        };
    };

    handleChange = state => {
        // eslint-disable-next-line no-console

        console.log('valor de state en handleChangeEmpresas: ', state)

        /*alianzaDetail: {
            id: 0,
                nombreAlianza: "",
                    fechaAlta: "",
                        fechaBaja: "",
                            miembros: []
    
        },
        */
        let alianzaDetailAux = { ...this.state.alianzaDetail }

        var datosEmpresasAlianza: any = []
        if (state.selectedCount > 0) {


            for (var i = 0; i < state.selectedRows.length; i++) {

                console.log('valor de state.selectedRows[i]:  ', state.selectedRows[i])

                let nombreEmpresa = state.selectedRows[i].empresa
                let idEmpresa = state.selectedRows[i].id

                datosEmpresasAlianza.push({ id: idEmpresa, nombreEmpresa: nombreEmpresa })

            }
        } else {


            datosEmpresasAlianza = []

        }


        console.log('valor de datosEmpresasAlianza: ', datosEmpresasAlianza)
        console.log('longitud de datosEmpresasAlianza: ', datosEmpresasAlianza.length)
    }

    handleClearRows = () => {
        this.setState({ toggledClearRows: !this.state.toggledClearRows })
    };


    resetValues() {
        // By calling the belowe method will reset the selected values programatically
        this.multiselectRef.current.resetSelectedValues();
    }

    onSelect = (selectedList, selectedItem) => {

        console.log('valor de selectedList: ', selectedList)
        console.log('valor de selectedItem: ', selectedItem)

        let alianzaDetailAux: any[] = selectedList

        this.setState({
            miembrosAlianzaAux: alianzaDetailAux
        });
    };

    añadirEmpresa = () => {

        let alianzaDetailAux: any[] = this.state.miembrosAlianzaAux

        let alianzaDetailAdd: any[] = this.state.miembrosAlianza


        console.log('1.valor de alianzaDetailAdd: ', alianzaDetailAdd)
        console.log('1.valorvalor de alianzaDetailAux: ', alianzaDetailAux)

        for (var i = 0; i < alianzaDetailAux.length; i++) {

            let id = alianzaDetailAux[i].id;
            let empresa = alianzaDetailAux[i].empresa;
            let nif = alianzaDetailAux[i].nif;
            let nombreContacto = alianzaDetailAux[i].nombreContacto;
            let correoContacto = alianzaDetailAux[i].correoContacto;
            let telefonoContacto = alianzaDetailAux[i].telefonoContacto;

            alianzaDetailAdd.push({ id: id, empresa: empresa, nif: nif, nombreContacto: nombreContacto, correoContacto: correoContacto, telefonoContacto: telefonoContacto })

        }

        console.log('2.valor de alianzaDetailAdd: ', alianzaDetailAdd)
        console.log('2.valorvalor de alianzaDetailAux: ', alianzaDetailAux)


        this.setState({
            miembrosAlianza: alianzaDetailAdd,
            updateDataEmpresa: true,
            saveEmpreButtonDisabled: false,
            updateEmpresaButtonDisabled: false
        });
    };

   
    ///////////////////////////////////////////////////////////////////////////////////////////

    CommandCell = (props) => (
        <MyCommandCell
            {...props}
            remove={this.remove}
        />
    );


    remove = (dataItem) => {

        console.log('valor de dataItem: ', dataItem)
        console.log('valor de this.state.miembrosAlianza: ', this.state.miembrosAlianza)
        let miembrosAlianza: any = this.state.miembrosAlianza

        let index = miembrosAlianza.findIndex((record) => record.id === dataItem.id);
        miembrosAlianza.splice(index, 1);

        console.log('valor de this.state.miembrosAlianza después: ', miembrosAlianza)

        this.setState({
            miembrosAlianza,
            updateDataEmpresa: true,
            saveEmpreButtonDisabled: false,
            updateEmpresaButtonDisabled: false

        });
    };

    //////////////////////////////////////////////////////////////////////////////////////////

    render() {

        var formEmpresa = this.state.formEmpresa


        console.log('VALOR DE THIS.STATE EN RENDER: ', this.state)

        return (
            <>
                <Header title={'Información Empresas'} />
                <Container className=" mt--7" fluid>

                    <Card className="mb-4">
                        <CardHeader>
                            <Button
                                onClick={this.showFiltersToggle}
                                size="sm"
                                className="float-right"
                            >
                                <i className={"ni " + this.getFilterIcon()} />
                            </Button>
                            <h3 className="mb-0">Filtrar agrupaciones</h3>
                        </CardHeader>
                        <Collapse isOpen={this.state.showFilters}>
                            <CardBody>
                                <Form innerRef={this.formRef}>
                                    <Row>
                                        <Col md="12" lg="3">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="clienteRef">
                                                    NOMBRE AGRUPACION
                                                </Label>
                                                <Input
                                                    id="apellidosUsuariosSearch"
                                                    placeholder="Ingresar Razón Social de la Empresa"
                                                    name="apellidosUsuariosSearch"
                                                    maxLength={80}
                                                    onChange={this.onChangeFieldSearch}
                                                    value={this.state.filters.apellidosUsuariosSearch}
                                                    type="text"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg="2">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="NIFSearch">
                                                    NIF
                                                </Label>
                                                <Input
                                                    id="NIFSearch"
                                                    placeholder="Ingresar CIF empresa"
                                                    name="NIFSearch"
                                                    maxLength={16}
                                                    onChange={this.onChangeFieldSearch}
                                                    value={this.state.filters.NIFSearch}
                                                    type="text"

                                                />
                                                {this.state.NIFError ? <FormText color="red">Comprobar CIF</FormText> : ''}
                                            </FormGroup>
                                        </Col>

                                        <Col md="2" lg="2">
                                            <FormGroup>
                                                <Label className="form-control-label" for="tipoEstadoProducto">ESTADO</Label>
                                                <Input type="select" name="tipoEstadoSearch" id="tipoEstadoSearch"
                                                    onChange={this.onChangeFieldSearch}
                                                    defaultValue={'DEFAULT'}
                                                    value={this.state.filters.tipoEstadoSearch || "DEFAULT"}>
                                                    <option value="DEFAULT" disabled>Selecciona...</option>
                                                    <option value="ACTIVA">ACTIVA</option>
                                                    <option value="BAJA">BAJA</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>

                                        <Col className="text-right">
                                            <Button onClick={this.clearForm}>
                                                <i className={"ni ni-fat-remove"} /> Limpiar
                                            </Button>
                                            <Button
                                                color="primary"
                                                onClick={this.buscarAgrupacion}
                                                disabled={this.state.buscarButtonDisabled}
                                            >
                                                <i className={"bi bi-search"} /> Buscar
                                            </Button>
                                        </Col>
                                    </Row>

                                    <Card className="shadow">
                                        <DataTable
                                            title="LISTADO DE AGRUPACIONES"
                                            columns={columnsDatosEmpresas}
                                            data={this.state.agrupacionDetailTotal}
                                            selectableRows
                                            selectableRowsComponent={Checkbox} // Pass the function only
                                            selectableRowsComponentProps={{ inkdisabled: "true" }} // optionally, pass Material Ui supported props down to our custom checkbox
                                            sortIcon={sortIcon} // use a material icon for our sort icon. rdt will rotate the icon 180 degrees for you           fixedHeader = {true}
                                            noDataComponent={"Sin datos para mostrar"}
                                            pagination
                                            paginationPerPage={10}
                                            paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 40]}
                                            onSelectedRowsChange={this.handleChangeEmpresas}
                                            clearSelectedRows={this.state.toggledClearRows}
                                            keyField="id"
                                            highlightOnHover
                                            striped
                                            pointerOnHover
                                            selectableRowsHighlight
                                            customStyles={customStyles}
                                            contextMessage={{ singular: 'empresa', plural: 'empresa', message: 'seleccionada' }}
                                            subHeader
                                            subHeaderComponent={

                                                <div style={{ display: 'flex', alignItems: 'center' }}>

                                                    <CSVLink
                                                        data={this.state.agrupacionDetailTotal}
                                                        filename={"empresasRegistradas.csv"}
                                                        className="btn btn-default btn-sm btn-circle"
                                                        color="default"
                                                        target="_blank"
                                                        separator={";"}
                                                    >

                                                        <span className="btn-inner--icon">
                                                            <i className={"bi bi-file-earmark-spreadsheet"} style={{ fontSize: 25 }}></i>
                                                        </span>
                                                        <span className="btn-inner--text">Fichero CSV</span>

                                                    </CSVLink>
                                                </div>
                                            }
                                            progressComponent={<Circular />}
                                        />
                                    </Card>
                                </Form>
                            </CardBody>
                        </Collapse>
                    </Card>


                    <Card className="mb-4">
                        <CardHeader>
                            <Button
                                onClick={this.showFiltersToggle}
                                size="sm"
                                className="float-right"
                            >
                                <i className={"ni " + this.getFilterIcon()} />
                            </Button>
                            <h3 className="mb-0">Filtrar empresas</h3>
                        </CardHeader>
                        <Collapse isOpen={this.state.showFilters}>
                            <CardBody>
                                <Form innerRef={this.formRef}>
                                    <Row>
                                        <Col md="12" lg="3">
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="clienteRef">
                                                    NOMBRE EMPRESA
                                                </Label>
                                                <Input
                                                    id="apellidosUsuariosSearch"
                                                    placeholder="Ingresar Razón Social de la Empresa"
                                                    name="apellidosUsuariosSearch"
                                                    maxLength={80}
                                                    onChange={this.onChangeFieldSearchEmpresa}
                                                    value={this.state.filtersEmpresa.apellidosUsuariosSearch}
                                                    type="text"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="2" lg="2">
                                            <FormGroup>
                                                <Label className="form-control-label" for="tipoEstadoProducto">TIPO</Label>
                                                <Input type="select" name="tipoEstadoSearch" id="tipoEstadoSearch"
                                                    onChange={this.onChangeFieldSearchEmpresa}
                                                    value={this.state.filtersEmpresa.tipoEstadoSearch}>
                                                    <option selected>Selecciona...</option>
                                                    <option value="ELEC">ELECTRICIDAD</option>
                                                    <option value="GAS">GAS</option>
                                                    <option value="ELEGAS">AMBOS</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>

                                        <Col className="text-right">
                                            <Button onClick={this.clearForm}>
                                                <i className={"ni ni-fat-remove"} /> Limpiar
                                            </Button>
                                            <Button
                                                color="primary"
                                                onClick={this.buscarEmpresa}
                                                disabled={this.state.buscarButtonEmpresaDisabled}
                                            >
                                                <i className={"bi bi-search"} /> Buscar
                                            </Button>
                                        </Col>
                                    </Row>

                                    <Row>

                                        <Col lg="6">
                                            <Multiselect
                                                options={this.state.empresaDetailTotal}
                                                //{productos.map((item:any, index) => <option value={item.id} key={index}>{item.nombreOferta}</option>)} // Options to display in the dropdown
                                                //selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                                                onSelect={this.onSelect} // Function will trigger on select event
                                                //onRemove={this.onRemove} // Function will trigger on remove event
                                                displayValue="empresa" // Property name to display in the dropdown options
                                                showCheckbox
                                                placeholder="Seleccionar empresas"
                                                emptyRecordMsg="Sin datos para mostrar"
                                                closeOnSelect={false}
                                                ref={this.multiselectRef}
                                            />
                                        </Col>

                                        <Col className="text-right">
                                            <Button
                                                color="primary"
                                                onClick={this.añadirEmpresa}
                                            // disabled={this.state.buscarButtonDisabled}
                                            >
                                                <i className={"bi bi-search"} /> Añadir Empresas
                                            </Button>
                                        </Col>


                                    </Row>
                                </Form>
                            </CardBody>
                        </Collapse>
                    </Card>


                    <Row>
                        <div className="col">
                            <Card className="shadow">
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <button
                                                className="btn btn-sm btn-primary"
                                                onClick={this.props.history.goBack}
                                            >
                                                Atrás
                                            </button>
                                        </Col>

                                        <Col className="col-auto d-flex">
                                            <Card className="p-3 bg-light">
                                                <FormGroup>

                                                    <Button disabled={this.state.updateEmpresaButtonDisabled} id="UpdateRepreProptooltip" color="danger" size="m" type="button" className="btn-icon btn-sm btn-circle"
                                                        onClick={() => { this.updateHandler(this.state.updateDataEmpresa) }}
                                                    >
                                                        <span className="btn-inner--icon">
                                                            <i className="bi bi-person-lines-fill" style={{ fontSize: 30 }}></i>
                                                        </span>
                                                        <span className="btn-inner--text">Actualizar</span>
                                                        <UncontrolledTooltip delay={0} placement="top" target="UpdateRepreProptooltip">
                                                            MODIFICAR AGRUPACION EXISTENTE
                                                        </UncontrolledTooltip>
                                                    </Button>

                                                    <Link
                                                        color="info" type="button" className="btn btn-primary btn-sm btn-circle"
                                                        data-toggle="modal"
                                                        to={{ pathname: "/admin/nuevaAlianza" }}
                                                        id="newElecProduct"
                                                    >
                                                        <span className="btn-inner--icon">
                                                            <i className="bi bi-person-plus-fill" style={{ fontSize: 30 }}></i>
                                                        </span>
                                                        <span className="btn-inner--text">Nuevo</span>

                                                        <UncontrolledTooltip delay={0} placement="top" target="newElecProduct">
                                                            CREAR NUEVA AGRUPACION
                                                        </UncontrolledTooltip>
                                                    </Link>

                                                </FormGroup>

                                            </Card>
                                        </Col>

                                    </Row>
                                    <div>
                                        <CardTitle>DATOS GENERALES AGRUPACION DE EMPRESAS</CardTitle>
                                    </div>

                                    <Row>
                                        <Col>
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="id">
                                                    ID
                                                </Label>
                                                <Input
                                                    className="text-primary"
                                                    id="id"
                                                    placeholder=""
                                                    name="nombre"
                                                    maxLength={100}
                                                    onChange={this.onChangeFieldUsuario}
                                                    value={formEmpresa.id}
                                                    type="text"
                                                    disabled={true}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="empresa">
                                                    Nombre Alianza
                                                </Label>
                                                <Input
                                                    className="text-primary"
                                                    id="empresa"
                                                    placeholder=""
                                                    name="empresa"
                                                    maxLength={100}
                                                    onChange={this.onChangeFieldUsuario}
                                                    value={formEmpresa.empresa}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label className="form-control-label" htmlFor="nif">
                                                    CIF
                                                </Label>
                                                <Input
                                                    id="nif"
                                                    placeholder=""
                                                    name="nif"
                                                    maxLength={16}
                                                    onChange={this.onChangeFieldUsuario}
                                                    value={formEmpresa.nif}
                                                    type="text"
                                                    invalid={this.state.errores.nif == false}
                                                />
                                                <FormFeedback>{this.state.textErrores.nif}</FormFeedback>
                                            </FormGroup>
                                        </Col>

                                    </Row>

                                    <Row>
                                        <Col md="2">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="nombreContacto">
                                                    Nombre Contacto
                                                </Label>
                                                <Input
                                                    className="text-primary"
                                                    id="nombreContacto"
                                                    placeholder=""
                                                    name="nombreContacto"
                                                    maxLength={80}
                                                    onChange={this.onChangeFieldUsuario}
                                                    value={formEmpresa.nombreContacto}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>


                                        <Col md="2">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="telefonoContacto">
                                                    Teléfono Contacto
                                                </Label>
                                                <Input
                                                    className="text-primary"
                                                    id="telefonoContacto"
                                                    placeholder=""
                                                    name="telefonoContacto"
                                                    maxLength={20}
                                                    onChange={this.onChangeFieldUsuario}
                                                    value={formEmpresa.telefonoContacto}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="3">
                                            <FormGroup>

                                                <Label className="form-control-label" htmlFor="correoContacto">
                                                    Correo Contacto
                                                </Label>
                                                <Input
                                                    id="correoContacto"
                                                    placeholder=""
                                                    name="correoContacto"
                                                    maxLength={80}
                                                    onChange={this.onChangeFieldUsuario}
                                                    value={formEmpresa.correoContacto}
                                                    type="text"
                                                    disabled={this.state.inputDisabled}

                                                />
                                            </FormGroup>
                                        </Col>

                                    </Row>

                                </CardBody>
                            </Card>

                            {this.state.alert}

                        </div>
                    </Row>

                    <Row className="margin-reset w-100">
                        <Card>
                            <CardBody>

                                <Row className="margin-reset w-100">
                                    <Grid
                                        style={{ height: '100%' }}
                                        data={this.state.miembrosAlianza.map((item: any) => ({
                                            ...item
                                        }))}
                                        resizable={true}
                                        scrollable="scrollable"
                                    >
                                        <Column field="id" title="Id" width="150px" editable={false} className="p" />
                                        <Column field="empresa" title="Empresa" width="400px" />
                                        <Column cell={this.CommandCell} width="150px" />

                                    </Grid>
                                </Row>
                            </CardBody>
                        </Card>
                    </Row>

                </Container>

            </>
        );
    }
};
export default withApollo(alianzaEmpresaEnergia);
