/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import Reflux from "reflux";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  CardFooter,
} from "reactstrap";
// core components
import UserHeader from "../../components/Headers/UserHeader";
// Avatar
import Avatar from "react-avatar";
import { withApollo } from "react-apollo";
import {
  CHANGE_APP_LOGO,
  UPDATE_PROFILE,
} from "../../queries/users/users.queries";
import GetUserData from "../../components/User/GetUserData";
import { UserStore, UserStoreActions } from "../../store/UserStore";
import { toast } from "react-toastify";

class Profile extends Reflux.Component<any, any> {
  public maxFileSize = 3;
  public validFileExtensions = ["jpg", "jpeg", "png"];
  constructor(props) {
    super(props);
    this.state = {
      changeLogoLoading: false,
      updateProfileLoading: false,
    };
    this.store = UserStore;
  }
  componentDidMount() {
    this.setState({ currentUser: this.state.UserStore.data });
  }
  validateFile(file) {
    const extension = file.name.split(".").pop() || "";
    // Get length of file in bytes
    const fileSizeInBytes = file.size;
    // Convert the bytes to Kilobytes (1 KB = 1024 Bytes)
    const fileSizeInKB = fileSizeInBytes / 1024;
    // Convert the KB to MegaBytes (1 MB = 1024 KBytes)
    const fileSizeInMB = fileSizeInKB / 1024;
    if (!this.validFileExtensions.includes(extension)) {
      const extensionsAllowed = this.validFileExtensions.join(", ");
      toast.error(
        `Solo permiten las siguientes extensiones ${extensionsAllowed}`
      );
      return false;
    }
    if (fileSizeInMB > this.maxFileSize) {
      toast.error("El tamaño maximo es de 3mb");
      return false;
    }

    return true;
    // onChangeFile(file);
  }
  // On App Logo Change
  onAppLogoChange = (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];
    if (this.validateFile(file)) {
      reader.onloadend = async () => {
        this.setState({ changeLogoLoading: true });
        const name = file.name;
        const content = reader.result;
        const resp = await this.props.client.mutate({
          mutation: CHANGE_APP_LOGO,
          variables: { data: { file: { content, name } } },
        });
        toast.success("Logo cambiado correctamente");
        this.setState({ changeLogoLoading: false });
        UserStoreActions.update(resp.data.changeAppLogo);
      };
      reader.readAsDataURL(file);
    }
  };
  eventFire = (el, etype) => {
    if (el.fireEvent) {
      el.fireEvent("on" + etype);
    } else {
      var evObj = document.createEvent("Events");
      evObj.initEvent(etype, true, false);
      el.dispatchEvent(evObj);
    }
  };
  onAppLogoButtonClick = () => {
    const input = document.getElementById("label-upload-button");
    this.eventFire(input, "click");
  };
  // Update User
  onChangeField = (event) => {
    const name = event.target.name;
    let value = event.target.value;
    const currentUser = this.state.currentUser;
    currentUser[name] = value;
    this.setState({ currentUser });
  };
  updateProfile = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    let hasError = false;
    this.setState({ updateProfileLoading: true });
    try {
      const variables = {
        email: this.state.currentUser.email,
        first_name: this.state.currentUser.first_name,
        last_name: this.state.currentUser.last_name,
      };
      const resp = await this.props.client.mutate({
        mutation: UPDATE_PROFILE,
        variables: { data: variables },
      });
      const userUpdated = resp.data.updateProfile || {};
      if (userUpdated) {
        UserStoreActions.update(userUpdated);
      }
    } catch (e) {
      hasError = true;
      toast.error("Ooops...Ocurrio un error intente mas tarde");
    } finally {
      if (!hasError) {
        toast.success("Perfil actualizado correctamente");
      }

      this.setState({ updateProfileLoading: false });
    }
  };
  // Render
  _renderChangeLogoLoading() {
    if (!this.state.changeLogoLoading) {
      return "Cambiar logo app";
    }
    return "Cargando...";
  }
  _renderCardMyAccount() {
    return (
      <Card className="bg-secondary shadow w-100">
        <Form onSubmit={this.updateProfile}>
          <CardHeader className="bg-white border-0">
            <Row className="align-items-center">
              <Col xs="6" md="6">
                <h3 className="mb-0">Mi cuenta</h3>
              </Col>
              <Col className="text-right" xs="6" md="6">
                <label htmlFor="upload-button" id="label-upload-button">
                  <span className="btn btn-sm btn-primary">
                    {this._renderChangeLogoLoading()}
                  </span>
                </label>
                <input
                  type="file"
                  id="upload-button"
                  style={{ display: "none" }}
                  onChange={this.onAppLogoChange}
                  accept="image/*"
                />
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <h6 className="heading-small text-muted mb-4">
              Informacion del usuario
            </h6>
            <div className="pl-lg-4">
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-email">
                      Email
                    </label>
                    <Input
                      disabled={true}
                      className="form-control-alternative"
                      id="input-email"
                      placeholder="jesse@example.com"
                      type="email"
                      value={this.state.UserStore.data.email}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-first-name"
                    >
                      Nombre
                    </label>
                    <Input
                      required
                      className="form-control-alternative"
                      name="first_name"
                      placeholder="Nombre"
                      type="text"
                      value={this.state.currentUser?.first_name || ''}
                      onChange={this.onChangeField}
                    />
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-last-name"
                    >
                      Apellido
                    </label>
                    <Input
                      required
                      className="form-control-alternative"
                      placeholder="Apellido"
                      name="last_name"
                      type="text"
                      value={this.state.currentUser?.last_name || ''}
                      onChange={this.onChangeField}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <hr className="my-4 d-none" />
            {/* Address */}
            <h6 className="heading-small text-muted mb-4 d-none">
              Contact information
            </h6>
            <div className="pl-lg-4 d-none">
              <Row>
                <Col md="12">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-address"
                    >
                      Address
                    </label>
                    <Input
                      className="form-control-alternative"
                      defaultValue="Bld Mihail Kogalniceanu, nr. 8 Bl 1, Sc 1, Ap 09"
                      id="input-address"
                      placeholder="Home Address"
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-city">
                      City
                    </label>
                    <Input
                      className="form-control-alternative"
                      defaultValue="New York"
                      id="input-city"
                      placeholder="City"
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col lg="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-country"
                    >
                      Country
                    </label>
                    <Input
                      className="form-control-alternative"
                      defaultValue="United States"
                      id="input-country"
                      placeholder="Country"
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col lg="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-country"
                    >
                      Postal code
                    </label>
                    <Input
                      className="form-control-alternative"
                      id="input-postal-code"
                      placeholder="Postal code"
                      type="number"
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <hr className="my-4" />
            {/* Description */}
            <h6 className="heading-small text-muted mb-4 d-none">About me</h6>
            <div className="pl-lg-4 d-none">
              <FormGroup>
                <label>About Me</label>
                <Input
                  className="form-control-alternative"
                  placeholder="A few words about you ..."
                  rows="4"
                  defaultValue="A beautiful Dashboard for Bootstrap 4. It is Free and
                          Open Source."
                  type="textarea"
                />
              </FormGroup>
            </div>
          </CardBody>
          <CardFooter>
            <Row>
              <Col md="12">
                <Button
                  color="primary"
                  type="submit"
                  disabled={this.state.updateProfileLoading}
                >
                  {this.state.updateProfileLoading
                    ? "Actualizando.."
                    : "Actualizar"}
                </Button>
              </Col>
            </Row>
          </CardFooter>
        </Form>
      </Card>
    );
  }
  _renderCardMyProfile() {
    return (
      <Card className="card-profile shadow w-100">
        <Row className="justify-content-center">
          <Col className="order-lg-2" lg="3">
            <div className="card-profile-image">
              <Avatar
                name={this.state.UserStore.data.fullname}
                src={this.state.UserStore.data.profile_photo}
                round={true}
                color="rgb(126, 55, 148)"
                fgColor={"#fff"}
                maxInitials={2}
                size={"180px"}
                style={{
                  position: "absolute",
                  left: "50%",
                  top: "-90px",
                  marginLeft: "-90px",
                }}
              />
            </div>
          </Col>
        </Row>
        <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4"></CardHeader>
        <CardBody className="pt-0 pt-md-4">
          <Row>
            <div className="col">
              <div className="card-profile-stats d-flex justify-content-center mt-md-5">
                <div className="d-none">
                  <span className="heading">22</span>
                  <span className="description">Lorem impsum</span>
                </div>
                <div className="d-none">
                  <span className="heading">10</span>
                  <span className="description">Lorem impsum</span>
                </div>
                <div className="d-none">
                  <span className="heading">89</span>
                  <span className="description">Lorem impsum</span>
                </div>
              </div>
            </div>
          </Row>
          <div className="text-center">
            <h3>
              {this.state.fullname}
              <span className="font-weight-light d-none">, 36</span>
            </h3>
            <div className="h5 font-weight-300">
              <i className="ni location_pin mr-2" />
              {this.state.company}
            </div>
            <div className="h5 mt-4 d-none">
              <i className="ni business_briefcase-24 mr-2" />
              Administrador
            </div>
          </div>
        </CardBody>
      </Card>
    );
  }
  render() {
    return (
      <>
        <GetUserData></GetUserData>
        <UserHeader fullname={this.state.UserStore.data.fullname} />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-2 mb-5 mb-xl-0 d-flex" xl="4">
              {this._renderCardMyProfile()}
            </Col>
            {/* Card my account */}
            <Col className="order-xl-1 d-flex" xl="8">
              {this._renderCardMyAccount()}
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withApollo(Profile);
